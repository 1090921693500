import { DialogActions } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"

export const ComingSoonDialog = ({ closeDialog }) => {
  return (
    <Box
      sx={{
        maxWidth: 400,
        width: "100%",
        background: "#121212",
        textAlign: "center",
      }}
    >
      <DialogTitle>Coming soon...</DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <Typography>Current functionality is not available yet</Typography>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          color="primary"
          variant={"contained"}
          onClick={() => closeDialog()}
          fullWidth
          sx={{ fontFamily: "RUSBoycott, sans-serif" }}
        >
          Close
        </Button>
      </DialogActions>
    </Box>
  )
}
