import machineGunImg_1 from "../assets/img/turrets/machineGun/machineGunWhite.webp"
import machineGunVideo_1 from "../assets/video/turrets/machineGun/machineGunWhite.mp4"
import rocketImg_1 from "../assets/img/turrets/rocket/rocketWhite.webp"
import rocketVideo_1 from "../assets/video/turrets/rocket/rocketWhite.mp4"
import laserImg_1 from "../assets/img/turrets/laser/laserWhite.webp"
import laserVideo_1 from "../assets/video/turrets/laser/laserWhite.mp4"
import electromagneticImg_1 from "../assets/img/turrets/electromagnetic/electromagneticWhite.webp"
import electromagneticVideo_1 from "../assets/video/turrets/electromagnetic/electromagneticWhite.mp4"
import artilleryImg_1 from "../assets/img/turrets/artillery/artilleryWhite.webp"
import artilleryVideo_1 from "../assets/video/turrets/artillery/artilleryWhite.mp4"
import machineGunImg_2 from "../assets/img/turrets/machineGun/machineGunGreen.webp"
import machineGunVideo_2 from "../assets/video/turrets/machineGun/machineGunGreen.mp4"
import rocketImg_2 from "../assets/img/turrets/rocket/rocketGreen.webp"
import rocketVideo_2 from "../assets/video/turrets/rocket/rocketGreen.mp4"
import laserImg_2 from "../assets/img/turrets/laser/laserGreen.webp"
import laserVideo_2 from "../assets/video/turrets/laser/laserGreen.mp4"
import electromagneticImg_2 from "../assets/img/turrets/electromagnetic/electromagneticGreen.webp"
import electromagneticVideo_2 from "../assets/video/turrets/electromagnetic/electromagneticGreen.mp4"
import artilleryImg_2 from "../assets/img/turrets/artillery/artilleryGreen.webp"
import artilleryVideo_2 from "../assets/video/turrets/artillery/artilleryGreen.mp4"
import machineGunImg_3 from "../assets/img/turrets/machineGun/machineGunBlue.webp"
import machineGunVideo_3 from "../assets/video/turrets/machineGun/machineGunBlue.mp4"
import rocketImg_3 from "../assets/img/turrets/rocket/rocketBlue.webp"
import rocketVideo_3 from "../assets/video/turrets/rocket/rocketBlue.mp4"
import laserImg_3 from "../assets/img/turrets/laser/laserBlue.webp"
import laserVideo_3 from "../assets/video/turrets/laser/laserBlue.mp4"
import electromagneticImg_3 from "../assets/img/turrets/electromagnetic/electromagneticBlue.webp"
import electromagneticVideo_3 from "../assets/video/turrets/electromagnetic/electromagneticBlue.mp4"
import artilleryImg_3 from "../assets/img/turrets/artillery/artilleryBlue.webp"
import artilleryVideo_3 from "../assets/video/turrets/artillery/artilleryBlue.mp4"
import machineGunImg_4 from "../assets/img/turrets/machineGun/machineGunPurple.webp"
import machineGunVideo_4 from "../assets/video/turrets/machineGun/machineGunPurple.mp4"
import rocketImg_4 from "../assets/img/turrets/rocket/rocketPurple.webp"
import rocketVideo_4 from "../assets/video/turrets/rocket/rocketPurple.mp4"
import laserImg_4 from "../assets/img/turrets/laser/laserPurple.webp"
import laserVideo_4 from "../assets/video/turrets/laser/laserPurple.mp4"
import electromagneticImg_4 from "../assets/img/turrets/electromagnetic/electromagneticPurple.webp"
import electromagneticVideo_4 from "../assets/video/turrets/electromagnetic/electromagneticPurple.mp4"
import artilleryImg_4 from "../assets/img/turrets/artillery/artilleryPurple.webp"
import artilleryVideo_4 from "../assets/video/turrets/artillery/artilleryPurple.mp4"
import machineGunImg_5 from "../assets/img/turrets/machineGun/machineGunGold.webp"
import machineGunVideo_5 from "../assets/video/turrets/machineGun/machineGunGold.mp4"
import rocketImg_5 from "../assets/img/turrets/rocket/rocketGold.webp"
import rocketVideo_5 from "../assets/video/turrets/rocket/rocketGold.mp4"
import laserImg_5 from "../assets/img/turrets/laser/laserGold.webp"
import laserVideo_5 from "../assets/video/turrets/laser/laserGold.mp4"
import electromagneticImg_5 from "../assets/img/turrets/electromagnetic/electromagneticGold.webp"
import electromagneticVideo_5 from "../assets/video/turrets/electromagnetic/electromagneticGold.mp4"
import artilleryImg_5 from "../assets/img/turrets/artillery/artilleryGold.webp"
import artilleryVideo_5 from "../assets/video/turrets/artillery/artilleryGold.mp4"

export const turrets = [
  {
    id: 1,
    name: "Machine Gun",
    price: "123",
    image: machineGunImg_1,
    videoUrl: machineGunVideo_1,
    poster: machineGunImg_1,
    type: "rocket",
    bonus: 30,
    rarity: "common",
    level: 1,
    status: "mining",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 24,
  },
  {
    id: 2,
    name: "Rocket",
    price: "123",
    image: rocketImg_1,
    videoUrl: rocketVideo_1,
    poster: rocketImg_1,
    type: "rocket",
    rarity: "common",
    level: 1,
    status: "mining",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 24,
  },
  {
    id: 3,
    name: "Laser",
    price: "123",
    image: laserImg_1,
    videoUrl: laserVideo_1,
    poster: laserImg_1,
    type: "rocket",
    rarity: "common",
    level: 1,
    status: "mining",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 24,
  },
  {
    id: 4,
    name: "Electromagnetic",
    price: "123",
    image: electromagneticImg_1,
    videoUrl: electromagneticVideo_1,
    poster: electromagneticImg_1,
    type: "rocket",
    rarity: "common",
    level: 1,
    status: "mining",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 24,
  },
  {
    id: 5,
    name: "Artillery",
    price: "123",
    image: artilleryImg_1,
    videoUrl: artilleryVideo_1,
    poster: artilleryImg_1,
    type: "rocket",
    rarity: "common",
    level: 1,
    status: "mining",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 24,
  },
  {
    id: 6,
    name: "Machine Gun",
    price: "123",
    image: machineGunImg_2,
    videoUrl: machineGunVideo_2,
    poster: machineGunImg_2,
    type: "rocket",
    rarity: "uncommon",
    level: 1,
    status: "mining",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 12,
  },
  {
    id: 7,
    name: "Rocket",
    price: "123",
    image: rocketImg_2,
    videoUrl: rocketVideo_2,
    poster: rocketImg_2,
    type: "rocket",
    rarity: "uncommon",
    level: 1,
    status: "mining",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 12,
  },
  {
    id: 8,
    name: "Laser",
    price: "123",
    image: laserImg_2,
    videoUrl: laserVideo_2,
    poster: laserImg_2,
    type: "rocket",
    rarity: "uncommon",
    level: 1,
    status: "mining",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 12,
  },
  {
    id: 9,
    name: "Electromagnetic",
    price: "123",
    image: electromagneticImg_2,
    videoUrl: electromagneticVideo_2,
    poster: electromagneticImg_2,
    type: "rocket",
    rarity: "uncommon",
    level: 1,
    status: "mining",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 12,
  },
  {
    id: 10,
    name: "Artillery",
    price: "123",
    image: artilleryImg_2,
    videoUrl: artilleryVideo_2,
    poster: artilleryImg_2,
    type: "rocket",
    rarity: "uncommon",
    level: 3,
    status: "mining",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 12,
  },
  {
    id: 11,
    name: "Machine Gun",
    price: "123",
    image: machineGunImg_3,
    videoUrl: machineGunVideo_3,
    poster: machineGunImg_3,
    type: "rocket",
    rarity: "rare",
    level: 1,
    status: "mining",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 4,
  },
  {
    id: 12,
    name: "Rocket",
    price: "123",
    image: rocketImg_3,
    videoUrl: rocketVideo_3,
    poster: rocketImg_3,
    type: "rocket",
    rarity: "rare",
    level: 1,
    status: "mining",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 4,
  },
  {
    id: 13,
    name: "Laser",
    price: "123",
    image: laserImg_3,
    videoUrl: laserVideo_3,
    poster: laserImg_3,
    type: "rocket",
    rarity: "rare",
    level: 1,
    status: "mining",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 4,
  },
  {
    id: 14,
    name: "Electromagnetic",
    price: "123",
    image: electromagneticImg_3,
    videoUrl: electromagneticVideo_3,
    poster: electromagneticImg_3,
    type: "rocket",
    rarity: "rare",
    level: 1,
    status: "mining",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 4,
  },
  {
    id: 15,
    name: "Artillery",
    price: "123",
    image: artilleryImg_3,
    videoUrl: artilleryVideo_3,
    poster: artilleryImg_3,
    type: "rocket",
    rarity: "rare",
    level: 1,
    status: "mining",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 4,
  },
  //
  {
    id: 16,
    name: "Machine Gun",
    price: "123",
    image: machineGunImg_4,
    videoUrl: machineGunVideo_4,
    poster: machineGunImg_4,
    type: "rocket",
    rarity: "epic",
    level: 3,
    status: "In slot",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 3,
  },
  {
    id: 17,
    name: "Rocket",
    price: "123",
    image: rocketImg_4,
    videoUrl: rocketVideo_4,
    poster: rocketImg_4,
    type: "rocket",
    rarity: "epic",
    level: 1,
    status: "In slot",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 3,
  },
  {
    id: 18,
    name: "Laser",
    price: "123",
    image: laserImg_4,
    videoUrl: laserVideo_4,
    poster: laserImg_4,
    type: "rocket",
    rarity: "epic",
    level: 1,
    status: "In slot",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 3,
  },
  {
    id: 19,
    name: "Electromagnetic",
    price: "123",
    image: electromagneticImg_4,
    videoUrl: electromagneticVideo_4,
    poster: electromagneticImg_4,
    type: "rocket",
    rarity: "epic",
    level: 1,
    status: "On market",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 3,
  },
  {
    id: 20,
    name: "Artillery",
    price: "123",
    image: artilleryImg_4,
    videoUrl: artilleryVideo_4,
    poster: artilleryImg_4,
    type: "rocket",
    rarity: "epic",
    level: 1,
    status: "On market",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 3,
  },
  {
    id: 21,
    name: "Machine Gun",
    price: "123",
    image: machineGunImg_5,
    videoUrl: machineGunVideo_5,
    poster: machineGunImg_5,
    type: "rocket",
    rarity: "legendary",
    level: 1,
    status: "In slot",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 1,
  },
  //
  {
    id: 22,
    name: "Rocket",
    price: "123",
    image: rocketImg_5,
    videoUrl: rocketVideo_5,
    poster: rocketImg_5,
    type: "rocket",
    rarity: "legendary",
    level: 1,
    status: "In slot",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 1,
  },
  {
    id: 23,
    name: "Laser",
    price: "123",
    image: laserImg_5,
    videoUrl: laserVideo_5,
    poster: laserImg_5,
    type: "rocket",
    rarity: "legendary",
    level: 1,
    status: "In slot",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 1,
  },
  {
    id: 24,
    name: "Electromagnetic",
    price: "123",
    image: electromagneticImg_5,
    videoUrl: electromagneticVideo_5,
    poster: electromagneticImg_5,
    type: "rocket",
    rarity: "legendary",
    level: 1,
    status: "On market",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 1,
  },
  {
    id: 25,
    name: "Artillery",
    price: "123",
    image: artilleryImg_5,
    videoUrl: artilleryVideo_5,
    poster: artilleryImg_5,
    type: "rocket",
    rarity: "legendary",
    level: 1,
    status: "In slot",
    baseDamage: "12",
    actualDamage: "3",
    attackSpeed: "2",
    attackType: "8",
    points: "3",
    minted: 0,
    left: 1,
  },
]
