import { ReactComponent as metaMaskIcon } from "../../assets/img/icons/metamask.svg"
import CopyToClipboard from "../CopyToClipboard"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { IconButton, SvgIcon } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import React from "react"

export const SubHeader = () => {
  const contractAddress = "0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830"

  return (
    <div className="sub-header">
      <div className="container">
        <div>
          <div>Zombie TD Contract Address: </div>
          <div>{contractAddress}</div>
          <CopyToClipboard>
            {({ copy }) => (
              <IconButton
                sx={{
                  color: "#242424",
                  padding: {
                    xs: "5px",
                  },
                }}
                onClick={() => copy(contractAddress)}
              >
                <ContentCopyIcon
                  sx={{
                    width: {
                      xs: 16,
                    },
                    height: {
                      xs: 16,
                    },
                  }}
                />
              </IconButton>
            )}
          </CopyToClipboard>

          <Tooltip title="Coming soon...">
            <span>
              <IconButton className="metamask-icon" size="small" disabled>
                <SvgIcon
                  component={metaMaskIcon}
                  inheritViewBox
                  sx={{
                    width: {
                      xs: 16,
                    },
                    height: {
                      xs: 16,
                    },
                  }}
                />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
