import dividerPrimaryGradientHUrl from "../assets/img/border/divider-primary-gradient-h.svg"
import dividerPrimaryGradientUrl from "../assets/img/border/divider-primary-gradient.svg"
import { useAppDispatch, useAppSelector } from "../store"
import { connectHandler } from "../utils/connectHandler"
import CopyToClipboard from "./CopyToClipboard"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  StepLabel,
  TextField,
  Tooltip,
} from "@mui/material"
import Button from "@mui/material/Button"
import Step from "@mui/material/Step"
import StepContent from "@mui/material/StepContent"
import Stepper from "@mui/material/Stepper"
import Typography from "@mui/material/Typography"
import React from "react"

export const AffiliateProgram = ({ view }) => {
  const dispatch = useAppDispatch()
  const { address } = useAppSelector((state) => state.user.user)

  const affiliateProgramEarnings = {
    turrets: {
      total: "30,000",
      topOne: "10,000",
      topTwo: "5,000",
      topThree: "1,800",
      topFour: "1,000",
      topFiveAndMore: "700",
      topElevenAndMore: "500",
      topTwentyAndMore: "100",
    },
    commanders: {
      total: "15,000",
      topOne: "5,000",
      topTwo: "2,500",
      topThree: "900",
      topFour: "1,000",
      topFiveAndMore: "500",
      topElevenAndMore: "350",
      topTwentyAndMore: "250",
    },
  }

  const getWallet = () => {
    connectHandler(dispatch)
  }

  return (
    <Box
      sx={{
        mt: {
          xs: 4,
          sm: 6,
          md: 8,
        },
      }}
    >
      <Typography variant="h2" textAlign="center" gutterBottom>
        Affiliate Program
      </Typography>

      <Typography variant="subtitle1" textAlign="center" sx={{ mb: 2 }}>
        Refer your friends to buy{" "}
        <span className="primary">
          NFT Boxes with {view === "turrets" ? "Turrets" : "Commanders"}
        </span>{" "}
        to earn 10% affiliate commission, as well as the opportunity to win an
        extra prize of {affiliateProgramEarnings[view].total} BUSD
      </Typography>

      <Grid container rowSpacing={3} columnSpacing={0} className="d-flex">
        <Grid item md={6}>
          <Box
            sx={{
              borderRadius: 2,
              borderLeft: "1px solid transparent",
              borderBottom: "1px solid transparent",
              borderImage: `url(${dividerPrimaryGradientHUrl}) 1 stretch`,
              height: "100%",
            }}
          >
            <Box
              sx={{
                borderTop: "1px solid transparent",
                borderBottom: "1px solid transparent",
                borderImage: `url(${dividerPrimaryGradientUrl}) 1 stretch`,
                pt: 3,
                pb: 1,
                mb: 2,
                background: "rgba(255, 255, 255, .05)",
              }}
            >
              <Typography variant="h4" textAlign="center" sx={{ mb: 2 }}>
                Step by step
              </Typography>
            </Box>

            <Box sx={{ px: 3 }}>
              <Stepper orientation="vertical">
                <Step active expanded>
                  <StepLabel>
                    <Typography>Step 1:</Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography>
                      Go to{" "}
                      <Link
                        href={`https://zombietd.app/nft-boxes-with-${
                          view === "turrets" ? "turrets" : "commanders"
                        }`}
                        target="_blank"
                      >
                        https://zombietd.app/nft-boxes-with-
                        {view === "turrets" ? "turrets" : "commanders"}
                      </Link>
                    </Typography>
                  </StepContent>
                </Step>

                <Step active expanded>
                  <StepLabel>
                    <Typography>Step 2:</Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography>Connect wallet.</Typography>
                  </StepContent>
                </Step>

                <Step active expanded>
                  <StepLabel>
                    <Typography>Step 3:</Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography>
                      Copy your affiliate link and share it with your friends
                    </Typography>
                  </StepContent>
                </Step>
                <Step active expanded>
                  <StepLabel>
                    <Typography>Step 4:</Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography>
                      For each NFT Box with{" "}
                      {view === "turrets" ? "Turrets" : "Commanders"} your
                      referees purchase, you will earn 10% affiliate commission
                      in BUSD and you can withdraw them immediately.
                    </Typography>
                  </StepContent>
                </Step>
              </Stepper>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box
            sx={{
              borderRadius: 2,
              borderLeft: "1px solid transparent",
              borderRight: "1px solid transparent",
              borderBottom: "1px solid transparent",
              borderImage: `url(${dividerPrimaryGradientHUrl}) 1 stretch`,
              height: "100%",
            }}
          >
            <Box
              sx={{
                borderTop: "1px solid transparent",
                borderBottom: "1px solid transparent",
                borderImage: `url(${dividerPrimaryGradientUrl}) 1 stretch`,
                pt: 3,
                pb: 1,
                mb: 2,
                background: "rgba(255, 255, 255, .05)",
              }}
            >
              <Typography variant="h4" textAlign="center" sx={{ mb: 2 }}>
                Extra prize pool
              </Typography>
            </Box>

            <Box sx={{ px: 3 }}>
              <Typography gutterBottom>
                {affiliateProgramEarnings[view].total} BUSD prize pool for the
                top 50 promoters with the highest number of successfully
                referred transactions
              </Typography>

              <Stepper
                orientation="vertical"
                sx={{
                  mb: 2,
                  "& .MuiStepLabel-root": {
                    pb: 0,
                  },
                  "& .MuiStepConnector-line": {
                    display: "none",
                  },
                }}
              >
                <Step active expanded>
                  <StepLabel>
                    <Typography>Top 1 promoter: </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography className="primary">
                      {affiliateProgramEarnings[view].topOne} BUSD
                    </Typography>
                  </StepContent>
                </Step>

                <Step active expanded>
                  <StepLabel>
                    <Typography>Top 2 promoter: </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography className="primary">
                      {affiliateProgramEarnings[view].topTwo} BUSD
                    </Typography>
                  </StepContent>
                </Step>

                <Step active expanded>
                  <StepLabel>
                    <Typography>Top 3 promoter: </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography className="primary">
                      {affiliateProgramEarnings[view].topThree} BUSD
                    </Typography>
                  </StepContent>
                </Step>
                <Step active expanded>
                  <StepLabel>
                    <Typography>Top 4 promoter:</Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography className="primary">
                      {affiliateProgramEarnings[view].topFour} BUSD
                    </Typography>
                  </StepContent>
                </Step>
                <Step active expanded>
                  <StepLabel>
                    <Typography>Top 5-10 promotes:</Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography className="primary">
                      {affiliateProgramEarnings[view].topFiveAndMore} BUSD
                    </Typography>
                  </StepContent>
                </Step>
                <Step active expanded>
                  <StepLabel>
                    <Typography>Top 11-20 promotes:</Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography className="primary">
                      {affiliateProgramEarnings[view].topElevenAndMore} BUSD
                    </Typography>
                  </StepContent>
                </Step>
                <Step active expanded>
                  <StepLabel>
                    <Typography>Top 20-50 promotes:</Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography className="primary">
                      {affiliateProgramEarnings[view].topTwentyAndMore} BUSD
                    </Typography>
                  </StepContent>
                </Step>
              </Stepper>

              <Typography variant="body2">
                * Extra prize pool will be awarded after the sale of all NFT
                Boxes with {view === "turrets" ? "Turrets" : "Commanders"}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  background: "rgba(255, 255, 255, .05)",
                  p: 3,
                  borderRadius: 2,
                  display: "flex",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5" textAlign="center">
                    Number of referrals:
                  </Typography>
                  <Typography variant="h5" textAlign="center">
                    0
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  background: "rgba(255, 255, 255, .05)",
                  p: 3,
                  borderRadius: 2,
                  display: "flex",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5" textAlign="center">
                    Total Earned:
                  </Typography>
                  <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                    sx={{ alignItems: "center" }}
                  >
                    <Typography variant="h5" textAlign="center">
                      0 BUSD
                    </Typography>

                    <Tooltip title="Coming soon...">
                      <span>
                        <Button
                          variant="contained"
                          onClick={getWallet}
                          size="small"
                          disabled
                        >
                          Claim
                        </Button>
                      </span>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              background: "rgba(255, 255, 255, .05)",
              p: 3,
              borderRadius: 2,
            }}
          >
            <Typography variant="h5">Your Affiliate Link:</Typography>
            {!address ? (
              <>
                <Typography gutterBottom>
                  Connect your wallet to get affiliate link
                </Typography>
                <Button variant="contained" onClick={getWallet} size="small">
                  Connect Wallet
                </Button>
              </>
            ) : (
              <Grid container spacing={2} sx={{ mt: 0 }}>
                <Grid item xs={11}>
                  <TextField
                    label="Your Affiliate Link:"
                    variant="outlined"
                    size="small"
                    value="https://zombietd.app?ref=qerd4321"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs>
                  <CopyToClipboard>
                    {({ copy }) => (
                      <IconButton
                        onClick={() =>
                          copy("https://zombietd.app?ref=qerd4321")
                        }
                        sx={{
                          border: "1px solid rgba(255, 255, 255, 0.5)",
                          height: 44,
                          width: 44,
                        }}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    )}
                  </CopyToClipboard>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
