import React from "react"

export const Entry = ({ image, setCurrentTurret, turrets, index }) => {
  return (
    <div
      className="item"
      onMouseEnter={() =>
        setCurrentTurret(turrets.find((el) => el.id === index + 1))
      }
    >
      <img src={image} alt="" />
    </div>
  )
}
