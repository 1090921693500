import { setIsAuth, setUser } from "../store/userReducer"
import { connectWallet } from "./metamask/connectWallet"

export const connectHandler = (dispatch) => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
  ) {
    window.open("https://metamask.app.link/dapp/game-bsc-three.vercel.app/")
  }
  connectWallet().then((account) => {
    // @ts-ignore
    dispatch(
      setUser({
        userName: "User",
        address: account,
        cardBalance: "0",
        balance: 0,
        userId: 14632341,
      }),
    )
    dispatch(setIsAuth(true))

    //   axios.post(${BASE_URL}/account/token, {
    //       address: ${account}
    //     })
    //     .then(( res ) => {
    //       localStorage.setItem('token', res.data.data)
    //       $api.get(/account/userInfo).then(({data}) => {
    //         dispatch(setUserLoading(true))
    //         dispatch(setUser(data.data))
    //         dispatch(setIsAuth(true));
    //         dispatch(setNotification(Zombie TD Contract address: ${account}));
    //       }).catch(err => {
    //         dispatch(setUserLoading(true))
    //         dispatch(setNotification('Server error, please try again later'));
    //       });
    //     });
    // }).catch(err => {
    //   dispatch(setUserLoading(false))
    //   dispatch(setNotification('Server error, please try again later'));
  })
}
