import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"
import Box from "@mui/material/Box"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import React, { useState } from "react"
import { NavLink } from "react-router-dom"

export const AccountMenu = ({ title, description, menuList, disabled }) => {
  const [openMenu, setOpenMenu] = useState(true)

  return (
    <Box>
      <ListItemButton
        alignItems="flex-start"
        onClick={() => setOpenMenu(!openMenu)}
        sx={{
          px: 3,
          pt: 2,
          pb: openMenu ? 0 : 2,
          "&:hover, &:focus": { "& svg": { opacity: openMenu ? 1 : 0 } },
        }}
      >
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            fontSize: 18,
            lineHeight: "21px",
            mb: "2px",
          }}
          secondary={description}
          secondaryTypographyProps={{
            noWrap: true,
            fontSize: 14,
            lineHeight: "16px",
            color: openMenu ? "rgba(0,0,0,0)" : "rgba(255,255,255,0.5)",
          }}
          sx={{ my: 0 }}
        />
        <KeyboardArrowDown
          sx={{
            mr: -1,
            opacity: 0,
            transform: openMenu ? "rotate(-180deg)" : "rotate(0)",
            transition: "0.2s",
          }}
        />
      </ListItemButton>
      {openMenu && (
        <>
          {menuList.map((menuItem, index) => (
            <ListItemButton
              to={menuItem.PATH}
              key={index}
              sx={{
                py: 0,
                minHeight: 42,
                height: 48,
                "&.active svg": {
                  color: "#BC8C4B",
                },
              }}
              onClick={menuItem.ACTION ? menuItem.ACTION : null}
              component={NavLink}
              className="nav-link"
              disabled={menuItem.IS_DISABLED}
            >
              <ListItemIcon sx={{ color: "inherit" }}>
                {menuItem.ICON && menuItem.ICON}
              </ListItemIcon>
              <ListItemText
                primary={menuItem.LABEL}
                primaryTypographyProps={{ fontSize: 18 }}
              />
            </ListItemButton>
          ))}
        </>
      )}
    </Box>
  )
}
