import electromagneticTurretBoxImg from "../../assets/img/nft-boxes/electromagnetic-box.png"
import { DialogActions, Stack } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import React from "react"

export const BuyDialog = ({ closeDialog, width }) => {
  return (
    <Box
      sx={{
        width,
        background: "#121212",
        textAlign: "center",
      }}
    >
      <DialogTitle sx={{ fontSize: "1rem" }}>Congratulations!</DialogTitle>
      <DialogContent sx={{ pt: 2, pb: 0 }}>
        <Stack>
          <Box
            textAlign="center"
            sx={{
              width: 350,
              margin: "auto",
              padding: "70px 0",
              background:
                "url(https://faraland.io/static/media/circle-highlight.a886adc7.png)",
              backgroundSize: "75%",
              backgroundPosition: "50% 50%",
              backgroundRepeat: "no-repeat",
              "& > img": {
                width: "180px",
              },
            }}
          >
            <img src={electromagneticTurretBoxImg} alt={""} />
          </Box>
          <Typography>
            You have fulfilled all the conditions and receive a NFT Box with
            Machine Gun as a gift!
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          color="primary"
          variant={"contained"}
          onClick={() => closeDialog()}
          fullWidth
          sx={{ fontFamily: "RUSBoycott, sans-serif" }}
        >
          Close
        </Button>
      </DialogActions>
    </Box>
  )
}
