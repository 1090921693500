import appLogo from "../assets/img/logo.svg"
import { PATHS } from "../constants/paths"
import React from "react"
import { NavLink } from "react-router-dom"

export const Logo = () => {
  return (
    <NavLink to={PATHS.HOME.PATH}>
      <div className="logo">
        <img src={appLogo} alt="ZombieTD" />
      </div>
    </NavLink>
  )
}
