import { BASE_URL } from "../constants/constants"
import axios from "axios"

const $api = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    accept: "text/plain",
  },
})

$api.interceptors.request.use((config) => {
  // @ts-ignore
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  return config
})

export default $api
