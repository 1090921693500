import web3 from "web3"

export const connectWallet = () =>
  new Promise(async (resolve, reject) => {
    if (!window.ethereum) reject("No crypto wallet found. Please install it.")

    if (window.ethereum) {
      await window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((address) => {
          resolve(web3.utils.toChecksumAddress(address[0]))
        })
        .catch((err) => reject(err.message))
    }
  })
