import appStoreIcon from "../assets/img/icons/app-store-badge.svg"
import googlePlayIcon from "../assets/img/icons/google-play-badge.svg"
import roadmap from "../assets/img/roadmap.png"
import diagram from "../assets/img/tokemonics-diagram.png"
import ShowMoreButton from "../components/button/ShowMoreButton"
import { Card } from "../components/card/Card"
import { Entry } from "../components/card/PreviewCard"
import { ComingSoonDialog } from "../components/dialog/ComingSoonDialog"
import { useDialog } from "../context/DialogContext"
import {
  advantages,
  economy,
  entries,
  features,
  gameplay,
  partners,
  zombies,
} from "../data/entries"
import { Container, Link, Stack } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import Parser from "html-react-parser"
import React, { useState } from "react"

export const Home = () => {
  const [currentTurret, setCurrentTurret] = useState(entries.turrets2[0])
  const [currentCommander, setCurrentCommander] = useState(
    entries.commanders[0],
  )

  const [openDialog, closeDialog] = useDialog()

  const onOpenComingSoonDialog = () => {
    openDialog({
      children: <ComingSoonDialog closeDialog={closeDialog} />,
    })
  }

  const isDownMd = useMediaQuery("(max-width: 900px)")
  return (
    <div className="home">
      <section className="hero">
        <Container fixed={isDownMd && false}>
          <div className="info">
            <h1 className="title">
              Welcome to <span className="primaryColor">Zombie TD</span>
            </h1>
            <p className="sub-title">
              The post-apocalyptic world{" "}
              <span className="primary"> filled with mutant zombies</span>
            </p>
            <p className="description">
              with Play to Earn concept in the BSC ecosystem
            </p>
            <Stack direction="row" justifyContent="center" mt={4} spacing={2}>
              <Link
                // href="https://play.google.com/store/games"
                href={void 0}
                target="_blank"
                sx={{
                  display: "inline-flex",
                  padding: "4px",
                  background: "#000000",
                  borderRadius: "8px",
                }}
                onClick={() => onOpenComingSoonDialog()}
              >
                <img src={googlePlayIcon} alt="" style={{ height: 48 }} />
              </Link>
              <Link
                // href="https://www.apple.com/by/app-store/"
                href={void 0}
                target="_blank"
                sx={{
                  display: "inline-flex",
                  padding: "4px",
                  background: "#000000",
                  borderRadius: "8px",
                }}
                onClick={() => onOpenComingSoonDialog()}
              >
                <img src={appStoreIcon} alt="" style={{ height: 48 }} />
              </Link>
            </Stack>
          </div>
        </Container>

        <Container fixed={isDownMd && false}>
          <div className="news">
            <h4 className="primary">The Whitelist will be open soon</h4>
            <p>
              Getting into the Whitelist gives a guaranteed allocation to
              participate in Private Sale of IDO and 24 hours of private sale on
              the sale of Boxes with NFT Turrets and Commanders. &nbsp;
              <Link
                className="primary"
                href={
                  "https://docs.zombietd.app/tokenomics/whitelist-information"
                }
                target={"_blank"}
                sx={{
                  textTransform: "uppercase",
                }}
              >
                Read More
              </Link>
            </p>
          </div>
        </Container>
      </section>

      <section className="intro">
        <Container fixed={isDownMd && false}>
          <div className="head">
            <h2 className="heading">
              <span className="primary">Best Mobile Tower Defense Game</span>
            </h2>
            <p className="subheading">
              Zombie TD - is one of the best mobile games on Binance Smart
              Chain, set in a post-apocalyptic world filled with mutant zombies,
              where you can simultaneously enjoy a mobile tower defense game,
              compete against other players, and earn ZTD tokens through
              gameplay! The game consists of:
            </p>
          </div>

          <div className="body">
            <div className="list">
              {features.map((feature, index) => (
                <Card
                  key={index}
                  image={feature.image}
                  subTitle={feature.subtitle}
                />
                // Fight for ZTD token!
              ))}
            </div>
            {/*<GetAppsDialog label='Play & Earn'/>*/}
          </div>
        </Container>
      </section>

      <section className="features">
        <Container fixed={isDownMd && false}>
          <div className="body">
            <div>
              <h2 className="heading">
                Our <span className="primary">advantages:</span>
              </h2>
              <div className="list">
                {advantages.map((advantage, index) => (
                  <div className="item" key={index}>
                    <h4>{advantage.title}</h4>
                    <ShowMoreButton content={advantage.description} mt={2} />
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h2 className="heading">
                Token <span className="primary">Stabilization:</span>
              </h2>
              <div className="list">
                {economy.map((advantage, index) => (
                  <div className="item" key={index}>
                    <h4>{advantage.title}</h4>
                    <ShowMoreButton content={Parser(advantage.description)} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Container>
      </section>

      <div>
        <section className="entity commanders">
          <Container fixed={isDownMd && false}>
            <div className="head">
              <h2 className="heading">
                <span className="primary">Commanders</span>
              </h2>
              <p className="subheading">
                NFT Commanders play an essential role in Zombie TD. There is a{" "}
                <span className="primary">Base Commander NFT</span> (which is
                provided for free) and a{" "}
                <span className="primary">Specialized Commander NFT</span>{" "}
                (which can be purchased on the Marketplace or NFT Boxes sale).
                Specialized Commanders have a specialization for one of the five
                Turrets, which increases the attack, damage radius, and ability
                effectiveness of one of the five Turrets:
              </p>
            </div>

            <div className="body">
              <div className="media">
                <video
                  src={currentCommander.video}
                  poster={currentCommander.image}
                  preload="auto"
                  muted
                  autoPlay={true}
                  loop
                  className="videoContainer"
                />
              </div>
              <div className="info">
                <div className="head">
                  <h4>{currentCommander.title}</h4>
                  <p>{currentCommander.description}</p>
                </div>
                <div className="body">
                  <div className="list">
                    {Object.entries(entries.commanders).map(
                      ([key, value], index) => {
                        return (
                          <Entry
                            key={index}
                            turrets={entries.commanders}
                            image={value.image}
                            setCurrentTurret={setCurrentCommander}
                            index={index}
                          />
                        )
                      },
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>

        <section className="entity turrets light">
          <Container fixed={isDownMd && false}>
            <div className="head">
              <h2 className="heading">
                <span className="primary">Turrets</span>
              </h2>
              <p className="subheading">
                NFT Turrets are the only way to defend against zombies. All
                Turrets have their characteristics and unique abilities.
              </p>
            </div>

            <div className="body">
              <div className="info">
                <div className="head">
                  <div>
                    <h4>{currentTurret.title}</h4>
                    <p>{currentTurret.description}</p>
                  </div>
                </div>
                <div className="body">
                  <div className="list">
                    {Object.entries(entries.turrets2).map(
                      ([key, value], index) => {
                        return (
                          <Entry
                            key={index}
                            index={index}
                            turrets={entries.turrets2}
                            image={value.image}
                            setCurrentTurret={setCurrentTurret}
                          />
                        )
                      },
                    )}
                  </div>
                </div>
              </div>

              <div className="media">
                <video
                  src={currentTurret.video}
                  poster={currentTurret.image}
                  preload="auto"
                  muted
                  autoPlay={true}
                  loop
                  className="videoContainer"
                />
              </div>
            </div>
          </Container>
        </section>
      </div>

      <section className="entity zombies">
        <Container fixed={isDownMd && false}>
          <div className="head">
            <h2 className="heading">
              <span className="primary">Zombies</span>
            </h2>
            <p className="subheading">
              Due to an incident in the laboratory of the 'Zero Time Day'
              corporation, a virus that causes mutations in all living beings
              broke out and spread around the world with great speed. As a
              result, the virus-infected many living beings who became the
              living dead.
            </p>
          </div>

          <div className="body">
            <div className="list">
              {zombies.map((zombie) => (
                <div className="item">
                  <div className="media">
                    <img src={zombie.image} alt={zombie.alt} />
                  </div>
                  <div className="content">
                    <h4>{zombie.title}</h4>
                  </div>
                </div>
              ))}
              <div className="item"></div>
            </div>
          </div>
        </Container>
      </section>

      <section className="intro">
        <Container fixed={isDownMd && false}>
          <div className="head">
            <h2 className="heading">
              <span className="primary">Earn money</span> by playing Zombie TD
            </h2>
            <p className="subheading">
              There are various ways to earn money in the game, the main ones
              are:
            </p>
          </div>

          <div className="body">
            <div className="list">
              {gameplay.map((advantage) => (
                <Card
                  image={advantage.image}
                  title={advantage.title}
                  subTitle={advantage.description}
                />
              ))}
            </div>
            {/*<GetAppsDialog label='Play & Earn'/>*/}
          </div>
        </Container>
      </section>

      <section className="tokenomics">
        <Container fixed={isDownMd && false}>
          <div className="head">
            <h2 className="heading">
              <span className="primary">Tokenomics</span>
            </h2>
            <p className="subheading">
              The ZTD token has a maximum supply of 1,000,000,000 tokens, and is
              (or will be) distributed as follows:
            </p>
          </div>

          <div className="body">
            <div>
              <div className="diagram">
                <img src={diagram} alt="" />
                <div className="diagram-legend">
                  <div className="diagram-legend-cell">
                    <div className="diagram-legend-color diagram-legend-color6"></div>
                    <p>60% Play to Earn</p>
                  </div>
                  <div className="diagram-legend-cell">
                    <div className="diagram-legend-color diagram-legend-color2"></div>
                    <p>10% IDO</p>
                  </div>
                  <div className="diagram-legend-cell">
                    <div className="diagram-legend-color diagram-legend-color5"></div>
                    <p>10% Development</p>
                  </div>
                  <div className="diagram-legend-cell">
                    <div className="diagram-legend-color diagram-legend-color3"></div>
                    <p>8% Team</p>
                  </div>
                  <div className="diagram-legend-cell">
                    <div className="diagram-legend-color diagram-legend-color1"></div>
                    <p>7% Marketing</p>
                  </div>
                  <div className="diagram-legend-cell">
                    <div className="diagram-legend-color diagram-legend-color4"></div>
                    <p>5% Liquidity</p>
                  </div>
                </div>
              </div>

              <div className="info-table">
                <div className="info_table-heading">
                  <div>Category</div>
                  <div>Allocation</div>
                </div>
                <div className="info_table-row">
                  <div className="info_table-cellAllocation">Play to Earn</div>
                  <div className="info_table-cellSchedule">
                    60% of total supply or 600,000,000 $ZTD tokens allocated to
                    the Play to Earn pool
                  </div>
                </div>
                <div className="info_table-row">
                  <div className="info_table-cellAllocation">IDO</div>
                  <div className="info_table-cellSchedule">
                    10% of total supply or 100,000,000 $ZTD tokens allocated to
                    IDO
                  </div>
                </div>
                <div className="info_table-row">
                  <div className="info_table-cellAllocation">Development</div>
                  <div className="info_table-cellSchedule">
                    10% of total supply or 100,000,000 $ZTD tokens allocated to
                    development. Tokens are locked for 6 months
                  </div>
                </div>
                <div className="info_table-row">
                  <div className="info_table-cellAllocation">Team</div>
                  <div className="info_table-cellSchedule">
                    8% of total supply or 80,000,000 $ZTD tokens allocated to
                    the Team. Tokens are locked for 12 months
                  </div>
                </div>
                <div className="info_table-row">
                  <div className="info_table-cellAllocation">Marketing</div>
                  <div className="info_table-cellSchedule">
                    7% of total supply or 70,000,000 $ZTD tokens allocated to
                    Marketing. There are no distributions currently scheduled
                  </div>
                </div>

                <div className="info_table-row">
                  <div className="info_table-cellAllocation">Liquidity</div>
                  <div className="info_table-cellSchedule">
                    5% of total supply or 50,000,000 $ZTD tokens allocated to
                    initial liquidity. Reserved for initial Liquidity for
                    trading pair ZTD/BNB. This grants a healthy maximum initial
                    Liquidity-Circulating supply ratio of 50%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section>
        <Container fixed={isDownMd && false}>
          <div className="head">
            <h2 className="heading">
              <span className="primary">Roadmap</span>
            </h2>
          </div>
          <div className="body">
            <img src={roadmap} alt="Roadmap" />
          </div>
        </Container>
      </section>

      <section>
        <Container fixed={isDownMd && false}>
          <div className="head">
            <h2 className="heading">
              <span className="primary">Trailer</span>
            </h2>
          </div>
          <div className="body">
            <iframe
              width="100%"
              height="720"
              src="https://www.youtube.com/embed/GfsOuXIqXTw"
              title="Zombie TD Trailer"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Container>
      </section>

      <section className="our-partners">
        <Container fixed={isDownMd && false}>
          <div className="head">
            <h2 className="heading">
              <span className="primary">Our partners</span>
            </h2>
          </div>

          <div className="body">
            <div className="list">
              {partners.map((partner) => {
                return (
                  <div className="item">
                    <img src={partner.image} alt={partner.alt} />
                  </div>
                )
              })}
            </div>
          </div>
        </Container>
      </section>
    </div>
  )
}
