import PreviewNFTCard from "../card/PreviewNFTCard"
import {
  Avatar,
  DialogActions,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
} from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import React from "react"
import { capitalize } from "lodash"
import {
  GiAirZigzag,
  GiBarbedCoil,
  GiBeveledStar,
  GiBlaster,
  GiBullets,
} from "react-icons/gi"

const Property = ({ icon, label, value }) => {
  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar
          sx={{
            minWidth: 50,
          }}
        >
          <Avatar
            sx={{
              width: 36,
              height: 36,
              color: "#bc8c4b",
              background: "rgba(188,140,75,0.1)",
              border: "1px solid rgba(188,140,75,0.15)",
            }}
            variant="rounded"
          >
            {icon}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={label}
          secondary={
            <Typography
              sx={{ display: "inline" }}
              component="span"
              variant="body2"
              color="text.secondary"
            >
              {capitalize(value)}
            </Typography>
          }
        />
      </ListItem>
      {label !== "Ability" && (
        <Divider
          variant="inset"
          component="li"
          sx={{
            marginLeft: "66px",
          }}
        />
      )}
    </>
  )
}

export const PreviewNFTDialog = ({
  item,
  closeDialog,
  maxWidth,
  onSellDepositDialog,
  onAddNftToGame,
}) => {
  return (
    <Box
      sx={{
        maxWidth,
        background: "#121212",
        textAlign: "center",
      }}
    >
      <DialogTitle sx={{ fontSize: "1rem" }}>{item.name}</DialogTitle>
      <DialogContent sx={{ pt: 2, pb: 0 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item md={6}>
            <PreviewNFTCard
              imageUrl={item.image}
              videoUrl={item.videoUrl}
              id={item.id}
              lvl={item.level}
              rarity={item.rarity}
              type={item.type}
            />
            <Box
              textAlign="center"
              mt={2}
              px={2}
              sx={{ fontFamily: "RUSBoycott, sans-serif", fontSize: 26 }}
              className="primary"
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    p={1}
                    sx={{ fontFamily: "Foul Fiend, sans-serif", fontSize: 12 }}
                  >
                    In slot
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    p={1}
                    sx={{ fontFamily: "Foul Fiend, sans-serif", fontSize: 12 }}
                  >
                    On sale
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item md={6}>
            <List sx={{ width: "100%", p: 0 }}>
              <Property
                icon={<GiBullets />}
                label="Base Damage"
                value={item.baseDamage}
              />
              <Property
                icon={<GiBeveledStar />}
                label="Actual Damage"
                value={item.actualDamage}
              />
              <Property
                icon={<GiBlaster />}
                label="Attack Speed"
                value={item.attackSpeed}
              />
              <Property
                icon={<GiAirZigzag className="primary" />}
                label="Attack Type"
                value={item.attackType}
              />
              <Property
                icon={<GiBarbedCoil />}
                label="Ability"
                value={item.ability}
              />
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Stack
          flexWrap="wrap"
          spacing={2}
          useFlexGap
          sx={{ width: "100%", justifyContent: "space-between" }}
          direction="horizontal"
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              closeDialog()
              onSellDepositDialog?.()
            }}
            sx={{ fontFamily: "RUSBoycott, sans-serif" }}
          >
            Sell on the Market
          </Button>

          <Stack flexWrap="wrap" direction="horizontal" useFlexGap spacing={1}>
            <Button
              disabled
              color="primary"
              variant="contained"
              onClick={() => {
                closeDialog()
              }}
              sx={{ fontFamily: "RUSBoycott, sans-serif" }}
            >
              Add to staking
            </Button>

            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                closeDialog()
                onAddNftToGame?.()
              }}
              sx={{ fontFamily: "RUSBoycott, sans-serif" }}
            >
              Add to the Game
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Box>
  )
}
