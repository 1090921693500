import { Container, Link } from "@mui/material"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import React from "react"

export const TermsOfUse = () => {
  return (
    <div className="content">
      <Box className="content-head">
        <Container fixed>
          <Typography variant={"h1"} mb={2}>
            Terms Of Use
          </Typography>
        </Container>
      </Box>
      <Box className="content-body">
        <Container fixed>
          <Typography gutterBottom>
            Publication date: November 1, 2022
          </Typography>

          <Typography>
            Zombie TD is a tower defense post-apocalyptic world filled with
            mutant zombies.
          </Typography>

          <Typography>
            Zombie TD is a mobile game with excellent graphics, addictive
            gameplay, and a stable income from the game's first minutes.
          </Typography>

          <Typography gutterBottom>
            By playing Zombie TD, the User gets the opportunity to earn a ZTD
            Token from the first minutes of the game. The User can exchange ZTD
            tokens for any other cryptocurrency using the decentralized exchange
            PancakeSwap.
          </Typography>

          <Typography gutterBottom>
            The Company's Site provides Services for Users of the game Zombie
            TD, described below in these Terms of Use.
          </Typography>

          <Typography gutterBottom>
            The Terms of Use regulate the relationship between the User and the
            Company: receipt, use, payment for the Services, purchase,
            transactions with the ZTD Token, and regulation of the gameplay in
            Zombie TD.
          </Typography>

          <Typography gutterBottom>
            The Terms of Use is a document that is a public offer agreement, and
            by accepting it, you agree to its terms and undertake to comply with
            its provisions.
          </Typography>

          <Typography gutterBottom>
            The User undertakes to comply with the Terms of Use; if the User
            does not comply with the Terms of Use, the Company has the right not
            to provide the Services to the User.
          </Typography>

          <Typography gutterBottom>
            References to the words "we," "our," or "us" (or equivalent terms)
            mean the KISA games Company.
          </Typography>

          <Typography gutterBottom>
            References to the words "you" or "your" (or similar terms in
            content) mean our User, i.e., the person to whom we provide the
            Services.
          </Typography>

          <Typography color={"primary"} sx={{ fontWeight: 500 }}>
            ACCESS TO AND USE THE COMPANY'S SERVICES IN ANY FORM OR INSTALLATION
            OF THE ZOMBIE TD GAME INDICATES ACCEPTANCE OF THESE TERMS OF USE AND
            CONSENT TO FOLLOW THEM.
          </Typography>

          <ol>
            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                DEFINITIONS
              </Typography>

              <ol>
                <li>
                  <b>Account</b> - is a functional part of Zombie TD, with the
                  help of which the User gets the opportunity to receive
                  Services, carry out transactions with the ZTD Token, and
                  purchase/sell NFT.
                </li>

                <li>
                  <b>Arsenal</b> - means in-game items in the Zombie TD game
                  with which the User can destroy the User's opponents
                  (“zombies”).
                </li>
                <li>
                  <b>Battle</b> - is part of the gameplay, the essence of which
                  is to protect the city by destroying the zombies and the Boss.
                </li>
                <li>
                  <b>Bet</b> - is the amount of ZTD Tokens paid by the User as
                  an entry fee to participate in the Tournament.
                </li>
                <li>
                  <b>Boss</b> - is a type of zombie that is particularly
                  powerful and the hardest to kill.
                </li>
                <li>
                  <b>Turret</b> - is the primary weapon in Zombie TD, expressed
                  in the form of NFT Turrets, with which the User defends their
                  city from the invasion of zombies.
                </li>
                <li>
                  <b>Campaign</b> - is the main stable form of a User's income.
                  By capturing a new Campaign sector, the Users increase their
                  indicators and receive ZTD tokens, hero experience, and gold
                  necessary for upgrading Turrets.
                </li>
                <li>
                  <b>
                    Consent of the User (hereinafter referred to as "Consent")
                  </b>{" "}
                  - a voluntary, specific, informed, and unambiguous expression
                  of will in which the User, using a statement or a clear
                  affirmative action, consents to the processing of their
                  Personal Data.
                </li>
                <li>
                  <b>Commander</b> - is a character in the game Zombie TD,
                  expressed in the form of NFT Commanders, the choice of which
                  affects the gameplay and with the help of which the User
                  participates in defense of their city.
                </li>
                <li>
                  <b>Character</b> - is a User's avatar in the Zombie TD game,
                  with the help of which the User controls the gameplay.
                </li>
                <li>
                  <b>Event</b> - is an action in the Zombie TD game where Users
                  compete against each other to earn points and receive a reward
                  expressed in ZTD Token.
                </li>
                <li>
                  <b>Inventory</b> - a functional part of the Zombie TD game in
                  which the User stores the Arsenal and/or Lucky Box.
                </li>
                <li>
                  <b>
                    “KISA games” Company (hereinafter referred to as the
                    “Company” or “KISA games”)
                  </b>{" "}
                  - is a legal entity that provides the User with the Services
                  and the opportunity to play Zombie TD.
                </li>
                <li>
                  <b>
                    The Level of NFT Commanders and NFT Turrets (hereinafter
                    referred to as “Level”)
                  </b>{" "}
                  - is the overall progress of the Commander and/or Turret,
                  which affects their characteristics and the gameplay of the
                  User in the game Zombie TD.
                </li>
                <li>
                  <b>Marketplace</b> - is the internal market of the Zombie TD
                  game, located on the Official Site https://zombietd.app from
                  which Users can sell or purchase NFT for ZTD Tokens.
                </li>
                <li>
                  <b>Mining NFT</b> - is a way to earn NFT by locking a certain
                  amount of ZTD Tokens to get points and then converting points
                  into NFT.
                </li>
                <li>
                  <b>NFT Boxes</b> - are Boxes that the User purchases in the
                  relevant section of the Site “NFT Boxes.”
                </li>
                <li>
                  <b>Personal Account</b> - is a functional part of the Zombie
                  TD game, with the help of which the User can control the
                  gameplay.
                </li>
                <li>
                  <b>Pool</b> - is the total number of ZTD Tokens determined by
                  the Company and used to provide Services on the Site or Events
                  in the game Zombie TD.
                </li>
                <li>
                  <b>Sector</b> - is a local part of the Campaign map where the
                  Battle takes place, for which the User receives a reward.
                </li>
                <li>
                  <b>Services</b> - are a sequence of actions that the Company
                  performs for the User to support the gameplay in Zombie TD and
                  make transactions with ZTD Tokens: acquisition, Staking,
                  Staking NFT, and Mining of NFT.
                </li>
                <li>
                  <b>Store</b> - is a functional part of the Zombie TD game,
                  with which the User can purchase Arsenal and/or Lucky Box.
                </li>
                <li>
                  <b>Staking</b> - is a way of passive income in which Users
                  block their ZTD Tokens with different blocking durations, on
                  which an additional percentage bonus to Staking depends.
                </li>
                <li>
                  <b>Staking NFT</b> - is an NFT Staking “Zombie TD Collection,”
                  in which the User adds a percentage to the Staking of the ZTD
                  Token and upgrades the Level of the NFT itself.
                </li>
                <li>
                  <b>Third Party</b> - is a natural or legal person, public
                  authority, institution, or body other than the User, the
                  Controller, the Processor, and persons authorized by the
                  Controller or the Processor, under their direct authority, to
                  process Personal Data.
                </li>
                <li>
                  <b>Tournament</b> - is an action that takes place in the
                  Zombie TD game over a certain period and consists of
                  protecting the city from zombies. Users participate in
                  determining prizes and receiving rewards.
                </li>
                <li>
                  <b>User</b> - is an individual who plays Zombie TD, receives
                  Services from the Company, and provides their Personal Data.
                </li>
                <li>
                  <b>Zombie TD Site (hereinafter referred to as the "Site")</b>{" "}
                  - is a web page or a group of web pages on the Internet
                  located at:{" "}
                  <Link href={"https://zombietd.app"} target={"_blank"}>
                    https://zombietd.app
                  </Link>
                  , which provides information about the Zombie TD game and the
                  Services provided by the Company.
                </li>
                <li>
                  <b>ZTD Token</b> - is a currency in the game Zombie TD, which
                  the User earns during the game or in other ways provided by
                  the Company, and can be converted into other cryptocurrencies
                  on the PancakeSwap decentralized exchange.
                </li>
                <li>
                  <b>Zombie TD</b> - is a mobile app game set in a
                  post-apocalyptic genre with addictive gameplay and the
                  possibility of earning income in ZTD Tokens.
                </li>
                <li>
                  <b>Zombie TD Official Chat</b> - is the Official channel of
                  the Zombie TD game in the Telegram messenger, in which Users
                  can communicate. The channel is located at:{" "}
                  <Link href={"https://t.me/zombie_td_chat"} target={"_blank"}>
                    https://t.me/zombie_td_chat
                  </Link>
                </li>
                <li>
                  <b>Zombie TD Official Announcements</b> - is the Official
                  channel of the Zombie TD game in the Telegram messenger, in
                  which KISA games publishes announcements about the Events in
                  the Zombie TD game. The chat is hosted at:{" "}
                  <Link href={"https://t.me/zombie_td_news"}>
                    https://t.me/zombie_td_news
                  </Link>
                </li>
                <li>
                  <b>
                    Zombie TD Twitter @zombie_td (hereinafter referred to as
                    “Zombie TD Twitter”)
                  </b>{" "}
                  - is the Official Zombie TD Twitter group where KISA games
                  posts announcements about Zombie TD Events. The group is
                  hosted at:{" "}
                  <Link
                    href={"https://twitter.com/zombie_td"}
                    target={"_blank"}
                  >
                    https://twitter.com/zombie_td
                  </Link>
                </li>
                <li>
                  <b>Zombie TD NFT (hereinafter referred to as “NFT”)</b> - is a
                  non-fungible token in the form of a digital product in the
                  Zombie TD game, which can be used by the User for gameplay or
                  to receive Services from the Company.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                REGISTRATION/AUTHORIZATION
              </Typography>

              <ol>
                <li>
                  To participate in the Zombie TD game, the User must register
                  on the Site and install the Zombie TD application.
                </li>
                <li>
                  To register, the User must indicate on the Site their MetaMask
                  cryptocurrency wallet in the relevant section of the Site, and
                  all the necessary User data for registration will be
                  transferred to the Company. To register on the Site, the User
                  does not need to undergo additional authentication or provide
                  additional data, such as e-mail.
                </li>
                <li>
                  Registration of the User is carried out automatically, and an
                  Account will be created for the User.
                </li>
                <li>
                  Authorization of the User on the Site is carried out
                  automatically, using the MetaMask cryptocurrency wallet or
                  re-entering the User's MetaMask cryptocurrency wallet.
                </li>
                <li>
                  If the User specifies another cryptocurrency wallet during
                  registration/authorization, the Company will create a new
                  Account for the User. Authorization on the Site and access to
                  an already created Account is possible when the User indicates
                  the MetaMask cryptocurrency wallet used during registration on
                  the Site.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                ACCOUNT/PERSONAL ACCOUNT
              </Typography>

              <ol>
                <li>
                  With the help of the Account, the User has the right to carry
                  out the following actions:
                  <ol>
                    <li>receive Services on the Company's Site;</li>
                    <li>participate in bidding on the Marketplace;</li>
                    <li>Staking, Staking NFT, and/or Mining NFT;</li>
                    <li>carry out transactions with the ZTD Token;</li>
                    <li>purchase ZTD Token and/or NFT;</li>
                    <li>receive support from the Company's employees;</li>
                    <li>to communicate with the employees of the Company.</li>
                  </ol>
                </li>

                <li>
                  With the help of the Personal Account, the User has the right
                  to carry out the following actions:
                  <ol>
                    <li>
                      control the Commander and the Turret during the gameplay;
                    </li>
                    <li>specify/change your Nickname;</li>
                    <li>view information about the used by NFT;</li>
                    <li>
                      place a Turret or a Commander in the Campaign/Battle slot;
                    </li>
                    <li>find out information about the NFT Level.</li>
                  </ol>
                </li>

                <li>
                  The User has the right to delete their Account by sending a
                  request in writing to the Company, in which case the Account
                  and all Personal Data and NFT contained in it will be
                  permanently deleted. Users will be obliged to exchange ZTD
                  Tokens for any other cryptocurrency within 24 hours. After the
                  expiration, within 24 hours, the Company will distribute all
                  the User's ZTD Tokens among other Users who remain in the
                  Account of such User. The Company deletes the Account within 3
                  (three) business days of receiving such a request.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                ZTD TOKEN
              </Typography>

              <ol>
                <li>
                  The Zombie TD game provides an opportunity to earn ZTD Tokens,
                  the game currency, for each User during the gameplay.
                </li>
                <li>
                  With the help of ZTD Tokens, you can perform the following
                  actions:
                  <ol>
                    <li>purchase Arsenal and Lucky Box in the Store;</li>
                    <li>
                      carry out transactions on the Marketplace by purchasing
                      NFT;
                    </li>
                    <li>increase the number of ZTD Tokens using Staking;</li>
                    <li>
                      exchange ZTD Tokens for another cryptocurrency,
                      exclusively after listing on the PancakeSwap decentralized
                      exchange.
                    </li>
                  </ol>
                </li>
                <li>
                  The Company does not provide the User with ZTD Tokens after
                  registration on the Site. The User can purchase the ZTD Token
                  in the relevant section of the Site “Buy ZTD” - while the User
                  is using the “Buy ZTD” section, the User will be redirected to
                  the PancakeSwap decentralized exchange, through which they
                  will be able to purchase the ZTD Token.
                </li>
                <li>
                  The ZTD Token is used in Zombie TD as the currency and primary
                  means of payment for any fees.
                </li>
                <li>
                  During the sale of the ZTD Token on the decentralized exchange
                  PancakeSwap, a commission may be charged, provided by the
                  exchange itself, and the seller of ZTD Token.
                </li>
                <li>
                  The NFT Level, upgraded in the game, is essential in Battle.
                  With an increase in the Level, the damage, the cost of the
                  improvement, and the time spent on upgrade growth. If
                  necessary, the User can accelerate the upgrade to the new
                  Level by paying a certain amount of ZTD Tokens.
                </li>
                <li>
                  The NFT has a Level that affects the characteristics of the
                  NFT and may affect the value expressed in ZTD Tokens. The
                  higher the NFT Level, the higher its features become and the
                  greater the advantage the User receives during the gameplay.
                </li>
                <li>
                  The User can increase the number of NFT by using the NFT
                  Mining section of the Site or the Level of NFT by using
                  Staking NFT.
                </li>
                <li>
                  The User has the right to sell NFT to another User using the
                  Marketplace section of the Site.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                GAME PROCESS
              </Typography>

              <ol>
                <li>
                  The game Zombie TD takes place in a post-apocalyptic world,
                  and the goal is to protect your city from the invasion of
                  zombies.
                </li>
                <li>
                  Zombie TD is a tower defense mobile game with a Play-To-Earn
                  concept. Users fight zombies by setting up Turrets of
                  different characteristics to prevent zombies from entering the
                  User's city.
                </li>
                <li>
                  The Zombie TD game is synchronized with the Site, which
                  integrates the core tools of Binance Smart Chain. The Site is
                  compatible with the MetaMask wallet, which is used for
                  authorization, accepting transactions, and paying fees. With
                  the help of the Site, the User can receive Services from the
                  Company.
                </li>
                <li>
                  When registering on the zombietd.app website, each User is
                  given the NFT of the Base Commander Level 1, which is
                  installed in the slot for integration with the game. The User
                  has the right to change their Commander to gain benefits in
                  Zombie TD.
                </li>
                <li>
                  It will be possible to purchase the NFT of a specialized
                  Сommander at the sale of chests with Commanders or after the
                  sale of all chests on the Marketplace for the ZTD Token.
                </li>
                <li>
                  The Zombie TD game provides Levels, by increasing which the
                  User can improve the parameters of the Commander or Turret.
                </li>
                <li>
                  The game has 5 leagues to achieve a balance of equality and
                  competitiveness:
                  <ol>
                    <li>Bronze League;</li>
                    <li>Silver League;</li>
                    <li>Golden League;</li>
                    <li>Platinum League;</li>
                    <li>Diamond League.</li>
                  </ol>
                </li>
                <li>
                  The User starts with the Bronze League and moves to the
                  successive league after passing the 101st Level of the
                  Campaign by at least 1 star. Leagues differ in complexity,
                  strength, and number of monsters.
                </li>
                <li>
                  There are five types of Turrets in Zombie TD, each with five
                  rarities. The higher the rarity, the more damage the Turret
                  has. Turrets differ in type, attack speed, damage, and
                  abilities. In the game, Users can use only those NFT Turrets
                  they own, and they must be installed in the slot on the
                  https://zombietd.app Site on the My Account page.
                </li>
                <li>
                  The NFT Level, upgraded in the game, is of great importance in
                  Battle and with NFT Staking, which is implemented on the Site.
                  With an increase in the Level, the damage, the cost of the
                  improvement, and the time spent on upgrade growth. If
                  necessary, the User can accelerate the upgrade to the new
                  Level by paying a certain amount of ZTD Tokens.
                </li>
                <li>
                  Commander leveling is limited to 100 levels. With the receipt
                  of each new Level, the Commander is given three points to
                  distribute and improve the following indicators:
                  <ol>
                    <li>+% to gold after the Battle;</li>
                    <li>+% to the experience of the Commander;</li>
                    <li>+% to the attack of all Turrets;</li>
                    <li>+% to ZTD Token Staking NFT.</li>
                  </ol>
                </li>
                <li>
                  By distributing Commander upgrade points, the Users determine
                  the path of development of their Character, making the
                  gameplay as varied, exciting, and enjoyable as possible. If
                  necessary, you can reset the improvement points for ZTD Token
                  and redistribute them again. Only one Commander can be used in
                  the game, so the User should think carefully about which
                  Commander best suits the User's chosen strategy.
                </li>
                <li>
                  Users can upgrade each Turret to Level 6 for the iron received
                  after killing zombies on the Battlefield. After improvement,
                  the radius of destruction, damage, and cost increase.
                </li>
                <li>
                  The following types of Turrets are provided in the Zombie TD
                  game, which differ in type, attack speed, damage, and
                  abilities:
                  <ol>
                    <li>
                      <b>Machine Gun.</b> Deals damage to the nearest single
                      target with high attack speed. Effective against weak
                      zombies;
                    </li>
                    <li>
                      <b>Rocket.</b> Deals massive damage with medium attack
                      speed in an area. Effective against groups of zombies;
                    </li>
                    <li>
                      <b>Laser.</b> Deals damage to the nearest single target
                      and is the most effective Turret against Bosses. As soon
                      as the Boss enters the radius of destruction, the priority
                      switches to it, and in the process of continuous attack,
                      an increase in damage occurs. When the target changes, the
                      damage returns to the base value;
                    </li>
                    <li>
                      <b>Artillery.</b> Deals high damage to the nearest single
                      target while stunning them for a particular time. It can
                      only be installed in front of the city's entrance at the
                      city's main gate;
                    </li>
                    <li>
                      <b>Electromagnetic.</b> Deals minor AoE damage in an area
                      of effect while slowing the zombie's movement speed.
                    </li>
                  </ol>
                </li>
                <li>
                  In addition to the NFT of the Base Commander, there will be
                  another NFT of five specialized Commanders with 5 rarities
                  that increase the attack, damage radius, and ability
                  effectiveness of one of the five Turrets:
                  <ol>
                    <li>
                      <b>Base Сommander</b> - is the default Commander, which
                      does not have the ability;
                    </li>
                    <li>
                      <b>Machine Gunner</b> - increases the damage, radius of
                      destruction, and attack speed of the Machine Gun;
                    </li>
                    <li>
                      <b>Rocketeer</b> - increases the damage, radius, and AoE
                      of the Rocket Turret;
                    </li>
                    <li>
                      <b>Artilleryman</b> - increases the damage, radius, and
                      stun duration of the Artillery Turret;
                    </li>
                    <li>
                      <b>Laserwoman</b> - increases damage, the radius of
                      destruction and acceleration of damage increase rate;
                    </li>
                    <li>
                      <b>Electromagnet</b> - increases damage and radius and
                      increases the slowdown effect of the Electromagnetic
                      Turret.
                    </li>
                  </ol>
                </li>
                <li>
                  There are two types of resources in Zombie TD:
                  <ol>
                    <li>Gold;</li>
                    <li>Iron.</li>
                  </ol>
                </li>
                <li>
                  The Battle begins with a certain amount of iron, used
                  exclusively on the Battlefield to build and upgrade Turrets.
                  The User receives iron for killing zombies based on their
                  strength.
                </li>
                <li>
                  The User gains experience and gold by participating in the
                  Battles of the ZTD Company and/or the Tournament, depending on
                  the Battle's outcome. After each Battle, the User receives the
                  mined gold, and the result is summed up and automatically
                  entered into the rating table. At the end of Gold mining,
                  rewards in the form of ZTD Tokens are awarded to the User by
                  the gold mined and the position in the rating table.
                </li>
                <li>
                  After each Battle, the User receives gold and experience.
                  Depending on the amount of experience gained, the Level of the
                  Commander is determined.
                </li>
                <li>
                  The Zombie TD game provides additional ammunition for the User
                  to destroy zombies.
                </li>
                <li>
                  Zombie TD provides five types of additional ammo:
                  <ol>
                    <li>
                      <b>Grenade</b> - weak damage, small radius of destruction;
                    </li>
                    <li>
                      <b>Dynamite</b> - average damage, average damage radius;
                    </li>
                    <li>
                      <b>Cryogenic Bomb</b> - weak damage, large radius of
                      destruction, slowing down for 5 seconds;
                    </li>
                    <li>
                      <b>Hydrogen Bomb</b> - medium damage, large radius of
                      destruction;
                    </li>
                    <li>
                      <b>Nuclear Bomb</b> - strong damage, large radius of
                      destruction.
                    </li>
                  </ol>
                </li>
                <li>
                  The User can purchase Arsenal and/or Lucky Box in the Store
                  for ZTD Tokens. The Company sets the cost of Arsenal and Lucky
                  Box items and can change it randomly.
                </li>
                <li>
                  The User can place Arsenal and Lucky Box in their Inventory.
                  The size of the Inventory is limited by the Zombie TD game,
                  which is subject to change by the Company.
                </li>
                <li>
                  The User receives a reward of gold for achievements while
                  playing Zombie TD, which the Company can change in any order.
                </li>
                <li>
                  In Zombie TD, there are 23 types of zombies with different
                  parameters and distinctive features. They have different
                  movement speeds, health points, sizes, and damage. The larger
                  the zombie, the greater its attack and health points.
                </li>
                <li>
                  When killing zombies during the Battle, the User receives iron
                  - a resource designed to increase the Level of the Turret
                  during a single Battle.
                </li>
                <li>
                  At the end of the Battle for killing zombies, the User is
                  awarded gold, experience, and rating points in the Tournament.
                  The stronger the zombies, the more experience, gold, and
                  rating points are awarded. The number of rating points affects
                  the number of ZTD Tokens received by the User as a reward -
                  the more rating points, the greater the number of ZTD Tokens
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                BATTLE
              </Typography>

              <ol>
                <li>
                  The main game Events occur on the Battlefield, a ruined city
                  with roads passing through it. Once in the city's vicinity,
                  the zombies go to a concrete wall, where the base and the last
                  surviving people are located. The User needs to place the
                  Turrets along the road in the designated platforms to prevent
                  the zombies from entering the city.
                </li>
                <li>
                  When the zombies pass the entire Battlefield, they run into
                  the last stronghold of the living city - this is the gate. The
                  gate has HP, which can be increased by increasing the
                  Commander's Level or vacating Campaign map Sectors. Zombies
                  hit the gate until it breaks or until the Turrets near the
                  gate kill them.
                </li>
                <li>
                  A loss in the Battle is considered a breakthrough by zombies
                  into the besieged city of the User. The win is considered
                  killing all zombies surrounding the User's city.
                </li>
                <li>
                  The Battle always starts with a certain amount of iron, used
                  exclusively on the Battlefield to build and upgrade Turrets.
                  The User receives iron for killing zombies based on their
                  strength.
                </li>
                <li>
                  Only those Turrets can be used during the Battle, the NFT of
                  which the User has installed in a slot on the Site in their
                  Account. The User cannot sell Turrets on the Marketplace
                  inserted in the Account slot. To sell a Turret on the
                  Marketplace, the User must first get such a Turret from the
                  slot of their Account and only then put it up for sale on the
                  Marketplace. The User cannot place Turrets in the slot of
                  their Account that is listed on the Marketplace - they must
                  first be removed from sale on the Marketplace and only then
                  added to the slot of their Account.
                </li>
                <li>
                  Any Turret placed on the Battlefield can be upgraded to Level
                  6 for iron.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                CAMPAIGN
              </Typography>

              <ol>
                <li>
                  A Campaign is the main stable form of a User's income. By
                  capturing a new Campaign sector, the Users increase their
                  indicators and receive ZTD Tokens, hero experience, and gold
                  necessary for upgrading Turrets.
                </li>
                <li>
                  The Campaign has five difficulty Levels and is presented in
                  the form of a global map, which is divided into 101 sectors.
                  With each sector passed, the strength and number of zombies
                  attacking the city increased. The Battle map changes every 5th
                  sector, and the 101st sector is the extreme at the current
                  difficulty Level. It is unique, and there are no simple
                  zombies - only Bosses in large numbers attack the city.
                </li>
                <li>
                  The Sector can be passed to 0, 1, 2, and 3 stars. The Sector
                  can be completed with 0, 1, 2, and 3 stars when passing the
                  sector with 3 stars without loss, it becomes possible to
                  choose one of the following bonuses that the Sector freed from
                  zombies will bring:
                  <ol>
                    <li>+% to gold in the game Zombie TD;</li>
                    <li>+% to the experience of the Commander;</li>
                    <li>+% to the attack of all Turrets;</li>
                    <li>+% to Staking ZTD Tokens;</li>
                    <li>+% to iron on the Battlefield;</li>
                    <li>+ durability (HP) to the wall of the city.</li>
                  </ol>
                </li>
                <li>
                  The Campaign is a simple, guaranteed, and stable way of
                  earning ZTD Tokens in the game for 12 months. The User
                  receives ZTD Tokens for completing Campaign Levels. The better
                  the Users defend the city, the more ZTD Tokens will be earned,
                  the amount of which depends on how many stars they received
                  for completing the Level:
                  <ol>
                    <li>
                      3 stars 100% wall HP at the end of the Battle - the User
                      receives 100% of ZTD Token per Level;
                    </li>
                    <li>
                      2 stars 99% - 50% of the wall's HP at the end of the
                      Battle - get 50% of ZTD Token per Level;
                    </li>
                    <li>
                      1 star 49% - 1% of the wall's HP at the end of the Battle
                      - receives 25% of ZTD Token per Level;
                    </li>
                    <li>0 stars (defeat) - gets 0 ZTD Token per Level.</li>
                  </ol>
                </li>
                <li>
                  The Users receive <b>ZTD Token</b> for stars only once. If
                  they passed the Campaign by 1 star and then outplayed by 2 or
                  3, then, in this case, they will receive a reward in
                  proportion to the difference.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                TOURNAMENT “ENDLESS MODE”
              </Typography>

              <ol>
                <li>
                  The Tournament is a unique map with five directions which
                  zombies can appear from. The difference between this mode and
                  the Campaign is that the zombies become stronger with each
                  wave, and the number of waves is not limited.
                </li>
                <li>
                  During the Tournament, the User earns points for killing
                  zombies. The User has the right to participate in the
                  Tournament unlimited times. The best attempt is recorded in
                  the rating table; at the end of the week, the results are
                  summed up, and the Users are awarded ZTD Tokens, according to
                  the prize placed in the rating table. The amount of the reward
                  (the number of ZTD Tokens) is determined by KISA games.
                </li>
                <li>
                  Endless mode is one of the main types of earnings in the game,
                  which requires effort and careful preparation. It is split by
                  the league to balance new and experienced Users.
                </li>
                <li>
                  There are 2 types of Tournaments in which Users earn points
                  for killing zombies:
                  <ol>
                    <li>
                      <b>King endless mode</b> - a mode that runs throughout the
                      week from Monday 00:00 UTC to Sunday 23:59 UTC. KISA games
                      allocates 50% of ZTD Tokens from the total weekly amount
                      of the ZTD Token Pool. To participate, the User must pay
                      the Bet, which is added to the weekly Pool.
                    </li>
                    <li>
                      <b>Daily endless mode</b> - a daily mode that starts at
                      00:00 UTC and ends at 23:59. KISA games allocates 30% of
                      ZTD Tokens from the total weekly supply of the ZTD Token
                      Pool.
                    </li>
                  </ol>
                </li>
                <li>
                  Rewarding in endless modes is calculated using the formula:
                  (Total User points / Total points of all User) * Reward Pool.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                EVENT
              </Typography>

              <ol>
                <li>
                  Events are a type of activity in which Users compete to earn
                  points. These points are entered into the rating tables, after
                  which the Users divide the allocated ZTD Token Pool among
                  themselves, according to the points received.
                </li>
                <li>
                  There are four Events in Zombie TD:
                  <ol>
                    <li>
                      <b>Development</b> is a weekly Event from Friday 00:00 UTC
                      to Sunday 23:59 UTC. The purpose of the Event is to
                      increase the firepower of the Turrets. It requires time
                      for upgrading, and for every minute spent on improvement,
                      the User is awarded one point. The time reduced by the
                      boost is also taken into account. All points are summed up
                      and recorded in the rating table of Users.
                    </li>
                    <li>
                      <b>Kill-Event</b> is a weekly Event from Monday 00:00 UTC
                      to Thursday 23:59 UTC. The purpose of the Event is to
                      destroy zombies in which Users earn points and get into
                      the rating table, thus determining and rewarding the best
                      participants in the Event of the current week. Each zombie
                      kill brings a certain number of points; the stronger the
                      zombie, the more points. Points are awarded upon
                      completion of the Battle in the Tournament or Campaign
                      mode; the result is summed up each time and automatically
                      entered into the rating table of Users.
                    </li>
                    <li>
                      <b>Gold mining</b> is a weekly Event running from Monday
                      00:00 UTC to Thursday 23:59 UTC, the results of which are
                      summed up every day at 23:59 UTC. The purpose of the Event
                      is to reward all miners of the current day with gold,
                      which can be obtained during the passage of the Campaign
                      and/or the Tournament. After each Battle, the User
                      receives the mined gold, and the result is summed up and
                      automatically entered into the User's rating table. At the
                      end of the Event, rewards in the form of ZTD Tokens are
                      awarded in accordance with the gold mined.
                    </li>
                    <li>
                      <b>Token mining</b> is a weekly Event running from Monday
                      00:00 UTC to Thursday 23:59 UTC, which ends every day at
                      23:59 UTC. The purpose of the Event is to reward all Users
                      (miners) with the ZTD Token of the current day in the
                      Daily endless mode and Gold mining Events. Points are
                      awarded for mined ZTD Tokens; after receiving them, the
                      result is summed up and automatically entered into the
                      rating table.
                    </li>
                  </ol>
                </li>
                <li>
                  At the end of the Event, rewards in the form of ZTD Tokens are
                  awarded by the points earned. 5% of ZTD Tokens from the weekly
                  pool are allocated for each of them.
                </li>
                <li>
                  The number of ZTD Tokens awarded to each User is calculated
                  using the formula: (Total points for a single User / Total
                  points for all Users) * ZTD Token Reward Pool.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                NFT Boxes
              </Typography>

              <ol>
                <li>
                  There are two types of NFT Boxes:
                  <ol>
                    <li>
                      <b>NFT Boxes with Commanders.</b> The User will have 5
                      types of Boxes to choose from, and each Box will contain a
                      Commander's NFT of random rarity.
                    </li>
                    <li>
                      <b>NFT Boxes with Turrets.</b> The User will have 5 types
                      of Boxes to choose from, and each Box will contain a
                      Turrets NFT of random rarity.
                    </li>
                  </ol>
                </li>

                <li>
                  The User may purchase NFT Boxes in the <b>“Boxes”</b> section
                  of the Site for BUSD tokens.
                </li>

                <li>
                  The cost of NFT Boxes and their characteristics are indicated
                  in the “Boxes” section of the Site. The Company reserves the
                  right to change the cost and characteristics of NFT Boxes
                  and/or add a new type of NFT Boxes or increase the number of
                  NFT Boxes in the Zombie TD game, including during the sale of
                  NFT Boxes in the “Boxes” section of the Site, at any time
                  without User notifications.
                </li>

                <li>
                  The User has the right to open NFT Boxes and receive one NFT
                  Turrets or NFT Commanders of the type of random rarity chosen
                  by the User.
                </li>
                <li>
                  The Company's rules provide that the maximum number of NFT
                  Boxes of the same type of rarity for one Account is 5 pieces,
                  and the total number of NFT Boxes is 25.
                </li>
                <li>
                  Users who participate in IDO on the Site receive a guaranteed
                  allocation in the sale of NFT Boxes. The User will be given up
                  to two hours to purchase NFT Boxes. After this time, all
                  interested Users can buy the remaining NFT Boxes.
                </li>
                <li>
                  KISA games has the right to arrange sales of NFT Boxes on its
                  Site without obtaining Consent from the User.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                USER REWARDS
              </Typography>

              <ol>
                <li>
                  The reward mechanism for ZTD token holders is as follows.
                  During the week, snapshots of Users' game balances will be
                  taken. Whose scores will have a non-zero balance of ZTD
                  Tokens, those accounts will be rewarded from Pool's "sales
                  commissions."
                </li>

                <li>
                  The "sale fee" Pool will be formed during the week by charging
                  a commission when selling the ZTD token on the DEX{" "}
                  <Link href={"https://pancakeswap.finance/"} target={"_blank"}>
                    pancakeswap.finance
                  </Link>
                </li>

                <li>
                  The reward for ZTD token holders is calculated by the formula:
                  (Total number of ZTD tokens in the User's wallet / Total
                  number of ZTD tokens of all Users) * Reward pool.
                </li>
                <li>
                  The reward will be credited to the User's gaming account at
                  the end of the week and displayed on the transaction page.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                BURNING TOKEN ZTD
              </Typography>

              <ol>
                <li>
                  Burning Tokens ZTD will create scarcity by reducing the
                  issuance of the Token ZTD, increasing its value both in the
                  market and in the Zombie TD game.
                </li>
                <li>
                  The burning algorithm is as follows: all Tokens ZTD received
                  from commissions on the market, as well as spent in the game
                  when buying inventory or to speed up the leveling of towers,
                  will be burned every week on Monday, followed by the
                  publication of a burning report on social networks.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                LUCKY Box
              </Typography>

              <ol>
                <li>
                  The User can purchase the Lucky Box from the Store in the
                  Zombie TD game application using ZTD Tokens. The Company
                  determines the cost of the Lucky Box, which can be changed
                  arbitrarily.
                </li>
                <li>
                  Lucky Boxes contain Arsenal items that the User receives at
                  the time of their opening in random order.
                </li>
                <li>
                  Lucky Boxes may be stored in the User's Inventory for an
                  indefinite amount of time.
                </li>
                <li>
                  There are two types of Lucky Box:{" "}
                  <ol>
                    <li>
                      <b>Big Lucky Box.</b> The amount of Arsenal is high, but
                      also the cost is high.
                    </li>
                    <li>
                      <b>Small Lucky Box.</b> Low cost but a small amount of
                      Arsenal.
                    </li>
                  </ol>
                </li>
                <li>
                  The Company reserves the right to change the characteristics
                  of the Lucky Box, including, but not limited to: their cost,
                  the number of Arsenal, new types, etc.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                SERVICES
              </Typography>

              <ol>
                <li>
                  KISA games provides Services to Users of Zombie TD. Users can
                  use the Services of the Company on the Site.
                </li>
                <li>
                  The Company provides the following Services:
                  <ol>
                    <li>
                      <b>IDO.</b> It is the Company's initial offer to sell ZTD
                      Tokens to whitelisted Users. This Service is available for
                      a limited period. The IDO will be held at several launch
                      sites, including the Company's Site. The terms and
                      procedure for obtaining the purchased ZTD Token are
                      determined and stipulated by the sites where the IDO will
                      be held, including on the Company's Site. After purchasing
                      the ZTD Token, it will automatically appear on the balance
                      of the User Account. The receipt of ZTD Tokens will be
                      available after listing on the PancakeSwap exchange. The
                      Companies carry out listing in a certain period, which is
                      indicated on the Site. Whitelisted Users who participated
                      in the IDO on the Site receive a guaranteed distribution
                      in the sale of NFT Boxes with NFT. The IDO's conditions,
                      which are carried out on the Company's Site, are indicated
                      on the Company's Site and the sites that carry it out and
                      can be changed by such sites and/or KISA games in any
                      order. The conditions for adding the User to the White
                      List are indicated by the Company on the Site and can be
                      changed in any order. The conditions for adding the User
                      to the WhiteList on such sites are specified in the
                      internal documents. KISA games single-handedly determines
                      the venues that carry out IDOs. KISA games indicates such
                      sites individually on the Site.
                    </li>
                    <li>
                      <b>Marketplace.</b> This Service provides the User with
                      the opportunity to sell/buy NFT. The currency of the
                      sale/purchase operations is the ZTD Token. The User
                      (seller) pays a commission from the cost of the sold NFT
                      for using the Marketplace, which is indicated in the
                      relevant section of the Site. The NFT seller automatically
                      charges the Company's commission from the amount of the
                      NFT sale. NFT for sale cannot be installed in the Zombie
                      TD slot and used for Staking NFT. In the Event of a
                      dispute between the User-seller and the User-buyer, any
                      parties to the dispute have the right to contact the
                      Company's support service to resolve the dispute. The
                      Company may resolve the dispute at its discretion, and the
                      Company's decision is not subject to appeal.
                    </li>
                    <li>
                      <b>Staking.</b>{" "}
                      <ol>
                        <li>
                          The User has the right to Stake the ZTD Token in one
                          of 4 plans. They differ in the duration of the ZTD
                          Token lock and the additional percentage of the
                          Staking bonus:
                          <ol>
                            <li>
                              <b>Basic</b> - blocking the ZTD Token for 7
                              calendar days without receiving additional
                              interest;
                            </li>
                            <li>
                              <b>Standard</b> - blocking ZTD Token for 2 weeks
                              with a 10% bonus;
                            </li>
                            <li>
                              <b>Premium</b> - blocking the ZTD Token for a
                              month with a bonus of 20% from the distribution of
                              ZTD Tokens;
                            </li>
                            <li>
                              <b>Pro</b> - blocking of the ZTD Token for 3
                              months with a bonus of 50% from the distribution
                              of ZTD Tokens.
                            </li>
                          </ol>
                        </li>
                        <li>
                          200,000,000 ZTD Tokens have been allocated for
                          Staking. The distribution of ZTD Tokens takes place in
                          equal shares every day during the year. Every day,
                          547,945 (200,000,000 / 365) ZTD Tokens are distributed
                          among all Users in proportion to the contribution and
                          additional bonuses.
                        </li>
                        <li>
                          You can increase the ZTD Tokens received by the User
                          in one of the following ways:
                          <ol>
                            <li>by increasing the Level of the Commander;</li>
                            <li>
                              liberating new territories in the main Campaign by
                              choosing a bonus after completing the Sector +% of
                              the Staking of the ZTD Token;
                            </li>
                            <li>by Staking NFT.</li>
                          </ol>
                        </li>

                        <li>
                          <b>NFT Staking.</b> The Site zombietd.app implements
                          NFT Staking “Zombie TD Collection,” in which the
                          player adds a percentage to the Staking of the ZTD
                          Token and upgrades the level of the NFT itself. The
                          higher the rarity of the NFT, the higher the rate.
                        </li>

                        <li>
                          Below is a table of the distribution of additional
                          percent for Staking NFT Zombie TD Collection:
                          <TableContainer component={Paper} sx={{ my: 2 }}>
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>NFT rarity</TableCell>
                                  <TableCell align="center">
                                    The amount of additional interest
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    Common
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                  >
                                    5%
                                  </TableCell>
                                </TableRow>

                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    Uncommon
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                  >
                                    10%
                                  </TableCell>
                                </TableRow>

                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    Rare
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                  >
                                    30%
                                  </TableCell>
                                </TableRow>

                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    Epic
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                  >
                                    70%
                                  </TableCell>
                                </TableRow>

                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    Legendary
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                  >
                                    200%
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </li>

                        <li>
                          <b>Mining NFT.</b> The Site https://zombietd.app
                          implements NFT mining, for which Users need to stake
                          NFT ZTD Tokens. Points are awarded for ZTD Tokens;
                          there is also a limit of ZTD Tokens that can be staked
                          in NFT Mining. Once Users have collected the required
                          number of points, they can exchange them for a
                          specific NFT of any rarity. The number of NFT for NFT
                          Mining is limited and specified by KISA games.
                          <ol>
                            <li>
                              <b>Boxes.</b> In this Site section, the User has
                              the right to purchase any NFT Boxes for BUSD
                              Tokens or discover their characteristics.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                SANCTIONS
              </Typography>

              <ol>
                <li>
                  The User is prohibited from:{" "}
                  <ol>
                    <li>register more than two Accounts;</li>
                    <li>carry out hacking activities;</li>
                    <li>
                      carry out actions that violate the laws of their country
                      or international law;
                    </li>
                    <li>insult another User in any way;</li>
                    <li>
                      disrespectfully and/or rudely and/or use profanity when
                      communicating with the support service or any other
                      employee of the Company;
                    </li>
                    <li>
                      use third-party computer programs and/or codes and/or
                      hardware (“cheating”) to gain an advantage in Zombie TD;
                    </li>
                    <li>transfer inaccurate Personal Data;</li>
                    <li>
                      violate the intellectual property rights of the Company;
                    </li>
                    <li>
                      use the intellectual property rights of the Company in
                      favor of Third Parties;
                    </li>
                    <li>
                      distribute false, defamatory reviews about the game Zombie
                      TD without any reason;
                    </li>
                    <li>
                      disseminate false information of any nature about the KISA
                      games Company, including about its owners and employees
                      and/or the Zombie TD game;
                    </li>
                    <li>
                      violate the Terms of Use and/or the Privacy Policy in any
                      way.
                    </li>
                  </ol>
                </li>

                <li>
                  In case of violation by the User of any of the provisions of
                  clause 13.1 The Company has the right to apply the following
                  sanctions to the User:
                  <ol>
                    <li>send a warning to the User;</li>
                    <li>
                      restrict the User's access to Zombie TD for up to 6
                      months;
                    </li>
                    <li>
                      restrict the User's access to their Account for up to 6
                      months;
                    </li>
                    <li>
                      delete all User data, including their Account, Character,
                      and Commander; in such a case, the User will no longer
                      have access to Zombie TD.
                    </li>
                  </ol>
                </li>

                <li>
                  In case of violation by the User of clause 13.1, the Company
                  has the right to determine the amount of the sanction at its
                  discretion, depending on the severity of the violation.
                </li>

                <li>
                  The Company has the right to remove the User's nickname or
                  remove the User from the Zombie TD Official Chat, Zombie TD
                  Official Announcements, Zombie TD Twitter Official group or
                  issue a warning if the User's nickname or messages in the
                  Zombie TD Official Chat contain the following information:
                  <ol>
                    <li>
                      contains signs of a pornographic or sexual nature,
                      profanity;
                    </li>
                    <li>humiliates the honor and dignity of other Users;</li>
                    <li>
                      incites interracial, interethnic, religious, sexual, and
                      political hatred; violates the rights and/or freedoms of
                      other Users;
                    </li>
                    <li>contains advertising;</li>
                    <li>
                      contains links to third-party sites that may damage the
                      software of another User;
                    </li>
                    <li>
                      contains links to sites that distribute pornography,
                      unlicensed software;
                    </li>
                    <li>
                      contains links, is an open advertisement of network
                      marketing sites, affiliate marketing, pyramid schemes,
                      earnings at home, and other types of fraud;
                    </li>
                    <li>
                      contains links to websites that advertise goods and/or
                      services for adults;
                    </li>
                    <li>
                      contains links degrading the honor and dignity of other
                      Users;
                    </li>
                    <li>contains links to escort and dating sites;</li>
                    <li>
                      contains links to sites that advertise beliefs, personal
                      growth courses, get rich quick, and the like;
                    </li>
                    <li>
                      contains various ways to attract potential Users, such as
                      "get rich quick," "build your wealth," "financial
                      independence," etc.
                    </li>
                    <li>
                      contains links to online trading, and trading tips related
                      to the stock markets, including but not limited to message
                      boards;
                    </li>
                    <li>
                      contains links to the sale of pharmaceutical products,
                      including prescription drugs and counterfeit drugs,
                      nutritional, herbal, and drug supplements, or medicines;
                    </li>
                    <li>
                      contains proposals for loan restructuring and debt relief;
                    </li>
                    <li>
                      contains links and/or information about mortgages and
                      loans;
                    </li>
                    <li>contains links to the list of brokers;</li>
                    <li>contains religious and/or political information;</li>
                    <li>
                      contains links to betting/gambling services, including
                      poker, casinos, and sporting Events;
                    </li>
                    <li>
                      contains references to political parties and/or
                      organizations;
                    </li>
                    <li>
                      contains advertising of alcoholic and/or tobacco products;
                    </li>
                    <li>contains spam;</li>
                    <li>contains incitement to violence;</li>
                    <li>contains slander against other Users;</li>
                    <li>
                      given that it is impossible to foresee all the options for
                      violations of the rules of communication by the User; KISA
                      games reserves the right to apply the provision of clause
                      14.1 to the User at its discretion if it considers that
                      communication or content published by the User violates
                      the rights of other Users or contains information that
                      offends other Users.
                    </li>
                  </ol>
                </li>
                <li>
                  In case of violation by the User of these Terms of Use, the
                  Company has the right to block the User's Account and not
                  provide the User with the Services indicated on the Site. In
                  such case, the User is solely responsible for all of their ZTD
                  Tokens and undertakes to exchange their ZTD Tokens within 24
                  hours of receiving notice from the Company. After the
                  expiration of 24 hours from the moment of notification by the
                  Company, all ZTD Tokens of such User will be distributed among
                  other Users. The Company does not restrict access to the
                  Zombie TD game to such a User. Still, it does not provide any
                  Services, the possibility of obtaining ZTD Tokens by such a
                  User, or participation in the Event.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                REFUNDS
              </Typography>

              <ol>
                <li>
                  The Company does not return the User's funds:
                  <ol>
                    <li>
                      spent on the acquisition of the ZTD Token on the Site;
                    </li>
                    <li>
                      in the Event of an error during the acquisition of the ZTD
                      Token;
                    </li>
                    <li>
                      in case of bankruptcy or reorganization of the Company;
                    </li>
                    <li>in case of closing the Zombie TD game.</li>
                  </ol>
                </li>

                <li>
                  The Company does not return ZTD Tokens:{" "}
                  <ol>
                    <li>
                      in case of loss of ZTD Tokens due to illegal or erroneous
                      actions of the User;
                    </li>
                    <li>
                      in case of loss of ZTD Tokens due to a hacker attack or
                      actions of the PancakeSwap Site or fraudulent activities
                      by any Third Parties;
                    </li>
                    <li>in the case of transactions with the ZTD Token.</li>
                  </ol>
                </li>

                <li>
                  The Company does not return BUSD Tokens:{" "}
                  <ol>
                    <li>in case of NFT Boxes acquisition on the Site;</li>
                    <li>
                      in case of loss of BUSD Tokens due to illegal or erroneous
                      actions of the User;
                    </li>
                    <li>
                      in case of loss of BUSD Tokens due to a hacker attack or
                      actions of the PancakeSwap Site or fraudulent activities
                      by any Third Parties.
                    </li>
                  </ol>
                </li>

                <li>
                  The Company does not return the Bet paid by the User to
                  participate in the Tournament in any case.
                </li>

                <li>
                  The return of funds and/or ZTD Tokens is not provided by the
                  Company, and KISA games has the right not to return funds
                  and/or ZTD Tokens in any case. If the User does not agree with
                  these conditions, then they undertake not to use the Services
                  of the Company and undertake to exchange their ZTD Tokens
                  within 24 hours from the moment of refusal of the Terms of
                  Use. After the expiration of 24 hours from the moment of
                  refusal of the Terms of Use, all ZTD Tokens of such User will
                  be distributed among other Users. The Company does not
                  restrict access to the Zombie TD game to such a User. Still,
                  it does not provide any Services, the possibility of obtaining
                  ZTD Tokens, or participation in the Event.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                RESPONSIBILITY
              </Typography>

              <ol>
                <li>
                  To the extent permitted by law, the Company provides the
                  materials and Services "as is." It means that the Company
                  makes no warranties, including but not limited to warranties
                  of merchantability and fitness for a particular purpose.
                </li>
                <li>
                  KISA games Company does not guarantee the User's earnings in
                  any form as a result of participation in the Zombie TD game or
                  use of the Services, including but not limited to Staking,
                  Staking NFT, and/or Mining.
                </li>
                <li>
                  The Company does not guarantee the User's victory in the
                  Battle, a prize in the Tournament, and/or victory in the
                  Campaign.
                </li>
                <li>
                  The Company does not guarantee that the Services provided will
                  fully meet the User's expectations.
                </li>
                <li>
                  The Company does not guarantee the accuracy of the
                  information, the absence of errors on the Site, or the
                  reliability of the information provided by the Company.
                </li>
                <li>
                  The Company is not responsible for implementing IDO by third
                  party sites, per clause 13.2.1 these Terms of Use.
                </li>

                <li>
                  KISA games reserves the right, but not the obligation to:
                  <ol>
                    <li>monitor violations of these Terms of Use;</li>
                    <li>
                      take appropriate legal action against anyone who, in their
                      sole discretion, violates the law or these Terms of Use,
                      including, without limitation, notifying such User to law
                      enforcement authorities;
                    </li>
                    <li>
                      in our sole discretion and without limitation, notice or
                      liability, remove from the Site and Zombie TD or otherwise
                      disable all files and content that are excessive in size
                      or in any way burden our systems;
                    </li>
                    <li>
                      operate the Site and Zombie TD in a manner that protects
                      our rights and property and facilitates the proper
                      functioning of the Site and Zombie TD.
                    </li>
                  </ol>
                </li>

                <li>
                  The User is solely responsible for the following:{" "}
                  <ol>
                    <li>security of access to your Account;</li>
                    <li>security of access to your cryptocurrency wallet;</li>
                    <li>
                      for the results of participation in the game Zombie TD;
                    </li>
                    <li>
                      for the consequences of their actions in the gameplay of
                      Zombie TD;
                    </li>
                    <li>for the results of the Battle;</li>
                    <li>for progress in the Campaign;</li>
                    <li>for the results of the Tournament and/or the Event;</li>
                    <li>for choosing a Commander and/or Turret;</li>
                    <li>for NFT Mining and/or Staking NFT and/or Staking;</li>
                    <li>
                      for the sale/purchase of game items and/or NFT on the
                      Marketplace and/or purchase the NFT Boxes.
                    </li>
                  </ol>
                </li>

                <li>
                  The Company is not responsible:{" "}
                  <ol>
                    <li>for the security of the User Account;</li>
                    <li>
                      for the access of Third Parties to the User's
                      cryptocurrency wallet;
                    </li>
                    <li>
                      for any indirect, actual, or consequential damages of the
                      User that arose as a result of the gameplay in Zombie TD
                      and/or the receipt of the Services, even if we were
                      advised of the possibility of such damages or they arose
                      due to negligence;
                    </li>
                    <li>
                      for unauthorized use of the Account and/or Personal
                      Account by a Third Party;
                    </li>
                    <li>for the actions of the User in Zombie TD;</li>
                    <li>
                      for the implementation by the User of Staking, Staking
                      NFT, and Mining NFT;
                    </li>
                    <li>
                      for the amount of Arsenal and/or NFT received, including
                      but not limited to opening NFT Boxes;
                    </li>
                    <li>for improving the NFT;</li>
                    <li>fraudulent actions of another User;</li>
                    <li>for the receipt by the User of earnings;</li>
                    <li>for the gameplay of Zombie TD;</li>
                    <li>
                      for the implementation by the User of transactions with
                      the ZTD Token;
                    </li>
                    <li>
                      for the security of the User's cryptocurrency wallet;
                    </li>
                    <li>for the receipt by the User of remuneration;</li>
                    <li>
                      for the exchange of the ZTD Token by the User for any
                      other cryptocurrency and any cryptocurrency to ZTD Token;
                    </li>
                    <li>
                      for the receipt by the User of cryptocurrency as a result
                      of the exchange of the ZTD Token;
                    </li>
                    <li>
                      for actions and/or technical malfunctions of the
                      PancakeSwap Site.
                    </li>
                  </ol>
                </li>

                <li>
                  KISA games does not offer additional earnings and/or send
                  links to third-party Services and applications and/or does not
                  require the provision of additional data, including in Zombie
                  TD Official Chat, a group of announcements in Telegram, and
                  Zombie TD Twitter. The Company is not liable to reimburse any
                  losses to the User in the Event of the above situations.
                </li>

                <li>
                  KISA games strongly recommends checking all links posted by
                  other Users on Zombie TD Official Chat and Zombie TD Twitter
                  and is not responsible for posting any fraudulent schemes
                  through such links. We strongly recommend that you avoid
                  fraudulent schemes. KISA games is not responsible for the
                  content of such links posted by the User.
                </li>

                <li>
                  The User must not use the Services or Zombie TD for commercial
                  purposes. You may not sell, resell, license, sublicense,
                  transfer or distribute the Services or the Zombie TD game to
                  Third Parties.
                </li>

                <li>
                  The User is responsible for immediately notifying the Company
                  of any known or suspected unauthorized Account use.
                </li>

                <li>
                  The Company does not bear any responsibility to the User. It
                  is not obliged to compensate the User for any losses or
                  material damage resulting from using the Site and/or the
                  Company's Zombie TD game.
                </li>

                <li>
                  The Company reserves the right to change, review, update,
                  suspend, discontinue or otherwise modify the Site or the
                  Zombie TD game at any time or for any reason without notice to
                  you. The User agrees that the Company shall not be liable for
                  any losses, damage, or inconvenience caused by its inability
                  to access or use the Site and/or Zombie TD during any downtime
                  or discontinuance of the Site and Zombie TD. Nothing in these
                  Terms of Use shall be construed as a commitment to maintain
                  the Site and Zombie TD or make any patches, updates, or
                  releases in connection.
                </li>

                <li>
                  We cannot guarantee that the Site and Zombie TD will be
                  available at all times. We may experience hardware, software,
                  or other problems or maintenance needs with the Site and
                  Zombie TD that result in interruptions, delays, and errors.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                USER CONSENT
              </Typography>

              <ol>
                <li>
                  The User provides their Consent:{" "}
                  <ol>
                    <li>
                      to pay a commission for the exchange of the ZTD Token,
                      which is charged by the sites that carry out IDO in
                      automatic mode;
                    </li>
                    <li>
                      for the issue (reduction of the number) of ZTD Tokens,
                      which the Company periodically carries out;
                    </li>
                    <li>
                      to receive information through the use of the Zombie TD
                      Official Chat, the Zombie TD Official Announcements
                      channel, and the Zombie TD Twitter group;
                    </li>
                    <li>
                      to pay a commission for selling NFT on the Marketplace.
                      The commission is charged by the Company automatically;
                    </li>
                    <li>
                      to pay a commission for selling Arsenal in the Store. The
                      commission is charged by the Company automatically;
                    </li>
                    <li>with the conditions of the Terms of Use;</li>
                    <li>
                      with the conditions of Staking and Staking NFT and Mining
                      NFT.
                    </li>
                  </ol>
                </li>
                <li>
                  The User confirms that they have reached the age of eighteen
                  and have the right to own a cryptocurrency wallet and carry
                  out money exchange transactions.
                </li>
                <li>
                  If the User has not reached the age of eighteen, then they are
                  not entitled to receive the Services and/or play Zombie TD.
                </li>
                <li>
                  KISA games has the right to delete the Account and distribute
                  the ZTD Tokens of such User among other Users.
                </li>
                <li>
                  The Company has the right to obtain Consent in electronic form
                  using the Site's relevant section.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                INTELLECTUAL RIGHTS
              </Typography>

              <ol>
                <li>
                  All exclusive intellectual property rights to the Company's
                  software, including design elements, text, graphics,
                  illustrations, and other objects and components and elements,
                  belong exclusively to KISA games.
                </li>
                <li>
                  The User acknowledges and agrees that the Company owns all
                  intellectual property rights regarding the provision of the
                  Services and the gameplay in Zombie TD.
                </li>
                <li>
                  The Company grants the User a non-exclusive right to use the
                  Services and participate in Zombie TD.
                </li>
                <li>
                  The User grants the Company a non-exclusive right to use,
                  copy, process and transfer their Personal Data during the
                  period of use of the Services and participation in the
                  gameplay in Zombie TD.
                </li>
                <li>
                  The User guarantees that the Personal data and information
                  provided by them do not violate the intellectual property
                  rights of a Third Party.
                </li>
                <li>
                  Suppose the Personal Data provided by the User violates the
                  intellectual property rights of a Third Party or is
                  unreliable. In that case, the Company reserves the right to
                  stop providing the Services, delete their Account and refuse
                  the relationship between them.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                APPLICABLE RIGHT
              </Typography>
              <ol>
                <li>
                  All relationships between the Company and the User arising
                  from the fulfillment of the conditions of these Terms of Use
                  and the use of the Services are governed by the legislation of
                  the Republic of Belarus.
                </li>
                <li>
                  All disputes arising from the relationship between the Company
                  and the User will be resolved through litigation in the courts
                  of the Republic of Belarus in accordance with the procedural
                  legislation of the Republic of Belarus.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                VALIDITY
              </Typography>

              <ol>
                <li>
                  The conditions of the Terms of Use are valid for the duration
                  of the User's use of the Services and/or participation in the
                  Zombie TD game. If you terminate the use of the Services or
                  stop playing Zombie TD, the conditions of the Terms of Use
                  will cease to apply.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                ALTERATION
              </Typography>

              <ol>
                <li>
                  The Company has the right to amend these Terms of Use in case
                  of changes in terms of the Services or the gameplay in Zombie
                  TD.
                </li>
                <li>
                  The Company informs Users about changes to the Terms of Use by
                  posting news on the Site/game Zombie TD. KISA games updates
                  the date of changes to the current version of the Terms of Use
                  in the "Updated" line.
                </li>
                <li>
                  The User is obliged to familiarize themself with the new terms
                  of the Terms of Use, and the Company is not responsible if the
                  User is unfamiliar with the new terms of the Terms of Use.
                </li>
                <li>
                  Our electronic or otherwise stored copies of the Terms of Use
                  shall be deemed the present, complete, valid, and enforceable
                  versions of these Terms of Use in effect at the time you visit
                  the Zombie TD Site/game. If the User uses the Services or
                  participates in the game Zombie TD after the date of updating
                  the Terms of Use, we may assume that the User has read the new
                  version of the Terms of Use, and agrees to the terms of the
                  Services and the gameplay in Zombie TD.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                CONTACTS
              </Typography>

              <ol>
                <li>
                  The User has the right to contact the Company's support
                  service by e-mail:{" "}
                  <Link href={"mailto:info@zombietd.app"}>
                    info@zombietd.app
                  </Link>
                  , to ensure their rights in accordance with the conditions of
                  the Terms of Use and if the User has questions about the
                  provision of the Services and/or the gameplay of Zombie TD.
                </li>
              </ol>
            </li>
          </ol>
        </Container>
      </Box>
    </div>
  )
}
