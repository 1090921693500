import CopyToClipboard from "../CopyToClipboard"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { DialogActions } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import React from "react"
import QRCode from "react-qr-code"

export const DepositDialog = ({ closeDialog, width }) => {
  return (
    <Box
      sx={{
        width,
        background: "#121212",
        textAlign: "center",
      }}
    >
      <DialogTitle sx={{ fontSize: "1rem" }}>Deposit</DialogTitle>
      <DialogContent sx={{ pt: 2, pb: 0 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              p: 2,
              borderRadius: 2,
              mb: 1,
              border: "1px solid rgba(255, 255, 255, .1)",
            }}
          >
            <QRCode value="0xf4565BE4b2c4cd7b70D0C74cEaFBd28dA067EEBE" />
          </Box>
        </Box>

        <Typography mb={2} variant={"body2"}>
          Send tokens ZTD to replenish the balance to this address:
          0xf4565BE4b2c4cd7b70D0C74cEaFBd28dA067EEBE
        </Typography>
        <Typography mb={1}>
          Your ZTD address for replenishment of the balance:
          0xf4565BE4b2c4cd7b70D0C74cEaFBd28dA067EEBE
        </Typography>
        <CopyToClipboard>
          {({ copy }) => (
            <Button
              variant={"outlined"}
              size={"small"}
              fullWidth
              startIcon={<ContentCopyIcon />}
              onClick={() => copy("0xf4565BE4b2c4cd7b70D0C74cEaFBd28dA067EEBE")}
            >
              Copy
            </Button>
          )}
        </CopyToClipboard>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          color="primary"
          variant={"contained"}
          onClick={() => closeDialog()}
          fullWidth
          sx={{ fontFamily: "RUSBoycott, sans-serif" }}
        >
          Close
        </Button>
      </DialogActions>
    </Box>
  )
}
