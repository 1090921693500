import { PATHS } from "../constants/paths"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Container, Link } from "@mui/material"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import React from "react"
import { NavLink } from "react-router-dom"

export const Faqs = () => {
  return (
    <div className="content content-faqs">
      <Box className="content-head">
        <Container fixed>
          <Typography variant={"h1"} mb={4}>
            FAQs
          </Typography>
        </Container>
      </Box>
      <Box
        className="content-body"
        sx={{
          "& h5.MuiTypography-root": {
            fontSize: {
              xs: 12,
              sm: 14,
            },
            letterSpacing: ".325px",
          },
          "& p.MuiTypography-root": {
            color: "#ccc",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "16px 0 0",
          },
        }}
      >
        <Container fixed>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant={"h5"}>
                Was there already an IDO? If not, when will it be and where?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                IDO has not yet taken place. The IDO will take place on the{" "}
                <span className={"primary"}>zombietd.app</span> website and on
                the launch pool. Launchpads, date, and time of the IDO will be
                published later. Subscribe to our social networks not to miss
                the news.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant={"h5"}>
                Do I need the Whitelist for IDO?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The Whitelist is needed to secure an IDO allocation, which can
                be redeemed within 2 hours after the start of IDO. After this
                time, not redeemed tokens will be available for everyone.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant={"h5"}>
                In which blockchain network is the ZTD token?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The ZTD token is on the Binance Smart Chain BEP-20 standard.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant={"h5"}>
                How many tokens were sold to early investors/backers, and at
                what price?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography gutterBottom>
                We <span className={"primary"}>do not sell</span> tokens to
                early investors, although investors and sponsors have repeatedly
                approached us with offers to invest in our project. However, we
                are against investors because they will have a dominant part of
                the tokens. Thus, they can speculate on the market and will also
                dictate their terms for developing the project.
              </Typography>
              <Typography>
                The rejection of investors gives the project stability and
                independence, and the players are guaranteed the absence of
                speculation in the market.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant={"h5"}>
                When will the listing be and where?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The listing of the ZTD Token will be on pancakeswap.finance. The
                listing date and time will be announced later. Subscribe to our
                social networks not to miss the news.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant={"h5"}>
                Why is NFT needed in the project?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                NFTs in the project are needed to play the mobile game Zombie
                TD. Only those NFTs installed in the slots on the Site can be
                used in the game on the battlefield against zombies. All five
                types of NFTs give additional advantages over other players.
                They can diversify and improve their battle strategy as much as
                possible, increasing their chances of winning or earning more
                points, their place in the ranking, and their income in ZTD
                tokens.
              </Typography>
              <Typography>
                NFT can also be staked, which gives an additional percentage to
                the Staking of the token while leveling up and increasing the
                characteristics of the staked NFT.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant={"h5"}>
                Where, when, and how can NFT be obtained?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography mb={2}>
                You can get NFT on the website{" "}
                <span className={"primary"}>zombietd.app</span> in 3 ways:
              </Typography>
              <Stack spacing={1} sx={{ pl: 2 }}>
                <Typography>
                  1. During the Boxes with{" "}
                  <Link
                    component={NavLink}
                    to={PATHS.NFT_BOXES.NFT_BOXES_WITH_TURRETS.PATH}
                    target={"_blank"}
                  >
                    Turrets
                  </Link>{" "}
                  and{" "}
                  <Link
                    component={NavLink}
                    to={PATHS.NFT_BOXES.NFT_BOXES_WITH_COMMANDERS.PATH}
                    target={"_blank"}
                  >
                    Commanders
                  </Link>{" "}
                  sale with NFT, an NFT with a rare parity can drop out, which
                  costs more than regular NFTs and is also more significant in
                  the game compared to regular NFTs;
                </Typography>
                <Typography>
                  2. To receive NFT, you need to stake ZTD tokens on the NFT
                  Mining page. For Staking ZTD tokens, points will be awarded
                  that can be exchanged for a certain NFT of any rarity;
                </Typography>
                <Typography>
                  3. Buy NFTs of a specific type and rarity on the Marketplace
                  using ZTD tokens.
                </Typography>
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant={"h5"}>
                Is there an affiliate program?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                During the sale of NFT Boxes with Turrets and Commanders, there
                will be an Affiliate Program that allows you to earn 10% of the
                total amount of each purchase of Users who have registered using
                the affiliate link.
              </Typography>
              <Typography>
                The commission is calculated in BUSD tokens and credited to the
                balance immediately after purchasing NFT Boxes with Turrets and
                Commanders by your referrals. Earned tokens can be withdrawn on
                the “Affiliate Program” page.
              </Typography>
              <Typography>
                You can learn more about the Affiliate Program{" "}
                <Link
                  href={
                    "https://docs.zombietd.app/how-to-earn/affiliate-program"
                  }
                  target={"_blank"}
                >
                  on this page
                </Link>
                .
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Container>
      </Box>
    </div>
  )
}
