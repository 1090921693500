import { useEffect, useRef, useState } from "react"

const NftVideo = ({ classes, videoUrl, poster, videoTrigger }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const videoRef = useRef(null)

  const playVideo = (video) => {
    if (video.readyState !== 4) {
      video.load()
    } else {
      video.play().then(setIsPlaying(true))
    }
  }

  const stopVideo = (video) => {
    if (isPlaying) {
      video.pause()
      video.currentTime = 0
      setIsPlaying(false)
    } else {
      console.log("Video is not ready!")
    }
  }

  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoTrigger && !isPlaying
        ? playVideo(videoRef.current)
        : stopVideo(videoRef.current)
    }
  }, [videoTrigger])

  return (
    <video
      ref={videoRef}
      width="auto"
      height="auto"
      src={videoUrl}
      muted
      preload="auto"
      poster={poster}
      loop
      className={`videoContainer img-dynamic_small img-dynamic ${classes}`}
    ></video>
  )
}

export default NftVideo
