// Paths
// Private
import { Faqs } from "../pages/FaqsPage"
// Public
import { Home } from "../pages/HomePage"
import { IDO } from "../pages/IdoPage"
import { Marketplace } from "../pages/MarketplacePage"
import { Mining } from "../pages/MiningPage"
import { NftBoxesCommanders } from "../pages/NftBoxesCommandersPage"
import { NftBoxesTurrets } from "../pages/NftBoxesTurretsPage"
import { PrivacyPolicy } from "../pages/PrivacyPolicyPage"
import { StakingNftPage } from "../pages/StakingNftPage"
import { Stacking } from "../pages/StakingPage"
import { TermsOfUse } from "../pages/TermsOfUsePage"
import { DashboardPage } from "../pages/account/DashboardPage"
import { TransactionsPage } from "../pages/account/TransactionsPage"
import { PATHS } from "./paths"

export const appRoutes = [
  {
    path: PATHS.HOME.PATH,
    label: PATHS.HOME.LABEL,
    type: PATHS.HOME.TYPE,
    Element: Home,
    isActive: true,
  },
  {
    path: PATHS.IDO.PATH,
    label: PATHS.IDO.LABEL,
    type: PATHS.IDO.TYPE,
    Element: IDO,
    isActive: true,
  },
  {
    path: PATHS.MARKET.PATH,
    label: PATHS.MARKET.LABEL,
    type: PATHS.MARKET.TYPE,
    Element: Marketplace,
    isActive: false,
  },
  {
    path: PATHS.STAKING.PATH,
    label: PATHS.STAKING.LABEL,
    type: PATHS.STAKING.TYPE,
    Element: Stacking,
    isActive: false,
  },
  {
    path: PATHS.STAKING_NFT.PATH,
    label: PATHS.STAKING_NFT.LABEL,
    type: PATHS.STAKING_NFT.TYPE,
    Element: StakingNftPage,
    isActive: false,
  },
  {
    path: PATHS.NFT_BOXES.NFT_BOXES_WITH_TURRETS.PATH,
    label: PATHS.NFT_BOXES.NFT_BOXES_WITH_TURRETS.LABEL,
    type: PATHS.NFT_BOXES.TYPE,
    Element: NftBoxesTurrets,
    isActive: false,
  },
  {
    path: PATHS.NFT_BOXES.NFT_BOXES_WITH_COMMANDERS.PATH,
    label: PATHS.NFT_BOXES.NFT_BOXES_WITH_COMMANDERS.LABEL,
    type: PATHS.NFT_BOXES.TYPE,

    Element: NftBoxesCommanders,
    isActive: false,
  },
  {
    path: PATHS.MINING.PATH,
    label: PATHS.MINING.LABEL,
    type: PATHS.MINING.TYPE,
    Element: Mining,
    isActive: false,
  },
  {
    path: PATHS.NFT_BOXES.NFT_BOXES_WITH_TURRETS.PATH,
    label: PATHS.NFT_BOXES.NFT_BOXES_WITH_TURRETS.LABEL,
    type: PATHS.NFT_BOXES.TYPE,
    Element: NftBoxesTurrets,
    isActive: false,
  },
  {
    path: PATHS.NFT_BOXES.NFT_BOXES_WITH_COMMANDERS.PATH,
    label: PATHS.NFT_BOXES.NFT_BOXES_WITH_COMMANDERS.LABEL,
    type: PATHS.NFT_BOXES.TYPE,

    Element: NftBoxesCommanders,
    isActive: false,
  },

  {
    path: PATHS.FAQS.PATH,
    label: PATHS.FAQS.LABEL,
    type: PATHS.FAQS.TYPE,
    Element: Faqs,
    isActive: false,
  },
  {
    path: PATHS.ACCOUNT.PATH,
    label: PATHS.ACCOUNT.LABEL,
    type: PATHS.ACCOUNT.TYPE,
    Element: DashboardPage,
    isActive: true,
  },
  {
    path: PATHS.TRANSACTIONS.PATH,
    label: PATHS.TRANSACTIONS.LABEL,
    type: PATHS.TRANSACTIONS.TYPE,
    Element: TransactionsPage,
    isActive: true,
  },
  {
    path: PATHS.TERMS_OF_USE.PATH,
    label: PATHS.TERMS_OF_USE.LABEL,
    type: PATHS.TERMS_OF_USE.TYPE,

    Element: TermsOfUse,
    isActive: false,
  },
  {
    path: PATHS.PRIVACY_POLICY.PATH,
    label: PATHS.PRIVACY_POLICY.LABEL,
    type: PATHS.PRIVACY_POLICY.TYPE,
    Element: PrivacyPolicy,
    isActive: false,
  },
]
