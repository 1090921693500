import CtaButton from "../button/CtaButton"
import { Link, Menu } from "@mui/material"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import { styled } from "@mui/material/styles"
import React, { useState } from "react"
import { NavLink } from "react-router-dom"

const BootstrapMenu = styled(Menu)(({ theme }) => ({
  ".MuiPaper-root": {
    backdropFilter: "blur(5px)",
    background: " rgba(0, 0, 0, 0.25)",
    transition: "all 0.2s",
  },
}))

export const MenuList = ({ name, type, items, isExternal }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {type === "button" ? (
        <CtaButton
          variant={type === "button" ? "contained" : "text"}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {name}
        </CtaButton>
      ) : (
        <Button
          variant={type === "button" ? "contained" : "text"}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="nav-link"
        >
          {name}
        </Button>
      )}

      <BootstrapMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        disableScrollLock
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {items.map((item, index) => {
          return (
            <MenuItem onClick={handleClose} key={index}>
              {!isExternal ? (
                <NavLink
                  style={{ display: "flex" }}
                  className="nav-link"
                  to={item.link}
                >
                  {item.label}
                </NavLink>
              ) : (
                <Link
                  href={item.link}
                  target={"_blank"}
                  sx={{ color: "#EEE", textDecoration: "none" }}
                >
                  {item.label}
                </Link>
              )}
            </MenuItem>
          )
        })}
      </BootstrapMenu>
    </>
  )
}
