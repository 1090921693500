import newItem from "../../assets/img/staking_items-new.png"
import { SellDialog } from "../../components/dialog/SellDialog"
import { useDialog } from "../../context/DialogContext"
import { useAppSelector } from "../../store"
import { CommanderComponent } from "../NftBoxesCommandersPage"
import {
  Card,
  CardActionArea,
  DialogContentText,
  Grid,
  Stack,
} from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import { useSnackbar } from "notistack"
import React, { useRef, useState } from "react"
import PreviewNFTCard from "../../components/card/PreviewNFTCard"
import { PreviewNFTDialog } from "../../components/dialog/PreviewNFTDialog"

export const DashboardPage = () => {
  const commanders = useAppSelector((state) => state.market.commanders)

  const inventoryRef = useRef(null)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [gunOfGame, setGunOfGame] = useState([])

  const [open, setOpen] = useState(false)

  const [isAddedToGame, setAddedToGameOpen] = useState(false)

  const scrollToInventory = () => {
    inventoryRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const onAddNftToGame = () => {
    setAddedToGameOpen(true)
    enqueueSnackbar("The NFT has been added successfully.", {
      variant: "success",
    })
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const items = useAppSelector((state) => state.market.turrets)
  const [marketItems, setMarketItems] = useState(items)

  const [currentCommander, setCurrentCommander] = useState(null)

  const [openSellDialog, closeSellDialog] = useDialog()

  const onSellDepositDialog = (e) => {
    openSellDialog({
      children: <SellDialog closeDialog={closeSellDialog} width={420} />,
    })
  }

  const [openPreviewNFTDialog, closePreviewNFTDialog] = useDialog()

  const onOpenPreviewNFTDialog = (item, onSellDepositDialog) => {
    openPreviewNFTDialog({
      children: (
        <PreviewNFTDialog
          item={item}
          closeDialog={closePreviewNFTDialog}
          maxWidth={700}
          onSellDepositDialog={onSellDepositDialog}
          onAddNftToGame={onAddNftToGame}
        />
      ),
    })
  }

  return (
    <>
      <Box mt={4} id="gun-of-the-game">
        <Typography variant="h3">Commander In the Game</Typography>

        <Grid container spacing={2} mt={1} columns={20}>
          <Grid item sm={10} md={6} lg={4}>
            {currentCommander ? (
              <>
                {commanders.map((commander) => {
                  return (
                    <>
                      {commander.title === currentCommander && (
                        <>
                          <CommanderComponent
                            image={commander.image}
                            video={commander.video}
                            lvl={commander.level}
                            rarity="Common"
                            bonus="30%"
                            progressValue={"85"}
                            progressLabel={"15h. 30m."}
                          />
                          <Button
                            variant={"outlined"}
                            fullWidth
                            sx={{ mt: 1 }}
                            onClick={handleClickOpen}
                          >
                            Change
                          </Button>
                        </>
                      )}
                    </>
                  )
                })}
              </>
            ) : (
              <Box display={"flex"}>
                <Box>
                  <CardActionArea
                    sx={{
                      display: "block",
                    }}
                    onClick={scrollToInventory}
                  >
                    <img
                      src={newItem}
                      alt="Add new"
                      style={{ width: "100%" }}
                    />
                  </CardActionArea>
                </Box>
              </Box>
            )}
          </Grid>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" textAlign={"center"}>
              Upgrade
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                textAlign={"center"}
                sx={{ mb: 2 }}
              >
                Upgrade
              </DialogContentText>
              <Box>
                <Grid container spacing={3}>
                  {commanders.map((commander) => {
                    return (
                      <>
                        {commander.id > 1 && (
                          <Grid item sm={10} md={6} lg={4}>
                            <Box
                              sx={{
                                filter: "grayscale(1)",
                                "&:hover": {
                                  filter: "grayscale(0)",
                                },
                              }}
                            >
                              <CommanderComponent
                                image={commander.image}
                                video={commander.video}
                              />
                              <Button
                                variant={"outlined"}
                                fullWidth
                                sx={{ mt: 1 }}
                                onClick={() =>
                                  setCurrentCommander(commander.title)
                                }
                              >
                                Upgrade
                              </Button>
                            </Box>
                          </Grid>
                        )}
                      </>
                    )
                  })}
                </Grid>
              </Box>
            </DialogContent>
          </Dialog>
        </Grid>
      </Box>

      <Box mt={4}>
        <Typography variant="h3">Turrets in the game</Typography>

        <Grid container spacing={2} mt={1} columns={20}>
          {/*{entries.turrets.map((turret) => {*/}
          {/*  return (*/}
          {/*    <>*/}
          {/*      {turret.id === 1 &&*/}
          {/*        <Grid item sm={10} md={6} lg={4}>*/}
          {/*          <TurretComponent*/}
          {/*            image={turret.image}*/}
          {/*            video={turret.video}*/}
          {/*          />*/}

          {/*        </Grid>*/}
          {/*      }*/}
          {/*    </>*/}
          {/*  )*/}
          {/*})}*/}
          {[1, 2, 3, 4, 5].map((item) => (
            <Grid item sm={10} md={6} lg={4} display="flex">
              <Box>
                <CardActionArea
                  sx={{
                    display: "block",
                  }}
                  onClick={scrollToInventory}
                >
                  <img src={newItem} alt="Add new" style={{ width: "100%" }} />
                </CardActionArea>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/*<Box mt={4}>*/}
      {/*  <Typography variant='h3'>*/}
      {/*    Boxes*/}
      {/*  </Typography>*/}

      {/*  <Grid container spacing={2} mt={1} columns={20}>*/}
      {/*    {[1, 2, 3, 4, 5].map(() => (*/}
      {/*      <Grid item sm={10} md={6} lg={4}>*/}
      {/*        <Tooltip title='Coming Soon...'>*/}
      {/*          <span>*/}
      {/*            <CardActionArea disabled sx={{*/}
      {/*              borderRadius: '8px',*/}
      {/*              background: 'rgba(255, 255, 255, .025)',*/}
      {/*              border: '1px solid #a16929'*/}
      {/*            }}>*/}
      {/*          <Box p={2}>*/}
      {/*            <NftBoxAccount*/}
      {/*              title='Machine gunner Box'*/}
      {/*              img={machineCommanderBoxImg}*/}
      {/*            />*/}
      {/*          </Box>*/}
      {/*        </CardActionArea>*/}
      {/*          </span>*/}
      {/*        </Tooltip>*/}
      {/*      </Grid>*/}
      {/*    ))}*/}
      {/*  </Grid>*/}
      {/*</Box>*/}

      <Box mt={4} ref={inventoryRef} id="inventory">
        <Typography variant="h3">My Inventory</Typography>

        <Grid container spacing={2} mt={1} columns={20}>
          {commanders.map((commander) => {
            return (
              <>
                {commander.id === 1 && (
                  <Grid item sm={10} md={6} lg={4}>
                    <Box>
                      <Typography
                        variant={"h5"}
                        sx={{
                          mb: 2,
                          fontSize: {
                            xs: 12,
                            md: 14,
                          },
                        }}
                        textAlign={"center"}
                      >
                        {commander.name}
                      </Typography>

                      <Card>
                        <CardActionArea
                          onClick={() =>
                            onOpenPreviewNFTDialog(
                              commander,
                              onSellDepositDialog,
                            )
                          }
                        >
                          <PreviewNFTCard
                            id={commander.id}
                            imageUrl={commander.image}
                            videoUrl={commander.video}
                            lvl={commander.level}
                            rarity={commander.rarity}
                            type={commander.type}
                            commander={commander}
                          />
                        </CardActionArea>
                      </Card>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <Stack spacing={1} sx={{ width: "100%" }}>
                          <Button
                            variant={"contained"}
                            size={"small"}
                            onClick={onAddNftToGame}
                            disabled={isAddedToGame}
                            fullWidth
                          >
                            {!isAddedToGame ? "Add to game" : "Added to game"}
                          </Button>
                          <Button
                            variant={"outlined"}
                            size={"small"}
                            fullWidth
                            onClick={onSellDepositDialog}
                          >
                            Sell on the market
                          </Button>
                        </Stack>
                      </Box>
                    </Box>
                  </Grid>
                )}
              </>
            )
          })}
          {/*{marketItems.map((item) => (*/}
          {/*  <Grid item xs={12} sm={10} md={6} lg={4}>*/}
          {/*    <NftCard type={"inventory"} key={item.id} item={item} />*/}
          {/*  </Grid>*/}
          {/*))}*/}
        </Grid>
      </Box>
    </>
  )
}
