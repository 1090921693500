import $api from "../../api"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const getAllItems = createAsyncThunk(
  "market/fetchAll",
  async (page, { rejectWithValue }, direction) => {
    function SelectPage(page) {
      return {
        sorting: [
          {
            propertyName: "rarity",
            direction: direction.rarity,
            sortOrder: 0,
          },
          {
            propertyName: "level",
            direction: direction.lvl,
            sortOrder: 1,
          },
          {
            propertyName: "price",
            direction: direction.price,
            sortOrder: 2,
          },
        ],
        skip: page === 1 ? 0 : page * 9,
        take: 9,
      }
    }

    try {
      const response = await $api
        .post("/Towers/on-sale", SelectPage(page))
        .then((res) => res.data.data)
      // @ts-ignore
      return response
    } catch (err) {
      return rejectWithValue("Request NFT error")
    }
  },
)
