import React from "react"

export const Card = ({ title, subTitle, image, className }) => {
  return (
    <div className={`${className} item`}>
      {image ? (
        <div className="media" style={{ fontSize: "100px", lineHeight: 1 }}>
          <svg width="0" height="0">
            <linearGradient
              id="primary-gradient"
              x1="100%"
              y1="100%"
              x2="0%"
              y2="0%"
            >
              <stop stopColor="#e8c582" offset="0%" />
              <stop stopColor="#a16929" offset="100%" />
            </linearGradient>
          </svg>
          {image}
        </div>
      ) : null}
      <h4>{title}</h4>
      <p>{subTitle}</p>
    </div>
  )
}
