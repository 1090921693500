import { useDialog } from "../../context/DialogContext"
import { capitalizeFirstLetter } from "../../utils/utlis"
import NftVideo from "../NftVideo"
import { BuyNFTDialog } from "../dialog/BuyNFTDialog"
import { Tooltip } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Chip from "@mui/material/Chip"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import React, { useState } from "react"

const NftCard = ({ item, type }) => {
  const [videoTrigger, setVideoTrigger] = useState(false)

  const [openBuyNFTDialog, closeBuyNFTDialog] = useDialog()

  const onOpenBuyNFTDialog = (item) => {
    openBuyNFTDialog({
      children: (
        <BuyNFTDialog
          item={item}
          closeDialog={closeBuyNFTDialog}
          maxWidth={700}
        />
      ),
    })
  }

  return (
    <>
      <Box
        className="card img_toggler"
        onMouseEnter={() => setVideoTrigger(true)}
        onMouseLeave={() => setVideoTrigger(false)}
      >
        <img className="img-static" src={item.image} alt="turret" />

        <NftVideo
          videoUrl={item.videoUrl}
          poster={item.poster}
          videoTrigger={videoTrigger}
        />

        <div className="meta">
          <div className="meta-content">
            {type !== "default" && (
              <div className="meta-head">
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                >
                  <Typography
                    variant={"h5"}
                    textAlign="center"
                    sx={{
                      fontSize: {
                        xs: "12px",
                        lg: ".825rem",
                      },
                      letterSpacing: "1px",
                      px: 1,
                    }}
                  >
                    {item.name}
                  </Typography>
                </Stack>
              </div>
            )}

            <div className="meta-foot">
              {type !== "default" && (
                <>
                  <Stack
                    direction="row"
                    spacing={1}
                    position="relative"
                    flexWrap="wrap"
                  >
                    <Tooltip title="NFT ID">
                      <Chip
                        label={`ID #109${item.id}`}
                        variant="filled"
                        size="small"
                        className="chip"
                      />
                    </Tooltip>
                    <Tooltip title="Rarity">
                      <Chip
                        label={capitalizeFirstLetter(item.rarity)}
                        variant="filled"
                        size="small"
                        className={`chip chip-${item.rarity.toLowerCase()}`}
                      />
                    </Tooltip>
                    <Tooltip title="Lvl">
                      <Chip
                        label={`Lvl: ${item.level}`}
                        variant="filled"
                        size="small"
                        className="chip"
                      />
                    </Tooltip>

                    {type === "status" && !item.minted && (
                      <Chip
                        className="chip"
                        label={`Minted: ${item.minted}`}
                        variant="filled"
                        size="small"
                      />
                    )}

                    {type === "status" && (
                      <Chip
                        className="chip"
                        label={`Left: ${item.left}`}
                        variant="filled"
                        size="small"
                      />
                    )}
                  </Stack>
                </>
              )}
            </div>
          </div>
        </div>
      </Box>

      {type !== "inventory" && (
        <Box textAlign="center" mt={1}>
          <Button
            fullWidth
            variant="outlined"
            sx={{
              height: "38px",
              fontSize: ".625rem",
              fontFamily: '"Foul Fiend", sans-serif',
            }}
            onClick={() => onOpenBuyNFTDialog(item)}
          >
            Buy
          </Button>
        </Box>
      )}
    </>
  )
}

export default NftCard
