import dividerPrimaryGradientUrl from "../assets/img/border/divider-primary-gradient-h.svg"
import { MarketplaceFilter } from "../components/MarketplaceFilter"
import NftCard from "../components/card/NftCard"
import { StakeDialog } from "../components/dialog/StakeDialog"
import { useDialog } from "../context/DialogContext"
import { useAppSelector } from "../store"
import { Chip, Container, Drawer, Grid } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import useMediaQuery from "@mui/material/useMediaQuery"
import React, { useEffect, useState } from "react"

export const Mining = () => {
  const turrets = useAppSelector((state) => state.market.turrets)
  const [marketItems, setMarketItems] = useState(turrets)
  const [filters, setFilters] = useState({
    rarity: [],
    type: [],
  })

  const valueInArr = (value, type) =>
    Object.values(value).some((elem) =>
      filters[type].includes(elem.toLowerCase()),
    )

  useEffect(() => {
    if (filters.type.length && filters.rarity.length) {
      return setMarketItems(
        marketItems.filter(
          (value) => valueInArr(value, "rarity") && valueInArr(value, "type"),
        ),
      )
    }
    if (filters.type.length) {
      return setMarketItems(
        marketItems.filter((value) => valueInArr(value, "type")),
      )
    }
    if (filters.rarity.length) {
      return setMarketItems(
        marketItems.filter((value) => valueInArr(value, "rarity")),
      )
    }
    if (!filters.type.length && !filters.rarity.length) {
      setMarketItems(marketItems)
    }
    // eslint-disable-next-line
  }, [marketItems, filters])

  const isDownSm = useMediaQuery("(max-width: 600px)")
  const isDownMd = useMediaQuery("(max-width: 900px)")

  const [openDrawer, setOpenDrawer] = useState(false)

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setOpenDrawer(!openDrawer)
  }

  const [openDialog, closeDialog] = useDialog()

  const onOpenStakeDialog = () => {
    openDialog({
      children: <StakeDialog closeDialog={closeDialog} width={400} />,
    })
  }

  return (
    <Box className="content">
      <Box className="content-head" mb={4}>
        <Container fixed>
          <Typography variant={"h1"} mb={2}>
            MINING NFT
          </Typography>
          <Typography
            variant="subtitle1"
            my={2}
            pl={2}
            sx={{
              borderLeft: "1px solid transparent",
              borderImage: `url(${dividerPrimaryGradientUrl}) 1 stretch`,
            }}
          >
            To mine NFT, you need to stake ZTD tokens on the Mining page. For 1
            ZTD token per day, 1 point is awarded, a maximum of 1,000,000 ZTD
            tokens can be staked. After you have the required number of points,
            you can exchange them for a certain NFT of any rarity. The number of
            NFTs for Mining is limited to 220 pieces.
          </Typography>
        </Container>
      </Box>

      <Box className="content-body">
        <Container fixed>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item>
                  <Chip
                    color={"primary"}
                    variant={"outlined"}
                    label={"Staked: 0 ZTD"}
                    sx={{ height: 40, fontSize: 16 }}
                  />
                </Grid>
                <Grid item>
                  <Chip
                    color={"primary"}
                    variant={"outlined"}
                    label={"Points: 0"}
                    sx={{ height: 40, fontSize: 16 }}
                  />
                </Grid>
                <Grid item>
                  <Chip
                    color={"primary"}
                    variant={"outlined"}
                    label={"Total Staked: 0 ZTD"}
                    sx={{ height: 40, fontSize: 16 }}
                  />
                </Grid>
                <Grid item>
                  <Button variant={"contained"} onClick={onOpenStakeDialog}>
                    Stake
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container rowSpacing={5} columnSpacing={3}>
                {marketItems.length ? (
                  marketItems.map((item) => (
                    <Grid item xs={12} sm={6} lg={3}>
                      <NftCard type={"status"} key={item.id} item={item} />
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Typography textAlign={"center"}>Not found...</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Drawer anchor="left" open={openDrawer} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 260, px: 2 }}>
          <MarketplaceFilter />
        </Box>
      </Drawer>
    </Box>
  )
}
