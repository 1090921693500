import { PATHS } from "../constants/paths"
import MenuIcon from "@mui/icons-material/Menu"
import { Drawer, Link, ListItem } from "@mui/material"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import React, { useState } from "react"
import { NavLink } from "react-router-dom"

const DrawerMenu = () => {
  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IconButton aria-label="menu" onClick={handleDrawerOpen}>
        <MenuIcon fontSize="inherit" />
      </IconButton>
      <Drawer open={open} onClose={handleDrawerClose} anchor="right">
        <Box sx={{ width: 250, overflowY: "auto" }} role="presentation">
          <List>
            {Object.entries(PATHS).map(([key, value]) => (
              <React.Fragment key={value.ID}>
                {value.TYPE === "public" && value.SUB_TYPE === "main" && (
                  <>
                    {key !== "NFT_BOXES" && key !== "WHITE_PAPER" ? (
                      <>
                        <ListItem key={value.ID} disablePadding>
                          <ListItemButton
                            component={NavLink}
                            to={value.PATH}
                            className="nav-link"
                            onClick={() => handleDrawerClose()}
                            disabled={value.DISABLED}
                          >
                            <ListItemText primary={value.LABEL} />
                          </ListItemButton>
                        </ListItem>
                      </>
                    ) : (
                      <>
                        {key === "NFT_BOXES" ? (
                          <>
                            <ListItemButton
                              component={NavLink}
                              to={value.NFT_BOXES_WITH_TURRETS.PATH}
                              onClick={() => handleDrawerClose()}
                            >
                              <ListItemText
                                primary={value.NFT_BOXES_WITH_TURRETS.LABEL}
                              />
                            </ListItemButton>
                            <ListItemButton
                              component={NavLink}
                              to={value.NFT_BOXES_WITH_COMMANDERS.PATH}
                              onClick={() => handleDrawerClose()}
                            >
                              <ListItemText
                                primary={value.NFT_BOXES_WITH_COMMANDERS.LABEL}
                              />
                            </ListItemButton>
                          </>
                        ) : (
                          <>
                            <ListItemButton
                              component={Link}
                              href={value.WHITE_PAPER.PATH}
                              target={"_blank"}
                              onClick={() => handleDrawerClose()}
                            >
                              <ListItemText primary={value.WHITE_PAPER.LABEL} />
                            </ListItemButton>
                            <ListItemButton
                              component={Link}
                              href={value.LITE_PAPER.PATH}
                              target={"_blank"}
                              onClick={() => handleDrawerClose()}
                            >
                              <ListItemText primary={value.LITE_PAPER.LABEL} />
                            </ListItemButton>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                {value.SUB_TYPE === "additional" && (
                  <>
                    <Divider />
                    <ListItem key={value.ID} disablePadding>
                      <ListItemButton
                        component={NavLink}
                        to={value.PATH}
                        className="nav-link"
                        onClick={() => handleDrawerClose()}
                      >
                        <ListItemText primary={value.LABEL} />
                      </ListItemButton>
                    </ListItem>
                  </>
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  )
}

export default DrawerMenu
