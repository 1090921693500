import NftVideo from "../NftVideo"
import Box from "@mui/material/Box"
import React, { useState } from "react"
import { Grid, Stack, Tooltip } from "@mui/material"
import Chip from "@mui/material/Chip"
import { capitalize } from "lodash"
import { styled } from "@mui/material/styles"
import { LinearProgressStyled } from "../BorderLinearProgress"

const PreviewNFTCard = ({
  imageUrl,
  videoUrl,
  id,
  lvl,
  rarity,
  type,
  bonus,
  progressLabel,
  progressValue,
}) => {
  const [videoTrigger, setVideoTrigger] = useState(false)

  return (
    <Box
      className="card img_toggler"
      onMouseEnter={() => setVideoTrigger(true)}
      onMouseLeave={() => setVideoTrigger(false)}
      sx={{
        position: "relative",
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        flexWrap="wrap"
        sx={{ position: "absolute", zIndex: 1, top: 16, left: 16, right: 16 }}
      >
        {id && <Property title="NFT ID" label={`ID #109${capitalize(id)}`} />}
      </Stack>

      <Box sx={{ margin: "auto" }}>
        <img className="img-static" src={imageUrl} alt="turret" />

        <NftVideo
          videoUrl={videoUrl}
          poster={imageUrl}
          videoTrigger={videoTrigger}
        />
      </Box>

      <Box
        sx={{
          position: "absolute",
          zIndex: 1,
          bottom: 16,
          left: 16,
          right: 16,
        }}
      >
        <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
          {rarity && <Property title="Rarity" label={capitalize(rarity)} />}

          {lvl && <Property title="Level" label={`Lvl: ${capitalize(lvl)}`} />}

          {bonus && (
            <Property title="Bonus" label={`Bonus: ${capitalize(bonus)}%`} />
          )}

          {type && <Property title="Type" label={capitalize(type)} />}
        </Stack>

        {progressValue && progressLabel && (
          <Grid item xs={12} sx={{ mt: 1 }}>
            <LinearProgressStyled
              value={progressValue}
              label={progressLabel}
              rarity={rarity}
            />
          </Grid>
        )}
      </Box>
    </Box>
  )
}

const Property = ({ title, label }) => {
  const StyledChip = styled(Chip)(({ theme }) => ({
    padding: "2px 0",
    height: "auto",
    background: "rgba(0, 0, 0, .35)",
    "& > span": {
      fontSize: "16px",
    },
  }))

  return (
    <Tooltip title={title} placement="top">
      <div>
        <StyledChip size="small" label={label} />
      </div>
    </Tooltip>
  )
}

export default PreviewNFTCard
