import { Logo } from "../Logo"
import { MainNav } from "../MainNav"
import { Container } from "@mui/material"
import Box from "@mui/material/Box"
import useMediaQuery from "@mui/material/useMediaQuery"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

export const Header = () => {
  const path = useLocation().pathname

  const [stickyClass, setStickyClass] = useState("relative")

  const [isAccountView, setAccountView] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", stickyNavbar)
    return () => {
      window.removeEventListener("scroll", stickyNavbar)
    }
  }, [])

  const stickyNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY
      windowHeight > 640
        ? setStickyClass("sticky")
        : setStickyClass("non-sticky")
    }
  }

  const isDownMd = useMediaQuery("(max-width: 900px)")

  useEffect(() => {
    if (path.includes("/account/")) {
      setAccountView(true)
    } else {
      setAccountView(false)
    }
  }, [path])

  return (
    <header className={`header ${stickyClass}`}>
      {!isAccountView ? (
        <Container fixed={isDownMd && false}>
          <div className="header-row">
            <Logo />
            <MainNav />
          </div>
        </Container>
      ) : (
        <Box
          sx={{
            padding: "0 24px",
            maxWidth: "1310px",
            margin: "auto",
          }}
        >
          <MainNav />
        </Box>
      )}
    </header>
  )
}
