import { Container, Link } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import React from "react"

export const PrivacyPolicy = () => {
  return (
    <div className="content">
      <Box className="content-head">
        <Container fixed>
          <Typography variant={"h1"}>Privacy Policy</Typography>
        </Container>
      </Box>
      <Box className="content-body">
        <Container>
          <Typography>Publication date: November 1, 2022</Typography>
          <Typography>
            The Privacy Policy regulates the processing of the User's Personal
            Data by the KISA games Company.
          </Typography>
          <Typography>
            The provisions of this Privacy Policy describe how KISA games
            processes, stores, and receives Personal Data.
          </Typography>
          <Typography>
            This Privacy Policy describes the rules for processing the User's
            Personal Data while participating in the Zombie TD game.
          </Typography>
          <Typography>
            This Privacy Policy is intended for Users from jurisdictions around
            the world.
          </Typography>
          <Typography>
            The Privacy Policy identifies the Third Parties that may be provided
            with your Personal Data during the provision of the Services and/or
            participation in the game Zombie TD.
          </Typography>
          <Typography>
            Reference to the words "we," "our," or "us" (or equivalent terms)
            means the KISA games Company.
          </Typography>
          <Typography>
            Reference to the words "you" or "your" (or similar words in meaning)
            means the User who receives the Services and/or plays Zombie TD.
          </Typography>
          <Typography>We hope you enjoy playing Zombie TD!</Typography>
          <ol>
            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                DEFINITIONS
              </Typography>

              <ol>
                <li>
                  <b>Account</b> - is a functional part of Zombie TD, with the
                  help of which the User gets the opportunity to receive
                  Services, carry out transactions with the ZTD Token, and
                  purchase/sell NFT.
                </li>
                <li>
                  <b>Cookie</b> - is a piece of information in the form of text
                  or binary data transmitted to the browser from a website.
                </li>
                <li>
                  <b>Controller</b> - any natural or legal person, public
                  authority, institution, or other body that independently
                  determines the purposes and means of processing Personal Data.
                </li>
                <li>
                  <b>
                    Consent of the User (hereinafter referred to as "Consent")
                  </b>{" "}
                  - is a voluntary, specific, informed, and unambiguous
                  expression of will in which the User, using a statement or a
                  clear affirmative action, consents to the processing of their
                  Personal Data.
                </li>
                <li>
                  <b>Checkbox</b> - an element of the Site's graphical interface
                  which allows the User to provide their Consent. For example:
                  when registering in the Zombie TD game, agreeing to the
                  Privacy Policy, and the like.
                </li>
                <li>
                  <b>
                    KISA games Company (hereinafter referred to as the “Company”
                    or “KISA games”)
                  </b>{" "}
                  - is a legal entity that provides the User with the Services
                  and the opportunity to play Zombie TD.
                </li>
                <li>
                  <b>Processor</b> - is a natural or legal person, public
                  authority, institution, or other body that processes Personal
                  Data on behalf of the Controller.
                </li>
                <li>
                  <b>Services</b> - are a sequence of actions that the Company
                  performs for the User to support the gameplay in Zombie TD and
                  make transactions with ZTD Tokens: acquisition, Staking, and
                  Mining of NFT.
                </li>

                <li>
                  <b>Third Party</b> - is a natural or legal person, public
                  authority, institution, or body other than the User, the
                  Controller, the Processor, and persons authorized by the
                  Controller or the Processor, under their direct authority, to
                  process Personal Data.
                </li>

                <li>
                  <b>User</b> - is an individual who plays Zombie TD, receives
                  Services from the Company, and provides their Personal Data.
                </li>

                <li>
                  Zombie TD Site (hereinafter referred to as the "Site") - is a
                  web page or a group of web pages on the Internet located at:{" "}
                  <Link href={"https://zombietd.app"} target={"_blank"}>
                    https://zombietd.app
                  </Link>
                  , which provides information about the Zombie TD game and the
                  Services provided by the Company.
                </li>

                <li>
                  <b>ZTD Token</b> - is a currency in the game Zombie TD, which
                  the User earns during the game or in other ways provided by
                  the Company, and can be converted into other cryptocurrencies
                  on the PanckakeSwap decentralized exchange.
                </li>

                <li>
                  <b>Zombie TD</b> - is a mobile app game set in a
                  post-apocalyptic genre with addictive gameplay and the
                  possibility of earning income in ZTD Tokens.
                </li>

                <li>
                  <b>Zombie TD Official Chat</b> - is the Official channel of
                  the Zombie TD game in the Telegram messenger, in which Users
                  can communicate. The channel is located at:{" "}
                  <Link href={"https://t.me/zombie_td_chat"} target={"_blank"}>
                    https://t.me/zombie_td_chat
                  </Link>{" "}
                </li>

                <li>
                  <b>Zombie TD Official Announcements</b> - is the Official
                  channel of the Zombie TD game in the Telegram messenger, in
                  which KISA games publishes announcements about the Events in
                  the Zombie TD game. The chat is hosted at:{" "}
                  <Link href={"https://t.me/zombie_td_news"}>
                    https://t.me/zombie_td_news
                  </Link>
                </li>

                <li>
                  <b>
                    Zombie TD Twitter @zombie_td (hereinafter referred to as
                    “Zombie TD Twitter”)
                  </b>{" "}
                  - is the Official Zombie TD Twitter group where KISA games
                  posts announcements about Zombie TD Events. The group is
                  hosted at:{" "}
                  <Link
                    href={"https://twitter.com/zombie_td"}
                    target={"_blank"}
                  >
                    https://twitter.com/zombie_td
                  </Link>
                </li>

                <li>
                  <b>Zombie TD NFT (hereinafter referred to as “NFT”)</b> - is a
                  non-fungible token in the form of a digital product in the
                  Zombie TD game, which can be used by the User for gameplay or
                  to receive Services from the Company.
                </li>
              </ol>
            </li>
            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                GENERAL
              </Typography>
              <ol>
                <li>
                  The Company provides Services to Users from different regions
                  of the world. Therefore the Company uses the laws of various
                  jurisdictions in its activities.
                </li>
                <li>
                  The Company processes and stores Personal Data based on the
                  following legislative acts:
                  <ol>
                    <li>
                      Principles of legality, fairness, and transparency in
                      accordance with the General Data Protection Regulation
                      (GDPR);
                    </li>
                    <li>
                      Granting rights to the User under the California Consumer
                      Privacy Act;
                    </li>
                    <li>
                      Grant of rights and processing of Personal Data in
                      accordance with the Personal Information Protection and
                      Electronic Documents Act (PIPEDA);
                    </li>
                    <li>
                      Processing of Personal Data and privacy under the
                      Australian Privacy Act 1988;
                    </li>
                    <li>
                      Processing of Personal Data of South Korean residents in
                      accordance with South Korea’s Personal Information
                      Protection Act (PIPA);
                    </li>
                    <li>
                      Processing of Personal Data of Japanese residents based on
                      the Act on the Protection of Personal Information (Act No.
                      57 of 2003 as amended in 2015) ('APPI');
                    </li>
                    <li>
                      Processing of Personal Data of residents of the People's
                      Republic of China based on the Personal Information
                      Protection Law (PIPL);
                    </li>
                    <li>
                      Processing of Personal Data of residents of the Federative
                      Republic of Brazil based on General Personal Data
                      Protection Act (LGPD);
                    </li>
                    <li>
                      Processing of the User's Personal Data on the basis of the
                      Law of the Republic of Belarus “On the Protection of
                      Personal Data”
                    </li>
                  </ol>
                </li>
                <li>
                  KISA games acts as a Processor for processing Personal Data
                  that it receives through the use of the Zombie TD game for
                  Users, the provision of Services, and communication with
                  employees of KISA games.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                PERSONAL DATA
              </Typography>

              <ol>
                <li>
                  The Company may receive the following Personal Data about the
                  User:
                  <ol>
                    <li>full name, including in the case of a refund;</li>
                    <li>date of birth;</li>
                    <li>contacts: e-mail, contact phone number;</li>
                    <li>e-wallet required for registration of the User.</li>
                  </ol>
                </li>

                <li>
                  KISA games does not collect or process sensitive Personal Data
                  of Users, such as race or ethnic origin, political opinions,
                  religious or philosophical beliefs, trade union membership,
                  genetic or biometric data, or sexual orientation.
                </li>

                <li>
                  The Company does not process the User's banking data, except
                  the e-wallet required for registration. In the case of
                  transactions with the ZTD Token, the payment and banking data
                  of the User are processed by the corresponding service
                  “cryptocurrency exchange.”
                </li>

                <li>
                  When you visit the Site, use the App, or receive the Services,
                  KISA games may collect the following Personal Data
                  automatically:
                  <ol>
                    <li>IP address;</li>
                    <li>setting the time zone and language;</li>
                    <li>browser type and version;</li>
                    <li>
                      operating system, device type, and screen resolution;
                    </li>
                    <li>the country in which you are located;</li>
                    <li>
                      data about your visit to the Site/App, including full URL
                      information, routes to and from the Site/App when
                      navigating to the Site/App (including date and time), page
                      response time, data loading errors, length of stay on
                      certain pages, activity on the page (information such as
                      scrolling and mouse movements, clicks), the methods used
                      to exit the page, and the phone numbers you used to
                      contact support.
                    </li>
                  </ol>
                </li>

                <li>
                  KISA games has the right to collect and receive Personal Data
                  in the following ways:
                  <ol>
                    <li>during the provision of the Services;</li>
                    <li>during gameplay in Zombie TD;</li>
                    <li>when the User contacts the support service;</li>
                    <li>
                      using log files, Cookies, and other tracking technologies;
                    </li>
                    <li>
                      through the use of chat in the Telegram messenger Zombie
                      TD Official Chat.
                    </li>
                  </ol>
                </li>

                <li>
                  The Company has the right to use Personal Data for the
                  following purposes:
                  <ol>
                    <li>to provide the Services;</li>
                    <li>to ensure the security of the Zombie TD Site/game;</li>
                    <li>to provide gameplay in Zombie TD;</li>
                    <li>
                      to administer the Zombie TD Site/Game and conduct internal
                      operations, including troubleshooting, data analysis,
                      testing, and surveys;
                    </li>
                    <li>
                      to inform the User about new types of Services, changes in
                      the cost of Services, etc.;
                    </li>
                    <li>to respond to law enforcement inquiries;</li>
                    <li>
                      to initiate responses to legal claims, investigations, or
                      dispute resolution with you.
                    </li>
                  </ol>
                </li>

                <li>
                  The Company does not use the User's Personal Data for
                  marketing purposes and/or for sale to Third Parties.
                </li>

                <li>
                  The Company may collect data about the User's behavior in the
                  game Zombie TD solely to improve the gameplay of Zombie TD.
                </li>

                <li>
                  The Company has the right to collect data about the User's
                  transactions with the ZTD Token and/or NFT solely to provide
                  the Services.
                </li>

                <li>
                  The Company may collect and process the User's Personal Data
                  provided to them voluntarily in the Zombie TD Official Chat.
                  The Company is not responsible for Personal Data and/or
                  messages and/or content that Users post in Zombie TD Official
                  Chat created by Zombie TD, and such Personal Data is not
                  subject to the provisions of this Privacy Policy.
                </li>

                <li>
                  The Company is not responsible for the use/receipt/placement
                  of information, placement of Personal Data, communication with
                  third parties, losses, loss of information and/or funds,
                  disclosure of Personal Data if the User uses third-party
                  groups, channels, chats with a similar name to the Official
                  group, channel, and chat, specified in the first section
                  “Definitions.”
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                PERSONAL DATA TRANSFER
              </Typography>

              <ol>
                <li>
                  The Company has the right to transfer Personal Data to Third
                  Parties for the implementation and provision of the Services
                  and/or protection of its legal interests:
                  <ol>
                    <li>
                      <b>Contractors and workers.</b> KISA games may transfer
                      Personal Data to other entities with which it enters into
                      cooperation, including legal and tax consultants, and
                      entities providing accounting, marketing, and IT Services.
                    </li>

                    <li>
                      <b>Courts, law enforcement, and government agencies. </b>
                      The Company has the right to transfer Personal Data if
                      fulfilling any legal obligations, including in connection
                      with a court order.
                    </li>

                    <li>
                      <b>Third Person.</b> The Company has the right to disclose
                      or transfer the User's Personal Data in the following
                      transactions: acquisition or merger, financing, corporate
                      reorganization, joint venture, including the sale of
                      assets, or in the event of bankruptcy.
                    </li>
                  </ol>
                </li>

                <li>
                  The Company does not transfer Personal Data to cryptocurrency
                  exchanges. Such transfer is possible only at the written
                  request of the User who owns the Personal Data.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                ACCESS TO PERSONAL DATA
              </Typography>

              <ol>
                <li>
                  Only authorized employees of the Company have access to
                  Personal Data, according to their level of access.
                </li>
                <li>
                  Each employee who has access to Personal Data enters into a
                  non-disclosure agreement for confidential information and
                  Personal Data and complies with the terms of its provisions.
                </li>
                <li>
                  The Company monitors the actions of employees who have access
                  to the User's Personal Data and guarantees non-disclosure of
                  Personal Data by such employees.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                STORAGE AND PROTECTION OF PERSONAL DATA
              </Typography>

              <ol>
                <li>
                  The Company uses all necessary security and protection
                  measures for Personal Data to ensure its confidentiality and
                  prevents loss or unauthorized disclosure.
                </li>

                <li>
                  The Company protects and stores Personal Data from:
                  <ol>
                    <li>losses;</li>
                    <li>
                      illegal use, transfer, disclosure, modification, deletion,
                      and/or destruction.
                    </li>
                  </ol>
                </li>
                <li>
                  We may use the following methods to protect Personal Data in
                  accordance with{" "}
                  <Link
                    href={"https://gdpr-text.com/read/article-32/"}
                    target={"_blank"}
                  >
                    Article 32 GDPR “Security of Processing”
                  </Link>{" "}
                  or similar principles in the relevant jurisdiction:
                  <ol>
                    <li>pseudonymization and encryption of Personal Data;</li>
                    <li>
                      the ability to ensure the continued confidentiality,
                      integrity, availability, and resiliency of personal data
                      processing systems;
                    </li>
                    <li>
                      regular testing, evaluation, and measurement of the
                      effectiveness of technical and organizational measures to
                      ensure the security of the processing of Personal data.
                    </li>
                  </ol>
                </li>
                <li>
                  The Company's server is located in Germany and Personal Data
                  may be transferred to this country.
                </li>
                <li>
                  The Company may delete the User's Personal Data upon receipt
                  of a written request from the User and upon the termination of
                  the relationship. By deleting Personal Data, we mean deleting
                  all Personal Data from servers and/or written carrier.
                </li>

                <li>
                  KISA games has the right to store the User's Personal Data{" "}
                  <b>for 2 years</b> after the termination of relations with the
                  User in the following cases:
                  <ol>
                    <li>for conducting statistical accounting;</li>
                    <li>if applicable law requires their storage;</li>
                    <li>to communicate with the User;</li>
                    <li>if they are needed to run a business.</li>
                  </ol>
                </li>

                <li>
                  The Company does not store the User's Personal Data when
                  requesting the deletion of Personal Data on their behalf in
                  accordance with the right to erasure, as provided in{" "}
                  <Link
                    href={"https://gdpr-text.com/read/article-17/"}
                    target={"_blank"}
                  >
                    Article 17 "Right to erasure"
                  </Link>{" "}
                  of the GDPR or the corresponding provision of another
                  jurisdiction.
                </li>

                <li>
                  The Company does not transfer, disclose, or process Personal
                  Data after the termination of relations with the User, but
                  only stores.
                </li>

                <li>
                  KISA games has the right not to delete Personal Data in the
                  event of a company reorganization, merger of KISA games with
                  another company, or any other changes in the structure of KISA
                  games and the event of a server change.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                BASIS FOR PROCESSING PERSONAL DATA
              </Typography>

              <ol>
                <li>
                  The Company processes Personal Data based on the following
                  legal grounds:
                  <ol>
                    <li>Consent of the User;</li>
                    <li>registration on the Site;</li>
                    <li>
                      transfer of your Personal Data to the jurisdiction where
                      the server is located;
                    </li>
                    <li>installation of the game Zombie TD.</li>
                  </ol>
                </li>

                <li>
                  In cases where the basis for processing Personal Data is your
                  Consent, you have the right to withdraw it at any time. In
                  case of withdrawal of your Agreement, the Company has the
                  right to terminate the Services' provision and all relations
                  with you. If you withdraw your Consent, your Personal Data
                  will be permanently deleted.
                </li>

                <li>
                  The Company undertakes to stop processing Personal Data within
                  10 (ten) business days from the date of receipt of the
                  withdrawal of the Agreement.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                USER CONSENT
              </Typography>

              <ol>
                <li>
                  The performance of the following actions is considered Consent
                  to the conditions for the processing of Personal Data
                  specified in the Privacy Policy:
                  <ol>
                    <li>
                      providing Consent in the relevant section of the Site;
                    </li>
                    <li>registration on the Site;</li>
                    <li>installation of the game Zombie TD.</li>
                  </ol>
                </li>

                <li>
                  The Company reserves the right to obtain the User's Consent
                  using the pop-up form.
                </li>

                <li>
                  The User provides their voluntary, informed, and unambiguous
                  declaration of will to:
                  <ol>
                    <li>
                      the processing of their Personal Data during the provision
                      of the Services and/or the gameplay in Zombie TD;
                    </li>
                    <li>
                      to the processing of Personal Data by the Company posted
                      by the User in Zombie TD Official Chat and Zombie TD
                      Twitter;
                    </li>
                    <li>
                      for the storage of their Personal Data after the end of
                      the relationship between the parties, in accordance with
                      clause 6.6 this Privacy Policy;
                    </li>
                    <li>
                      if necessary, process data on transactions with the ZTD
                      Token and/or NFT.
                    </li>
                  </ol>
                </li>

                <li>
                  The User has the right to withdraw their Consent to the
                  processing of Personal Data at any time, in which case the
                  Company has the right to stop providing the Services to them,
                  and their Personal Data will be permanently deleted.
                </li>

                <li>
                  If the User withdraws their Consent to the processing of their
                  Personal Data, the Company has the right to block their
                  Account and not provide the User with the Services indicated
                  on the Site. In this case, the User is solely responsible for
                  all their ZTD Tokens and undertakes to exchange their ZTD
                  Tokens within 24 hours from the date of withdrawal of the
                  Consent. After the expiration of 24 hours from the moment of
                  withdrawal of Consent, all ZTD Tokens of such User will be
                  distributed among other Users. The Company does not restrict
                  access to the Zombie TD game to such a User but does not
                  provide any Services and the possibility of obtaining ZTD
                  Tokens. Withdrawal by the User of their Consent automatically
                  means the User's waiver of the Terms of Use, with all the
                  ensuing consequences.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                JURISDICTION OF THE EUROPEAN UNION AND THE EUROPEAN ECONOMIC
                AREA
              </Typography>

              <ol>
                <li>
                  The terms of this section apply to residents of the European
                  Union and the European Economic Area. In accordance with the
                  terms of this paragraph, the User has the following rights:
                  <ol>
                    <li>
                      <b>Right of access.</b> The Privacy Policy lists the
                      User's Personal Data. The User may contact us directly to
                      request access to the Personal Data we hold about them, as
                      well as to any information, in accordance with{" "}
                      <Link
                        href={
                          "https://gdpr-text.com/read/article-15/?col=1&lang1=en&lang2=en&lang3=uk"
                        }
                        target={"_blank"}
                      >
                        Article 15 of the General Data Protection Regulation.
                      </Link>
                    </li>
                    <li>
                      <b>Right to erasure.</b> The User has the right to delete
                      Personal Data about themself in accordance with{" "}
                      <Link
                        href={
                          "https://gdpr-text.com/read/article-17/?col=1&lang1=en&lang2=en&lang3=uk"
                        }
                        target={"_blank"}
                      >
                        Article 17 of the General Data Protection Regulation.
                        Personal data, in this case, will be permanently
                        deleted.
                      </Link>
                    </li>
                    <li>
                      <b>Right to data portability.</b> We may send the User's
                      Personal Data to third parties at the request of the User,
                      in accordance with{" "}
                      <Link
                        href={
                          "https://gdpr-text.com/read/article-20/?col=1&lang1=en&lang2=en&lang3=uk"
                        }
                        target={"_blank"}
                      >
                        Article 20 of the General Data Protection Regulation.
                      </Link>
                    </li>
                    <li>
                      <b>Right to rectification.</b> The User has access to
                      Personal Data that needs to be updated, corrected, and
                      supplemented at any time. The User may also contact the
                      Company to correct or amend, in accordance with{" "}
                      <Link
                        href={
                          "https://gdpr-text.com/read/article-16/?col=1&lang1=en&lang2=en&lang3=uk"
                        }
                        target={"_blank"}
                      >
                        Article 16 of the General Data Protection Regulation.
                      </Link>
                    </li>
                    <li>
                      <b>Right to object.</b> The User has the right to object
                      at any time to processing their Personal Data in
                      accordance with{" "}
                      <Link
                        href={
                          "https://gdpr-text.com/read/article-21/?col=1&lang1=en&lang2=en&lang3=uk"
                        }
                        target={"_blank"}
                      >
                        Article 21 of the General Data Protection Regulation.
                      </Link>
                    </li>
                    <li>
                      <b>
                        Automated individual decision-making, including
                        profiling.
                      </b>{" "}
                      The User has the right not to be subject to a decision
                      based solely on the automated processing of their Personal
                      Data, including profiling, which has legal consequences
                      for them or similarly significantly affects them, in
                      accordance with{" "}
                      <Link
                        href={
                          "https://gdpr-text.com/read/article-22/?col=1&lang1=en&lang2=en&lang3=uk"
                        }
                        target={"_blank"}
                      >
                        Article 22 of the General Data Protection Regulation.
                      </Link>
                    </li>
                  </ol>
                </li>

                <li>
                  Suppose the User considers that their rights have been
                  violated during the processing of Personal Data or is
                  concerned about the methods and ways of processing Personal
                  Data. In that case, they have the right to file a complaint
                  with the data protection supervisory authority or write a
                  request to the Company's support at:{" "}
                  <Link href={"mailto:info@zombietd.app"}>
                    info@zombietd.app
                  </Link>
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                US JURISDICTION
              </Typography>

              <ol>
                <li>
                  The terms of this section apply to US residents as contained
                  in the Privacy Policy regarding specific requirements under
                  the{" "}
                  <Link
                    href={
                      "https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201720180SB1121"
                    }
                    target={"_blank"}
                  >
                    California Consumer Privacy Act
                  </Link>
                  , the provisions of the Federal Trade Commission, or other
                  similar legislation in force in the United States relating to
                  data processing.
                </li>

                <li>
                  The Company does not support the <b>Do Not Track</b> mode. Do
                  Not Track is a setting you can set in your web browser to
                  inform websites that you do not want to be tracked. You can
                  enable or disable Do Not Track by visiting your web browser's
                  settings page.
                </li>

                <li>
                  Rights granted to US residents:
                  <ol>
                    <li>
                      <b>Right of access.</b> The Privacy Policy lists the
                      User's Personal Data. The User may contact us directly to
                      request access to the Personal Data we hold about them.
                    </li>
                    <li>
                      <b>Right to erasure.</b> The User has the right to delete
                      Personal Data about themself, in which case they will be
                      permanently deleted.
                    </li>
                    <li>
                      <b>Right to correction.</b> The User has access to
                      Personal Data that needs to be updated, corrected, and
                      supplemented at any time.
                    </li>
                    <li>
                      <b>Right to data portability.</b> We may send Personal
                      Data to third parties at the request of the User.
                    </li>
                    <li>
                      <b>Right to object.</b> The User has the right to object
                      to processing Personal Data at any time.
                    </li>
                  </ol>
                </li>

                <li>
                  A User may designate an authorized agent to submit a request
                  under the CCPA on their behalf if:
                  <ol>
                    <li>
                      An authorized agent is a person or entity registered with
                      the Secretary of State of California;
                    </li>
                    <li>
                      You sign a written statement that you authorize the
                      authorized agent to act on your behalf;
                    </li>
                    <li>
                      If you are using an Authorized Agent to request to
                      exercise your right to be informed or your right to
                      erasure, please send a certified copy of your written
                      statement allowing the Authorized Agent to act on your
                      behalf using the contact information below.
                    </li>
                  </ol>
                </li>

                <li>
                  Suppose the User believes that their rights were violated
                  during the processing of Personal Data or is concerned about
                  the methods and ways of processing Personal Data. In that
                  case, they have the right to submit a request in writing to
                  the Company's support at:{" "}
                  <Link href={"mailto:info@zombietd.app"}>
                    info@zombietd.app
                  </Link>
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                JURISDICTION OF CANADA
              </Typography>

              <ol>
                <li>
                  The terms of this section apply to residents of Canada as
                  contained in the Privacy Policy for specific requirements
                  under the{" "}
                  <Link
                    href={
                      "https://laws-lois.justice.gc.ca/ENG/ACTS/P-8.6/index.html"
                    }
                    target={"_blank"}
                  >
                    Personal Information Protection and Electronic Documents Act
                    (PIPEDA)
                  </Link>
                  .
                </li>

                <li>
                  Rights granted to residents of Canada:{" "}
                  <ol>
                    <li>
                      <b>Right of access.</b> The Privacy Policy lists the
                      User's Personal Data. The User may contact us directly to
                      request access to the Personal Data we hold about them.
                    </li>
                    <li>
                      <b>Right to erasure.</b> The User has the right to delete
                      Personal Data about themself, in which case they will be
                      permanently deleted.
                    </li>
                    <li>
                      <b>Right to correction.</b> The User has access to
                      Personal Data that needs to be updated, corrected, and
                      supplemented at any time.
                    </li>
                    <li>
                      <b>Right to data portability.</b> We may send Personal
                      Data to third parties at the request of the User.
                    </li>
                    <li>
                      <b>Right to object.</b> The User has the right to object
                      to processing Personal Data at any time.
                    </li>
                  </ol>
                </li>

                <li>
                  During the processing of the User's Personal Data, the Company
                  performs the following actions:
                  <ol>
                    <li>
                      Responsible for the accuracy of the transmitted data;
                    </li>
                    <li>
                      Informs Third Parties of the terms of this Privacy Policy;
                    </li>
                    <li>
                      Provides the User with the opportunity to submit a request
                      to:{" "}
                      <Link href={"info@zombietd.app"} target={"_blank"}>
                        info@zombietd.app
                      </Link>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                AUSTRALIA
              </Typography>

              <ol>
                <li>
                  The terms of this section apply to residents of Australia as
                  contained in the Privacy Policy relating to specific
                  requirements under the{" "}
                  <Link
                    href={
                      "https://www.ag.gov.au/rights-and-protections/privacy"
                    }
                    target={"_blank"}
                  >
                    Privacy Act 1988
                  </Link>
                  .
                </li>

                <li>
                  13 Australian Privacy Principles govern the standards, rights,
                  and obligations in relation to:
                  <ol>
                    <li>collection, use, and disclosure of Personal Data;</li>
                    <li>integrity and correction of Personal Data;</li>
                    <li>
                      the rights of individuals to access their Personal Data.
                    </li>
                  </ol>
                </li>

                <li>
                  The principles of confidentiality observed by the Company
                  during the processing of Personal Data:
                  <ol>
                    <li>
                      <Link
                        href={
                          "https://www.oaic.gov.au/privacy/australian-privacy-principles-guidelines/chapter-1-app-1-open-and-transparent-management-of-personal-information"
                        }
                        target={"_blank"}
                      >
                        Open and transparent management of Personal Data.
                      </Link>{" "}
                      Ensures that the Company manages Personal Data in an open
                      and transparent manner.
                    </li>
                    <li>
                      <Link
                        href={
                          "https://www.oaic.gov.au/privacy/australian-privacy-principles-guidelines/chapter-2-app-2-anonymity-and-pseudonymity"
                        }
                        target={"_blank"}
                      >
                        Anonymity and pseudonymization.
                      </Link>{" "}
                      The Company provides the User with the opportunity not to
                      identify themself or use a pseudonym.
                    </li>
                    <li>
                      <Link
                        href={
                          "https://www.oaic.gov.au/privacy/australian-privacy-principles-guidelines/chapter-3-app-3-collection-of-solicited-personal-information"
                        }
                        target={"_blank"}
                      >
                        Collection of requested Personal Data.
                      </Link>{" "}
                      Describes when the Company may collect the requested
                      Personal Data.
                    </li>
                    <li>
                      <Link
                        href={
                          "https://www.oaic.gov.au/privacy/australian-privacy-principles-guidelines/chapter-4-app-4-dealing-with-unsolicited-personal-information"
                        }
                        target={"_blank"}
                      >
                        Dealing with the requested Personal Data.
                      </Link>{" "}
                      Describes how the Company processes Personal Data.
                    </li>
                    <li>
                      <Link
                        href={
                          "https://www.oaic.gov.au/privacy/australian-privacy-principles-guidelines/chapter-5-app-5-notification-of-the-collection-of-personal-information"
                        }
                        target={"_blank"}
                      >
                        Notice of Personal Data Collection.
                      </Link>{" "}
                      Describes when and under what circumstances the Company
                      must inform an individual about the processing of Personal
                      Data.
                    </li>
                    <li>
                      <Link
                        href={
                          "https://www.oaic.gov.au/privacy/australian-privacy-principles-guidelines/chapter-6-app-6-use-or-disclosure-of-personal-information"
                        }
                        target={"_blank"}
                      >
                        Use or Disclosure of Personal Data.
                      </Link>{" "}
                      Describes the circumstances under which the Company may
                      use or disclose the Personal Data it processes.
                    </li>
                    <li>
                      <Link
                        href={
                          "https://www.oaic.gov.au/privacy/australian-privacy-principles-guidelines/chapter-7-app-7-direct-marketing"
                        }
                        target={"_blank"}
                      >
                        Direct marketing.
                      </Link>{" "}
                      Describes when the Company may use or disclose Personal
                      Data for direct marketing purposes only under certain
                      conditions.
                    </li>
                    <li>
                      <Link
                        href={
                          "https://www.oaic.gov.au/privacy/australian-privacy-principles-guidelines/chapter-8-app-8-cross-border-disclosure-of-personal-information"
                        }
                        target={"_blank"}
                      >
                        Cross-Border disclosure of Personal Data.
                      </Link>{" "}
                      Describes the Company's steps to protect Personal Data
                      before it is disclosed abroad.
                    </li>
                    <li>
                      <Link
                        href={
                          "https://www.oaic.gov.au/privacy/australian-privacy-principles-guidelines/chapter-9-app-9-adoption-use-or-disclosure-of-government-related-identifiers"
                        }
                        target={"_blank"}
                      >
                        Acceptance, use, or disclosure of government-related
                        identifiers.
                      </Link>{" "}
                      Describes the limited circumstances in which the Company
                      may accept an individual's government identifier as its
                      identifier or use or disclose an individual's government
                      identifier.
                    </li>
                    <li>
                      <Link
                        href={
                          "https://www.oaic.gov.au/privacy/australian-privacy-principles-guidelines/chapter-10-app-10-quality-of-personal-information"
                        }
                        target={"_blank"}
                      >
                        Quality of Personal Data.
                      </Link>{" "}
                      The Company must take reasonable steps to ensure that the
                      Personal Data it collects is accurate, current, and
                      complete.
                    </li>
                    <li>
                      <Link
                        href={
                          "https://www.oaic.gov.au/privacy/australian-privacy-principles-guidelines/chapter-11-app-11-security-of-personal-information"
                        }
                        target={"_blank"}
                      >
                        {" "}
                        Security of Personal Data.
                      </Link>{" "}
                      The Company must take reasonable steps to protect the
                      Personal Data it holds from misuse, interference, and loss
                      and unauthorized access, alteration, or disclosure. The
                      Company is required to destroy Personal Data or anonymize
                      it under certain circumstances.
                    </li>
                    <li>
                      <Link
                        href={
                          "https://www.oaic.gov.au/privacy/australian-privacy-principles-guidelines/chapter-12-app-12-access-to-personal-information"
                        }
                        target={"_blank"}
                      >
                        Access to Personal Data.
                      </Link>{" "}
                      Describes the obligations of the Company when the User
                      requests access to Personal Data held by the Company. It
                      includes the requirement to grant access unless a specific
                      exception applies.
                    </li>
                    <li>
                      <Link
                        href={
                          "https://www.oaic.gov.au/privacy/australian-privacy-principles-guidelines/chapter-13-app-13-correction-of-personal-information"
                        }
                        target={"_blank"}
                      >
                        Correction of Personal Data.
                      </Link>{" "}
                      Describes the Company's obligation to correct the Personal
                      Data it holds about individuals.
                    </li>
                  </ol>
                </li>

                <li>
                  Suppose the User believes that their rights were violated
                  during the processing of Personal Data or is concerned about
                  the methods and ways of processing Personal Data. In that
                  case, they have the right to submit a request in writing to
                  the Company's support at:{" "}
                  <Link href={"mailto:info@zombietd.app"}>
                    info@zombietd.app
                  </Link>
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                SOUTH KOREA
              </Typography>

              <ol>
                <li>
                  This section's terms apply to South Korean residents in the
                  Privacy Policy regarding specific requirements under{" "}
                  <Link
                    href={
                      "https://elaw.klri.re.kr/kor_service/lawView.do?hseq=53044&lang=ENG"
                    }
                    target={"_blank"}
                  >
                    South Korea's Personal Information Protection Act (PIPA)
                  </Link>
                  .
                </li>

                <li>
                  Rights granted to residents of South Korea:
                  <ol>
                    <li>
                      <b>Right of access.</b> The Privacy Policy lists the
                      User's Personal Data. The User may contact us directly to
                      request access to the Personal Data we hold about them.
                    </li>
                    <li>
                      <b>Right to erasure.</b> The User has the right to delete
                      Personal Data about themself, in which case they will be
                      permanently deleted.
                    </li>
                    <li>
                      <b>Right to correction.</b> The User has access to
                      Personal Data that needs to be updated, corrected, and
                      supplemented at any time.
                    </li>
                  </ol>
                </li>

                <li>
                  During the processing of the Personal Data of residents of
                  South Korea, the Company is guided by the principles
                  established by PIPA applicable to such processing:
                  <ol>
                    <li>
                      The Company indicates the purposes of processing Personal
                      Data, and they are processed lawfully, fairly, and to the
                      minimum extent necessary for such purposes;
                    </li>
                    <li>
                      The Company processes Personal Data in the appropriate
                      manner necessary for the purposes and is not used beyond
                      these purposes;
                    </li>
                    <li>
                      The Company securely manages Personal Data in accordance
                      with the processing methods, taking into Account the
                      possibility of violation of the User's rights and the
                      severity of the corresponding risks;
                    </li>
                    <li>
                      The Company demonstrates its Privacy Policy and answers
                      other questions related to the processing of Personal Data
                      and guarantees the rights of the User, such as the right
                      to access their Personal Data;
                    </li>
                    <li>
                      The Company processes personal data in such a way as to
                      minimize the possibility of violating the User's privacy;
                    </li>
                    <li>
                      The Company ensures the accuracy, completeness, and
                      relevance of the Personal Data to the extent necessary in
                      connection with the purposes for which the Personal Data
                      is processed;
                    </li>
                    <li>
                      The Company may process Personal Data by anonymization if
                      it is possible or by pseudonymization if it is impossible
                      to fulfill the purposes of collecting Personal Data
                      through anonymization;
                    </li>
                    <li>
                      The Company seeks to earn the trust of data subjects by
                      complying with and fulfilling such duties and
                      responsibilities as provided for in PIPA and other
                      relevant legislation.
                    </li>
                  </ol>
                </li>

                <li>
                  Suppose the User believes that their rights were violated
                  during the processing of Personal Data or is concerned about
                  the methods and ways of processing Personal Data. In that
                  case, they have the right to submit a request in writing to
                  the Company's support at:{" "}
                  <Link href={"mailto:info@zombietd.app"}>
                    info@zombietd.app
                  </Link>
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                JAPAN
              </Typography>

              <ol>
                <li>
                  The terms of this section apply to residents of Japan as
                  contained in the Privacy Policy regarding specific
                  requirements under{" "}
                  <Link
                    href={"https://www.ppc.go.jp/files/pdf/APPI_english.pdf"}
                    target={"_blank"}
                  >
                    The Act on the Protection of Personal Information (Act No.
                    57 of 2003 as amended in 2015) ('APPI')
                  </Link>
                  .
                </li>

                <li>
                  Rights granted to residents of Japan:
                  <ol>
                    <li>
                      <b>Right of access.</b> The Privacy Policy lists the
                      User's Personal Data. The User may contact us directly to
                      request access to the Personal Data we hold about them.
                    </li>
                    <li>
                      <b>Right to delete.</b> The User has the right to delete
                      Personal Data about themself, in which case they will be
                      permanently deleted. The Company deletes the User's
                      Personal Data within 6 (six) months from the receipt of
                      the request from the User.
                    </li>
                    <li>
                      <b>The right to receive information.</b> The User has the
                      right to know the purpose of using Personal Data before it
                      is collected.
                    </li>
                    <li>
                      <b>Right to сorrection.</b> The User has the right to
                      access Personal Data that needs to be updated, corrected,
                      or supplemented at any time.
                    </li>
                    <li>
                      <b>Right to object/reject.</b> The User has the right to
                      object to the processing of their Personal Data or refuse
                      the Company to transfer their Personal Data to another
                      jurisdiction.
                    </li>
                  </ol>
                </li>

                <li>
                  Suppose the User believes that their rights were violated
                  during the processing of Personal Data or is concerned about
                  the methods and ways of processing Personal Data. In that
                  case, they have the right to submit a request in writing to
                  the Company's support at:{" "}
                  <Link href={"mailto:info@zombietd.app"}>
                    info@zombietd.app
                  </Link>
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                PEOPLE'S REPUBLIC OF CHINA
              </Typography>

              <ol>
                <li>
                  The terms of this section apply to residents of the People's
                  Republic of China as contained in the Privacy Policy regarding
                  specific requirements under the{" "}
                  <Link
                    href={
                      "http://en.npc.gov.cn.cdurl.cn/2021-12/29/c_694559.htm"
                    }
                    target={"_blank"}
                  >
                    Personal Information Protection Law (PIPL)
                  </Link>
                  .
                </li>

                <li>
                  Rights granted to residents of China:
                  <ol>
                    <li>
                      <b>Right of access.</b> The Privacy Policy lists the
                      User's Personal Data. The User may contact us directly to
                      request access to the Personal Data we hold about them.
                    </li>
                    <li>
                      <b>Right to delete.</b> The User has the right to delete
                      Personal Data about themself, in which case they will be
                      permanently deleted.
                    </li>
                    <li>
                      <b>Right to сorrection.</b> The User has the right to
                      access Personal Data that needs to be updated, corrected,
                      and supplemented at any time.
                    </li>
                    <li>
                      <b>Right to object/restrict.</b> The User has the right to
                      object to the processing of their Personal Data or to
                      restrict the processing of their Personal Data.
                    </li>
                    <li>
                      <b>Right to data portability.</b> We may send Personal
                      Data to third parties at the request of the User.
                    </li>
                    <li>
                      <b>
                        Automated decision-making in individual cases, including
                        profiling.
                      </b>{" "}
                      The User has the right not to be subject to a decision
                      based solely on the automated processing of their Personal
                      Data, including profiling, which causes legal consequences
                      for them or similarly significantly affects them.
                    </li>
                  </ol>
                </li>

                <li>
                  Suppose the User believes that their rights were violated
                  during the processing of Personal Data or is concerned about
                  the methods and ways of processing Personal Data. In that
                  case, they have the right to submit a request in writing to
                  the Company's support at:{" "}
                  <Link href={"mailto:info@zombietd.app"}>
                    info@zombietd.app
                  </Link>
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                FEDERAL REPUBLIC OF BRAZIL
              </Typography>

              <ol>
                <li>
                  The terms of this section apply to residents of the Federal
                  Republic of Brazil, contained in the Privacy Policy, and
                  regarding specific requirements in accordance with the
                  provisions of the legislation of the Federative Republic of
                  Brazil, in particular the{" "}
                  <Link href={"https://lgpd-brazil.info/"} target={"_blank"}>
                    General Personal Data Protection Act (LGPD)
                  </Link>
                  .
                </li>

                <li>
                  According to{" "}
                  <Link
                    href={"https://lgpd-brazil.info/chapter_03/article_18"}
                    target={"_blank"}
                  >
                    Art. 18 “Personal Data Subject's Rights in Relation to the
                    Controller” of the General Law on the Protection of Personal
                    Data 13709/2018
                  </Link>
                  , the User has the right to:
                  <ol>
                    <li>confirmation of the processing of Personal Data;</li>
                    <li>access to Personal Data;</li>
                    <li>
                      correction of incomplete, inaccurate, or outdated Personal
                      Data; anonymization, blocking, or deletion of unnecessary,
                      excessive, or processed Personal Data in violation of the
                      provisions of this Law;
                    </li>
                    <li>
                      the transfer of Personal Data to another provider of
                      Services or products, upon express request, in accordance
                      with the regulations of the national authority, which are
                      commercial and industrial secrets;
                    </li>
                    <li>
                      deletion of Personal Data processed with the Consent of
                      the Data Subject, except as provided for in Art. 16 of
                      this Law;
                    </li>
                    <li>
                      obtaining information about public and private persons
                      with whom the Company has shared Personal Data;
                    </li>
                    <li>
                      obtaining information about the possibility of failure to
                      provide the Agreement and the consequences of refusal;
                    </li>
                    <li>
                      withdrawal of Consent, according to Art. 8 of this Law.
                    </li>
                  </ol>
                </li>

                <li>
                  Suppose the User believes that their rights were violated
                  during the processing of Personal Data or is concerned about
                  the methods and ways of processing Personal Data. In that
                  case, they have the right to submit a request in writing to
                  the Company's support at:{" "}
                  <Link href={"mailto:info@zombietd.app"}>
                    info@zombietd.app
                  </Link>
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                REPUBLIC OF BELARUS
              </Typography>

              <ol>
                <li>
                  The terms of this section apply to residents of the Republic
                  of Belarus, contained in the Privacy Policy, and relate to
                  specific requirements based on the Law of the{" "}
                  <Link href={"https://elo.belta.by/by/"} target={"_blank"}>
                    Republic of Belarus “On the Protection of Personal Data”
                  </Link>
                  .
                </li>

                <li>
                  While processing the Personal Data of residents of the
                  Republic of Belarus, KISA games acts as a Personal Data
                  Processor.
                </li>

                <li>
                  Rights granted to residents of the Republic of Belarus:
                  <ol>
                    <li>
                      <b>
                        The right to withdraw the Consent of the subject of
                        personal data.
                      </b>{" "}
                      The User has the right to withdraw their Consent without
                      giving reasons by applying the operator in the manner
                      prescribed by Article 14 of this Law or in the form by
                      which their Consent was obtained.
                    </li>
                    <li>
                      <b>
                        The right to receive information about the provision of
                        personal data to third parties.
                      </b>{" "}
                      The User has the right to demand from the operator the
                      free termination of the processing of their Personal Data,
                      including their deletion, in the absence of grounds for
                      the processing of Personal Data provided for by this Law
                      and other legislative acts.
                    </li>
                    <li>
                      <b>
                        The right to appeal against actions (inaction) and
                        decisions of the operator related to processing personal
                        data.
                      </b>{" "}
                      The User has the right to appeal against the actions
                      (inaction) and decisions of the operator that violate
                      their rights in the processing of Personal Data to the
                      authorized body for the protection of the rights of
                      subjects of Personal Data in the manner prescribed by law
                      on appeals of citizens and legal entities.
                    </li>
                  </ol>
                </li>

                <li>
                  Suppose the User believes that their rights were violated
                  during the processing of Personal Data or is concerned about
                  the methods and ways of processing Personal Data. In that
                  case, they have the right to submit a request in writing to
                  the Company's support at:{" "}
                  <Link href={"mailto:info@zombietd.app"}>
                    info@zombietd.app
                  </Link>
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                OTHER JURISDICTIONS
              </Typography>

              <ol>
                <li>
                  The Company makes every effort for the lawful processing of
                  Personal Data in accordance with international standards for
                  the processing of Personal Data.
                </li>

                <li>
                  This Privacy Policy sets out the main legislative acts
                  regarding the processing of Personal Data.
                </li>

                <li>
                  Unfortunately, we are not in a position to list all the laws
                  regarding processing Personal Data in every jurisdiction in
                  the world.
                </li>

                <li>
                  The terms of this Privacy Policy describe the basic rights of
                  the User, provided for by the main provisions of the main
                  legislative acts governing the processing of Personal Data.
                </li>

                <li>
                  Suppose the User believes that their rights were violated
                  during the processing of Personal Data or is concerned about
                  the methods and ways of processing Personal Data. In that
                  case, they have the right to submit a request in writing to
                  the Company's support at:{" "}
                  <Link href={"mailto:info@zombietd.app"}>
                    info@zombietd.app
                  </Link>
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                SUBMISSION OF A REQUEST
              </Typography>

              <ol>
                <li>
                  A User of any jurisdiction has the right to submit a request
                  to the Company if they believe that their rights have been
                  violated.
                </li>
                <li>
                  The User's request must contain accurate information about the
                  requirements of the Company. The Company has the right to
                  refuse the request if the request does not specify the exact
                  requirements.
                </li>
                <li>
                  We will not be able to respond to your request or provide you
                  with Personal Data unless we can verify your identity and
                  verify that the Personal Data relates to you. In case of
                  receiving a request with inaccurate information and/or if it
                  is impossible to confirm the identity of the User, the Company
                  has the right not to process the received request and contact
                  the User for clarification. In case of receiving a response to
                  a request for clarification, the User is obliged to provide a
                  new corrected request or provide a new request.
                </li>
                <li>
                  The Company must respond to the request or comply with the
                  conditions set out in the request within 21 (twenty-one)
                  business days from the date of its receipt.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                RESPONSIBILITY
              </Typography>

              <ol>
                <li>
                  The Company informs the User that Personal Data may be used to
                  provide the Services, and in case of disagreement, the User
                  must uninstall the Zombie TD game and not use the Company's
                  Services.
                </li>

                <li>
                  If the User provides inaccurate Personal Data, then they are
                  solely responsible for the result of such provision, including
                  the inability to perform actions with the ZTD Token and/or
                  NFT, including their safety and for the availability of the
                  gameplay in Zombie TD.
                </li>

                <li>
                  The User is solely responsible for the safety of Personal Data
                  during its transfer to the Company.
                </li>

                <li>
                  The User is solely responsible for the safety of access to
                  their MetaMask cryptocurrency wallet or Account on a
                  cryptocurrency exchange.
                </li>

                <li>
                  <b>The Company is not responsible for the following:</b>

                  <ol>
                    <li>
                      loss of Personal Data in the event of a hacker attack,
                      hacking off the Zombie TD Site/game software, illegal
                      actions of third parties, illegal actions of the User, as
                      well as actions that violate the terms of the Privacy
                      Policy;
                    </li>

                    <li>
                      for the processing of Personal Data about the User's
                      behavior in the Zombie TD game;
                    </li>

                    <li>
                      for the safety of Personal Data that the User places in
                      Zombie TD Official Chat;
                    </li>
                    <li>
                      for the use of Personal Data posted by the User in Zombie
                      TD Official Chat and Zombie TD Twitter;
                    </li>
                    <li>
                      for processing data on transactions of the User with the
                      ZTD Token and/or NFT;
                    </li>
                    <li>
                      for the processing of Personal Data by a Third Party that
                      does not belong to the Company.
                    </li>
                  </ol>
                </li>

                <li>
                  The User has the right to voluntarily post their Personal Data
                  in the Zombie TD Official Chat and is solely responsible for
                  the safety of this Personal Data, its use by other Users
                  and/or any other Third Party, the loss of such data, or the
                  use of this Personal Data by any Third Party, in including
                  illegal means.
                </li>

                <li>
                  The Company is not responsible for the safety of the User's
                  Accounts in the Telegram messenger - Zombie TD Official Chat.
                </li>

                <li>
                  The transmission of information over the Internet cannot be
                  completely secure. Although we make every effort to protect
                  your Personal Data, we cannot guarantee the secure transfer of
                  your Personal Data to our Site/Zombie TD game. In this regard,
                  you are solely responsible for possible failures in the
                  transmission of your Personal Data to the Zombie TD Site/game.
                  Upon receipt of your Personal Data, we take the necessary
                  technical and security measures to prevent unauthorized access
                  to your Personal Data.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                COOKIE
              </Typography>

              <ol>
                <li>
                  A Cookie is a small file placed on your computer, mobile
                  device, or tablet and contains an identification code that
                  allows you to recognize your computer, mobile device, or
                  tablet and provide you with the best experience on our Site.
                  For example, this allows the Site to remember your actions and
                  settings (such as login information, language, and other
                  display options) for some time so that you do not have to
                  re-enter or change them each time you return to the Site or
                  navigate from one page to another. The use of Сookies is a
                  popular practice for websites, and most web browsers allow the
                  use of Сookies by default.
                </li>

                <li>
                  Site pages may contain electronic images known as web beacons
                  (sometimes called clear gifs or pixel tags). Web beacons are
                  usually small images placed on a web page or email you are
                  viewing. The request that a device connected to the Internet
                  makes to download such an image from a server/computer is
                  recorded, and it provides us with information such as the IP
                  address, the time the image was viewed, and the type of
                  browser used to do so.
                </li>

                <li>
                  Cookies do not transfer viruses and/or malware to your device
                  because the data in a Сookie does not change when moved and
                  does not affect your computer. They act more like logs (i.e.,
                  record User activity and remember status information) and are
                  updated each time you visit the Site.
                </li>

                <li>
                  <b>The Company uses Cookies for the following purposes:</b>
                  <ol>
                    <li>User authentication;</li>
                    <li>
                      storing personal preferences and settings of the User;
                    </li>
                    <li>tracking session access to the game Zombie TD User;</li>
                    <li>tracking transactions with ZTD Token and/or NFT;</li>
                    <li>storage of statistical information about the User;</li>
                    <li>tracking the location of the User;</li>
                    <li>tracking User behavior.</li>
                  </ol>
                </li>

                <li>
                  <b>
                    The Company may use the following web analytics Services:
                  </b>
                  <ol>
                    <li>Facebook;</li>
                    <li>Google Analytics;</li>
                    <li>Company's internal analytics system.</li>
                  </ol>
                </li>

                <li>
                  When you visit the Site for the first time, you will see a
                  Сookie alert banner asking you to provide your Consent to the
                  Site's use of Сookies in accordance with the law.
                </li>
                <li>
                  You can revoke your Cookie Consent and/or change the
                  categories of Сookies you allow at any time. You can also
                  disable Сookies by changing your web browser settings. The
                  information that Сookies collect is in an anonymous form.
                </li>
                <li>
                  Some Сookies are essential for the Site to function properly,
                  and some are not, but they help us improve the Site. Please
                  note that you automatically accept the necessary Сookies from
                  our Site for its proper functioning during your visit (unless
                  you have turned off all Сookies in your browser settings).
                </li>
                <li>
                  We may also use Сookies stored in emails to help us confirm
                  that you have received and responded to such emails.
                </li>
                <li>
                  Please note that if you disable using Сookies in your browser,
                  many features of the Site will not be available to you.
                  Cookies allow you to track the use of the Site by the User,
                  measure data, and analyze the User's experience.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                ADULTS POLICY
              </Typography>

              <ol>
                <li>
                  The Company's Services are intended for persons over the age
                  of 18. The Company does not process Personal Data of persons
                  under 18.
                </li>
                <li>
                  If you are under 18, you may not use the Services and/or play
                  Zombie TD, and you must uninstall Zombie TD and delete your
                  Account.
                </li>
                <li>
                  KISA games has the right to delete the Personal Data and
                  Account of a person under 18, without warning and any
                  consequences for the Company, and stop providing such person
                  with the Services and/or access to the Zombie TD game.
                </li>
                <li>
                  If you have information about someone under 18 using the
                  Services and/or playing Zombie TD, please get in touch with
                  the Company's support team by emailing:{" "}
                  <Link href={"mailto:info@zombietd.app"}>
                    info@zombietd.app
                  </Link>
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                CHANGES TO THE PRIVACY POLICY
              </Typography>

              <ol>
                <li>
                  KISA games has the right to change the Privacy Policy
                  provisions to ensure the preservation of Personal Data and in
                  case of changes in legislation.
                </li>
                <li>
                  KISA games notifies Users of changes to the Privacy Policy by
                  posting news on the Site/Zombie TD game and/or by sending a
                  notice to the User. KISA games updates the date of change of
                  the current version of the Privacy Policy in the "Last
                  updated" line.
                </li>
                <li>
                  The User is obliged to read the new terms of the Privacy
                  Policy, and the Company is not responsible if the User has not
                  read the new terms of the Privacy Policy.
                </li>
                <li>
                  Electronic or otherwise stored copies of the Privacy Policy
                  are deemed the original, complete, valid, and enforceable
                  versions of the Privacy Policy at the time the User visits the
                  Zombie TD Site/game. Suppose the User uses the Services and/or
                  plays Zombie TD. In that case, the Company has the right to
                  consider that the User agrees with the new conditions for
                  processing Personal Data in accordance with the updated
                  version of the Privacy Policy.
                </li>
              </ol>
            </li>

            <li>
              <Typography
                variant={"h4"}
                mt={4}
                mb={1}
                sx={{ display: "inline-flex" }}
              >
                CONTACTS
              </Typography>

              <ol>
                <li>
                  The User has the right to contact the Company's support
                  service at:{" "}
                  <Link href={"mailto:info@zombietd.app"}>
                    info@zombietd.app
                  </Link>{" "}
                  to enforce their rights, in accordance with the terms of this
                  Privacy Policy, or to leave feedback or ask a question.
                </li>
              </ol>
            </li>
          </ol>
        </Container>
      </Box>
    </div>
  )
}
