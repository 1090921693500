import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  total: "10000",
  pricePerBox: "1 ZTD",
  quantityPerPerson: 5,
  available: "0",
  sold: "7400",
  box: {
    rarityLevel: "",
    probability: "",
    issueAmount: "",
  },
}

const boxSlice = createSlice({
  name: "box",

  initialState,

  reducers: {},
})

export default boxSlice.reducer
// export const {} = boxSlice.actions;
