import dividerPrimaryGradientUrl from "../assets/img/border/divider-primary-gradient.svg"
import { IDOSecondStep } from "../components/ido/IDOSecondStep"
import { Container, Grid } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import React from "react"

export default function VerticalLinearStepper() {
  return (
    <Box
      className="content"
      sx={{
        paddingTop: "120px",
      }}
    >
      <Box className="content-head" mb={4}>
        <Container fixed>
          <Box mb={4}>
            <Typography variant={"h1"} mb={2}>
              Information IDO
            </Typography>
            <Typography
              variant="subtitle1"
              mb={2}
              pl={2}
              sx={{
                borderLeft: "1px solid transparent",
                borderImage: `url(${dividerPrimaryGradientUrl}) 1 stretch`,
              }}
            >
              IDO will start on 12/20/2021 at 18:00 UTC+3 and end 48 hours later
              on 12/20/2021 at 18:00 UTC+3. The first 2 hours will be available
              only to participants from the Whitelist. If, after 2 hours, the
              allocation is not redeemed by the participants of the Whitelist,
              then the remaining tokens can be saved by everyone.
            </Typography>
            <Typography
              variant="subtitle1"
              my={2}
              pl={2}
              sx={{
                borderLeft: "1px solid transparent",
                borderImage: `url(${dividerPrimaryGradientUrl}) 1 stretch`,
              }}
            >
              Allocation for all participants is min - 100 BUSD, max - 500 BUSD.
              After purchasing a token, it will automatically appear on your
              balance on the Site. It will be possible to withdraw the token
              from the Site after listing on PanckakeSwap. The token's listing
              on PanckakeSwap will be on 12/20/2021 at 18:00 UTC+3. Users who
              are whitelisted and participated in the IDO on the Site receive a
              guaranteed allocation in selling Boxes with NFT!
            </Typography>
          </Box>

          <Box
            sx={{
              border: "1px solid transparent",
              borderRadius: 2,
              borderImage: `url(${dividerPrimaryGradientUrl}) 1 stretch`,
            }}
            p={3}
            mb={4}
          >
            <Typography variant="h4" mb={2} textAlign="center">
              IDO starts in: TBA
            </Typography>
            {/*TODO: Temporarily not relevant*/}
            {/*<Box sx={{ mx: "auto" }} justifyContent="center">*/}
            {/*  <TimeLeft y={2023} m={10} d={10} />*/}
            {/*</Box>*/}
          </Box>
        </Container>
      </Box>

      <Box className="content-body">
        <Container fixed>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <Box
                sx={{
                  borderRadius: 2,
                  p: 3,
                  background: "rgba(255, 255, 255, .05)",
                  mb: 3,
                  borderLeft: "1px solid transparent",
                  borderImage: `url(${dividerPrimaryGradientUrl}) 1 stretch`,
                }}
              >
                <Typography variant="h4" mb={2}>
                  Information Pool
                </Typography>
                <Box my={1}>
                  <Typography gutterBottom>
                    Start IDO: <span className="primary">TBA</span>
                  </Typography>
                  <Typography gutterBottom>
                    Token Distribution: <span className="primary">TBA</span>
                  </Typography>
                  <Typography gutterBottom>
                    Min Allocation: <span className="primary">100 BUSD</span>
                  </Typography>
                  <Typography gutterBottom>
                    Max Allocation: <span className="primary">500 BUSD</span>
                  </Typography>
                  <Typography gutterBottom>
                    Token Price: <span className="primary">TBA</span>
                  </Typography>
                  <Typography>
                    Token Listing Price: <span className="primary">TBA</span>
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  borderRadius: 2,
                  p: 3,
                  background: "rgba(255, 255, 255, .05)",
                  borderLeft: "1px solid transparent",
                  borderImage: `url(${dividerPrimaryGradientUrl}) 1 stretch`,
                }}
              >
                <Typography variant="h4" mb={2}>
                  Information Token
                </Typography>

                <Box my={1}>
                  <Typography gutterBottom>
                    Token Name: <span className="primary">Zombie TD</span>
                  </Typography>
                  <Typography gutterBottom>
                    Token Symbol: <span className="primary">ZTD</span>
                  </Typography>
                  <Typography gutterBottom>
                    Token Chain: <span className="primary">BSC(BEP20)</span>
                  </Typography>
                  <Typography gutterBottom>
                    Total Supply:{" "}
                    <span className="primary">1,000,000,000 ZTD</span>
                  </Typography>
                  <Typography gutterBottom>
                    Token Address:{" "}
                    <span className="primary">
                      0xffE7F99658a3b237F4fb9C6301bD71A50a41A43c
                    </span>
                  </Typography>
                  <Typography>
                    Token Listing On:{" "}
                    <span className="primary">Pancakeswap</span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box
                sx={{
                  borderRadius: 2,
                  p: 3,
                  background: "rgba(255, 255, 255, .05)",
                  borderLeft: "1px solid transparent",
                  borderImage: `url(${dividerPrimaryGradientUrl}) 1 stretch`,
                }}
              >
                <Typography variant="h4">Private Sale</Typography>
                <Box>
                  <IDOSecondStep />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export const IDO = () => {
  return (
    <section className="ido">
      <div className="container">
        <VerticalLinearStepper />
      </div>
    </section>
  )
}
