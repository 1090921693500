import { createTheme } from "@mui/material/styles"

// A custom theme for this app
const theme = {
  palette: {
    mode: "dark",
    primary: {
      main: "#bc8c4b",
    },
    secondary: {
      main: "#ccc",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
    },
  },

  typography: {
    fontFamily: ["Rajdhani", "sans-serif"].join(","),
    h1: {
      fontFamily: ["Foul Fiend", "sans-serif"].join(","),
      fontSize: "1.5rem",
      lineHeight: "2.5",
      background: "-webkit-linear-gradient(#e8c582, #a16929)",
      backgroundClip: "text",
      textFillColor: "transparent",
      "@media all and (max-width: 768px)": {
        fontSize: "1rem",
      },
    },
    h2: {
      fontFamily: ["Foul Fiend", "sans-serif"].join(","),
      fontSize: "1.5rem",
      lineHeight: "2.5",
      background: "-webkit-linear-gradient(#e8c582, #a16929)",
      backgroundClip: "text",
      textFillColor: "transparent",
      "@media all and (max-width: 768px)": {
        fontSize: "1rem",
      },
    },
    h3: {
      fontFamily: ["Foul Fiend", "sans-serif"].join(","),
      fontSize: "1.15rem",
      lineHeight: "2.5",
      background: "-webkit-linear-gradient(#e8c582, #a16929)",
      backgroundClip: "text",
      textFillColor: "transparent",
      "@media all and (max-width: 768px)": {
        fontSize: "1rem",
      },
    },
    h4: {
      fontFamily: ["Foul Fiend", "sans-serif"].join(","),
      fontSize: "1rem",
      lineHeight: "2.5",
      background: "-webkit-linear-gradient(#e8c582, #a16929)",
      backgroundClip: "text",
      textFillColor: "transparent",
    },
    h5: {
      fontFamily: ["Foul Fiend", "sans-serif"].join(","),
      fontSize: ".75rem",
      lineHeight: "2.5",
      background: "-webkit-linear-gradient(#e8c582, #a16929)",
      backgroundClip: "text",
      textFillColor: "transparent",
      "@media (max-width: 600px)": {
        fontSize: "10px",
      },
    },
    h6: {
      fontFamily: ["Foul Fiend", "sans-serif"].join(","),
      fontSize: ".75rem",
      lineHeight: "2.5",
      background: "-webkit-linear-gradient(#e8c582, #a16929)",
      backgroundClip: "text",
      textFillColor: "transparent",
    },
    body1: {
      fontSize: "1.15rem",
      "@media all and (max-width: 768px)": {
        fontSize: "1rem",
      },
    },
    body2: {
      fontSize: "1rem",
      "@media all and (max-width: 768px)": {
        fontSize: ".875rem",
      },
    },
    subtitle1: {
      fontSize: "1.25rem",
      "@media all and (max-width: 768px)": {
        fontSize: "1rem",
      },
      "@media all and (max-width: 600px)": {
        fontSize: "1rem",
      },
    },
    subtitle2: {
      fontSize: "1.15rem",
      "@media (max-width: 768px)": {
        fontSize: "1rem",
      },
      "@media all and (max-width: 600px)": {
        fontSize: ".875rem",
      },
    },
  },

  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          "@media (max-width: 600px)": {
            maxWidth: "100%",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          "@media (max-width: 600px)": {
            fontSize: ".875rem",
            padding: "4px 10px",
          },
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#0D0D0D",
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {},
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
}
export default createTheme(theme)
