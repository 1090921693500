import { PATHS } from "../../constants/paths"
import { useDialog } from "../../context/DialogContext"
import { useAppSelector } from "../../store"
import { Logo } from "../Logo"
import { DepositDialog } from "../dialog/DepositDialog"
import { WithdrawalDialog } from "../dialog/WithdrawalDialog"
import { AccountMenu } from "./AccountMenu"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import DashboardIcon from "@mui/icons-material/Dashboard"
import Home from "@mui/icons-material/Home"
import PaymentIcon from "@mui/icons-material/Payment"
import SyncIcon from "@mui/icons-material/Sync"
import { Drawer, Toolbar, useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { styled } from "@mui/material/styles"
import React from "react"
import { NavLink } from "react-router-dom"

export const AccountSidebar = () => {
  const theme = useTheme()
  const user = useAppSelector((store) => store.user.user)

  const FireNav = styled(List)({
    "& .MuiListItemButton-root": {
      paddingLeft: 24,
      paddingRight: 24,
    },
    "& .MuiListItemIcon-root": {
      minWidth: 0,
      marginRight: 16,
    },
    "& .MuiSvgIcon-root": {
      fontSize: 22,
    },
  })

  const [openDepositDialog, closeDepositDialog] = useDialog()
  const [openWithdrawalDialog, closeWithdrawalDialog] = useDialog()

  const onOpenDepositDialog = (e) => {
    e.preventDefault()
    openDepositDialog({
      children: <DepositDialog closeDialog={closeDepositDialog} width={480} />,
    })
  }

  const onOpenWithdrawalDialog = (e) => {
    e.preventDefault()
    openWithdrawalDialog({
      children: (
        <WithdrawalDialog closeDialog={closeWithdrawalDialog} width={480} />
      ),
    })
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 220,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 220,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar sx={{ pt: 1, pb: 2, mx: "auto" }}>
        <Logo />
      </Toolbar>
      <Divider />
      <Box sx={{ display: "flex" }}>
        <FireNav component="nav" sx={{ maxWidth: 256 }}>
          <ListItem component="div" disablePadding>
            <ListItemButton
              sx={{ height: 48 }}
              component={NavLink}
              to={PATHS.ACCOUNT.PATH}
            >
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText
                primary={`${user.address.substring(
                  0,
                  3,
                )}...${user.address.slice(-4)}`}
                primaryTypographyProps={{
                  fontSize: 18,
                }}
              />
            </ListItemButton>
            {/*<Tooltip title='Settings'>*/}
            {/*  <IconButton*/}
            {/*    size='large'*/}
            {/*    sx={{*/}
            {/*      '& svg': {*/}
            {/*        color: 'rgba(255,255,255,0.8)', transition: '0.2s', transform: 'translateX(0) rotate(0)',*/}
            {/*      }, '&:hover, &:focus': {*/}
            {/*        bgcolor: 'unset', '& svg:first-of-type': {*/}
            {/*          transform: 'translateX(-4px) rotate(-20deg)',*/}
            {/*        }, '& svg:last-of-type': {*/}
            {/*          right: 0, opacity: 1,*/}
            {/*        },*/}
            {/*      }, '&:after': {*/}
            {/*        content: '""',*/}
            {/*        position: 'absolute',*/}
            {/*        height: '80%',*/}
            {/*        display: 'block',*/}
            {/*        left: 0,*/}
            {/*        width: '1px',*/}
            {/*        bgcolor: 'divider',*/}
            {/*      },*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <Settings/>*/}
            {/*    <ArrowRight sx={{position: 'absolute', right: 4, opacity: 0}}/>*/}
            {/*  </IconButton>*/}
            {/*</Tooltip>*/}
          </ListItem>
          <Divider />

          <AccountMenu
            title={"Account"}
            description={
              "Your Commanders, Turrets in the game, Boxes, Inventory"
            }
            menuList={[
              {
                PATH: PATHS.ACCOUNT.PATH,
                LABEL: PATHS.ACCOUNT.LABEL,
                ICON: <DashboardIcon />,
              },
              {
                PATH: PATHS.TRANSACTIONS.PATH,
                LABEL: PATHS.TRANSACTIONS.LABEL,
                ICON: <PaymentIcon />,
              },
            ]}
          />

          <Divider />

          <AccountMenu
            title={"Operations"}
            description={"Operations: Withdrawal, Deposit and etc."}
            menuList={[
              {
                PATH: "/withdrawal",
                LABEL: "Withdrawal",
                ICON: <ArrowDownwardIcon />,
                ACTION: onOpenWithdrawalDialog,
              },
              {
                PATH: "/deposit",
                LABEL: "Deposit",
                ICON: <ArrowUpwardIcon />,
                ACTION: onOpenDepositDialog,
              },
              {
                PATH: "/sync-game",
                LABEL: "Sync Game",
                ICON: <SyncIcon />,
                IS_DISABLED: true,
              },
            ]}
          />
        </FireNav>
      </Box>
    </Drawer>
  )
}
