import Box from "@mui/material/Box"
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress"
import Typography from "@mui/material/Typography"

export const LinearProgressStyled = ({ value, label = false, rarity }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Typography
        variant="body2"
        sx={{
          width: "100%",
          position: "absolute",
          bottom: "50%",
          left: "50%",
          transform: "translate(-50%, 50%)",
          zIndex: 1,
          textAlign: "center",
        }}
      >
        Time to next lvl: {label}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={value}
        className={rarity.toLowerCase()}
        sx={{
          height: 25,
          borderRadius: 30,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: "rgba(0, 0, 0, .35)",
          },
          [`&.common .${linearProgressClasses.bar}`]: {
            borderRadius: 30,
            background:
              "linear-gradient(90deg, #787878 0%, rgba(57,119,60,1) 100%)",
          },
          [`&.uncommon .${linearProgressClasses.bar}`]: {
            borderRadius: 30,
            background:
              "linear-gradient(90deg, #787878 0%, rgba(31, 104, 162, 1) 100%)",
          },
        }}
      />
    </Box>
  )
}
