import dividerPrimaryGradientUrl from "../assets/img/border/divider-primary-gradient-h.svg"
import artilleryCommanderBoxImg from "../assets/img/nft-boxes/artillery-commander-box.png"
import electromagnetCommanderBoxImg from "../assets/img/nft-boxes/electromagnet-commander-box.png"
import laserCommanderBoxImg from "../assets/img/nft-boxes/laser-commander-box.png"
// Static
import machineCommanderBoxImg from "../assets/img/nft-boxes/machine-commander-box.png"
import rocketeerCommanderBoxImg from "../assets/img/nft-boxes/rocketeer-commander-box.png"
import { AffiliateProgram } from "../components/AffiliateProgram"
import { LinearProgressStyled } from "../components/BorderLinearProgress"
import NftVideo from "../components/NftVideo"
import { TopReferrals } from "../components/TopReferrals"
import { entries } from "../data/entries"
import {
  Box,
  Chip,
  Container,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import React, { useState } from "react"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 18,
    "@media (max-width: 600px)": {
      fontSize: 16,
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 20,
    "@media (max-width: 600px)": {
      fontSize: 16,
    },
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    borderBottom: "1px solid transparent",
    borderImage:
      "url(static/media/divider-primary-gradient.65b426a8.svg) 1 stretch",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}))

const AmountOption = ({ value, active, clickHandler }) => {
  const isActive = active
    ? "merch_selector-amount qty-active"
    : "merch_selector-amount"
  return (
    <div className={isActive} onClick={() => clickHandler(value)}>
      {value}
    </div>
  )
}

export const NftBox = ({ title, img, alt }) => {
  const [options, setOptions] = useState("1")

  const handleClick = (event, newAlignment) => {
    setOptions(newAlignment)
  }

  return (
    <Tooltip title="Max quantity purchased/person: 5 Boxes" arrow>
      <Box
        textAlign="center"
        sx={{
          mb: {
            xss: 4,
            sm: 4,
            lg: 0,
          },
        }}
      >
        <Typography
          variant={"h4"}
          textAlign="center"
          sx={{
            fontSize: ".825rem",
            lineHeight: "2.5",
            mb: 2,
          }}
        >
          {title}
        </Typography>

        <Box
          textAlign="center"
          sx={{
            padding: "16px 0",
            background:
              "url(https://faraland.io/static/media/circle-highlight.a886adc7.png)",
            backgroundSize: "75%",
            backgroundPosition: "50% 50%",
            backgroundRepeat: "no-repeat",
            "& > img": {
              width: "180px",
            },
          }}
        >
          <img src={img} alt={alt} />
        </Box>

        <Typography variant={"body1"} gutterBottom>
          Available boxes: <span className="primary">800</span>
        </Typography>

        <Typography variant={"body1"} gutterBottom>
          Price per box: <span className="primary">TBA</span>
        </Typography>

        <Typography variant={"body1"} mb={2}>
          How many boxes do you want to buy?
        </Typography>

        <ToggleButtonGroup value={options} exclusive onChange={handleClick}>
          <ToggleButton value="1">1</ToggleButton>
          <ToggleButton value="2">2</ToggleButton>
          <ToggleButton value="3">3</ToggleButton>
          <ToggleButton value="4">4</ToggleButton>
          <ToggleButton value="5">5</ToggleButton>
        </ToggleButtonGroup>
        {/*{options &&*/}
        {/*  options.map((option) => (*/}
        {/*    <AmountOption*/}
        {/*      clickHandler={handleClick}*/}
        {/*      key={option.value}*/}
        {/*      value={option.value}*/}
        {/*      active={option.active}*/}
        {/*    />*/}
        {/*  ))}*/}
      </Box>
    </Tooltip>
  )
}

const RarityItem = ({ level, probability, amount }) => {
  return (
    <StyledTableRow>
      <StyledTableCell>
        <Chip label={level} className={`chip chip-${level.toLowerCase()}`} />
      </StyledTableCell>
      <StyledTableCell>
        <span>{probability}</span>
      </StyledTableCell>
      <StyledTableCell>
        <span>{amount}</span>
      </StyledTableCell>
    </StyledTableRow>
  )
}

const StyledChip = styled(Chip)(({ theme }) => ({
  padding: "2px 0",
  height: "auto",
  background: "rgba(0, 0, 0, .35)",
  "& > span": {
    fontSize: "16px",
  },
}))

export const CommanderComponent = ({
  image,
  video,
  id,
  lvl,
  bonus,
  rarity,
  progressValue,
  progressLabel,
}) => {
  const [videoTrigger, setVideoTrigger] = useState(false)

  return (
    <Box
      className="img_toggler"
      onMouseEnter={() => setVideoTrigger(true)}
      onMouseLeave={() => setVideoTrigger(false)}
      sx={{ position: "relative" }}
    >
      <img src={image} alt="tower" className="chest_tower img-static"></img>
      <NftVideo
        classes="chest_tower"
        videoUrl={video}
        poster={image}
        videoTrigger={videoTrigger}
      />

      <Box
        textAlign="center"
        mt={1}
        sx={{
          width: "100%",
          position: "absolute",
          top: "8px",
          padding: "0 12px 12px",
        }}
      >
        {id && (
          <Grid item>
            <StyledChip label={`ID: ${id}`} />
          </Grid>
        )}
      </Box>

      <Box
        textAlign="center"
        mt={1}
        sx={{
          width: "100%",
          position: "absolute",
          bottom: "8px",
          padding: "0 12px 12px",
        }}
      >
        <Grid container spacing={1}>
          {lvl && (
            <Grid item>
              <StyledChip label={`Lvl: ${lvl}`} />
            </Grid>
          )}

          {bonus && (
            <Grid item>
              <StyledChip label={`Bonus: ${bonus}`} />
            </Grid>
          )}

          {rarity && (
            <>
              <Grid item>
                <StyledChip label={rarity} />
              </Grid>

              {progressValue && progressLabel && (
                <Grid item xs={12}>
                  <LinearProgressStyled
                    value={progressValue}
                    label={progressLabel}
                    rarity={rarity}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
    </Box>
  )
}

export const NftBoxesCommanders = () => {
  return (
    <Box className="content content-nft-boxes content-nft-boxes-commanders">
      <Box className="content-head" mb={5} textAlign="center">
        <Container fixed>
          <Typography variant="h1">NFT Boxes with Commanders</Typography>
          <Typography
            variant="subtitle1"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Sales Starts In: TBA
          </Typography>
          {/*<Box sx={{display: 'flex', justifyContent: 'center', mt: 2}}>*/}
          {/*  <TimeLeft y={2022} m={10} d={10}/>*/}
          {/*</Box>*/}
        </Container>
      </Box>
      <Box className="content-body">
        <Container fixed>
          <Box>
            <Grid
              container
              spacing={2}
              sx={{
                "& > div:nth-child(even)": {
                  borderLeft: "1px solid transparent",
                  borderRight: "1px solid transparent",
                  borderImage: `url(${dividerPrimaryGradientUrl}) 1 stretch`,
                },
              }}
              justifyContent="center"
            >
              <Grid item xs={8} sm={6} md={4} lg>
                <NftBox
                  title="Machine Gunner Box"
                  img={machineCommanderBoxImg}
                  alt=""
                />
              </Grid>
              <Grid item xs={8} sm={6} md={4} lg>
                <NftBox
                  title="Rocketeer Box"
                  img={rocketeerCommanderBoxImg}
                  alt=""
                />
              </Grid>
              <Grid item xs={8} sm={6} md={4} lg>
                <NftBox
                  title="Laserwoman Box"
                  img={laserCommanderBoxImg}
                  alt=""
                />
              </Grid>
              <Grid item xs={8} sm={6} md={4} lg>
                <NftBox
                  title="Electromagnet Box"
                  img={electromagnetCommanderBoxImg}
                  alt=""
                />
              </Grid>
              <Grid item xs={8} sm={6} md={4} lg>
                <NftBox
                  title="Artilleryman Box"
                  img={artilleryCommanderBoxImg}
                  alt=""
                />
              </Grid>
            </Grid>

            <Box mt={4} mb={2} textAlign="right">
              <Typography>
                Total Price: <span className="primary">0</span>
              </Typography>
              <Typography>
                Total Boxes: <span className="primary">0</span>
              </Typography>
            </Box>

            <Box textAlign="right">
              <Tooltip title="Coming Soon...">
                <span>
                  <Button variant="contained" size="large" disabled>
                    Buy
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>

          <Box
            textAlign="center"
            sx={{
              mt: {
                xs: 4,
                sm: 6,
                md: 8,
              },
            }}
          >
            <Typography variant="h2" gutterBottom>
              Description
            </Typography>

            <Typography variant="subtitle1" textAlign={"center"} sx={{ mb: 3 }}>
              To play the mobile game Zombie TD, you will need NFT Commander.
            </Typography>

            <Typography variant="subtitle1" mb={2}>
              In the game, the Commander participates in battles and receives
              experience, gold, and rating points, regardless of whether you win
              or lose. With the receipt of each new level, the Commander is
              given three points to distribute and improve the following
              indicators:
            </Typography>

            <Grid container spacing={2} sx={{ mb: 4 }}>
              <Grid item sm={6}>
                <Paper sx={{ p: 1, display: "flex" }}>
                  <Typography variant="subtitle1" className={"primary"}>
                    +% to gold in the game
                  </Typography>
                </Paper>
              </Grid>
              <Grid item sm={6}>
                <Paper sx={{ p: 1, display: "flex" }}>
                  <Typography variant="subtitle1" className={"primary"}>
                    +% to the experience of the Commander
                  </Typography>
                </Paper>
              </Grid>
              <Grid item sm={6}>
                <Paper sx={{ p: 1, display: "flex" }}>
                  <Typography variant="subtitle1" className={"primary"}>
                    +% to the attack of all Turrets
                  </Typography>
                </Paper>
              </Grid>
              <Grid item sm={6}>
                <Paper sx={{ p: 1, display: "flex" }}>
                  <Typography variant="subtitle1" className={"primary"}>
                    +% token Staking
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <Typography variant="subtitle1" mb={2} textAlign={"center"}>
              The game has 5 types of NFTs of five specialized Commanders with 5
              rarities that increase the attack, damage radius, and ability
              effectiveness of one of the five Turrets:
            </Typography>

            <Grid container spacing={2} sx={{ mb: 4 }}>
              {entries.commanders.map((commander) => {
                return (
                  <>
                    {commander.id > 1 && (
                      <Grid item xs={6} sm={6} md={3} lg>
                        <Typography
                          variant="h4"
                          textAlign={"center"}
                          sx={{
                            my: 2,
                            fontSize: {
                              xs: 12,
                              md: 14,
                            },
                          }}
                        >
                          {commander.title}
                        </Typography>
                        <CommanderComponent
                          image={commander.image}
                          video={commander.video}
                        />
                        <Box textAlign={"center"}>
                          <Typography
                            variant="body1"
                            sx={{ color: "#EEE" }}
                            mt={1}
                          >
                            {commander.description}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                  </>
                )
              })}
            </Grid>
          </Box>

          <Box
            textAlign="center"
            sx={{
              mt: {
                xss: 2,
                xs: 5,
                sm: 6,
                md: 8,
                lg: 10,
              },
              mb: {
                lg: 5,
              },
            }}
          >
            <Typography variant="h2" mb={2}>
              5 types of NFT Commanders will be sold with all Rarity Levels
            </Typography>
            <Typography variant="subtitle1" mb={2}>
              There will be 5 types of Commander Boxes available for sale, each
              containing 1 random NFT Zombie TD Collections Commanders with
              different stats, types, and rarity. You can use these NFT to play
              the Zombie TD mobile game, sell them on the market, or keep them
              for your collection. The maximum number of Boxes of each type per
              1 account is 5 pieces. The first sale will be limited to 4,000
              Boxes. Users on the IDO Whitelist and participating in the IDO on
              our website receive a guaranteed allocation in the sale of
              Commander Boxes. 2 hours will be allocated for the redemption of
              the guaranteed allocation. After this time, the remaining
              Commander Boxes can be bought by everyone.
            </Typography>

            <Typography variant="subtitle1" mb={2}>
              <span className={"primary"}>NFT Boxes with Commanders</span> are
              an excellent way to test your luck on high-rarity NFT drops and
              prepare higher-rarity Commanders. The higher the Commander's
              rarity, the more it enhances the stats of your Turrets, which
              allows you to get to higher places in the ranking. Rare NFTs have
              a high value in the Marketplace.
            </Typography>

            <Typography variant="subtitle1">
              The higher the rarity of the NFT Commander, the lower the
              probability of its appearance.
            </Typography>
          </Box>

          <Box maxWidth={820} sx={{ margin: "auto" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Rarity</StyledTableCell>
                    <StyledTableCell>Probability</StyledTableCell>
                    <StyledTableCell>
                      Number of pieces per 4,000
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <RarityItem
                    level={"Common"}
                    probability={"60%"}
                    amount={"2,400"}
                  />
                  <RarityItem
                    level={"Uncommon"}
                    probability={"30%"}
                    amount={"1,200"}
                  />
                  <RarityItem
                    level={"Rare"}
                    probability={"7%"}
                    amount={"280"}
                  />
                  <RarityItem
                    level={"Epic"}
                    probability={"2.5%"}
                    amount={"100"}
                  />
                  <RarityItem
                    level={"Legendary"}
                    probability={"0.5%"}
                    amount={"20"}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <AffiliateProgram view="commanders" />

          <TopReferrals />
        </Container>
      </Box>
    </Box>
  )
}
