export const getWalletBalance = () =>
  new Promise(async (res, rej) => {
    try {
      const account = await window.ethereum.request({
        method: "eth_requestAccounts",
      })
      await window.ethereum
        .request({ method: "eth_getBalance", params: [account[0], "latest"] })
        .then((result) => {
          // console.log(parseInt(result,16)/ (10 ** 18));
          res(parseInt(result, 16) / 10 ** 18)
        })
    } catch (e) {
      rej(e.message)
    }
  })
