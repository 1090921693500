import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material"
import { styled } from "@mui/material/styles"
import React from "react"

const StyledAvatar = styled(Avatar)(
  ({ theme }) => `
  ${theme.breakpoints.up("xs")} {
    width: 24px;
    height: 24px;
  }
  ${theme.breakpoints.up("md")} {
    width: 30px;
    height: 30px;
  }
  ${theme.breakpoints.up("lg")} {
    width: 40px;
    height: 40px;
  }
`,
)

export const ListItemStyled = ({
  icon,
  primaryText,
  secondaryText,
  ...props
}) => {
  return (
    <ListItem
      {...props}
      sx={{
        mb: 2,
        "&.gray": {
          background: "rgba(117,117,117,0.15)",
          border: "1px solid rgba(117,117,117,0.15)",
          "& .MuiAvatar-root": {
            background: "#757575",
          },
        },
        "&.green": {
          background: "rgba(57,119,60,0.15)",
          border: "1px solid rgba(57,119,60,0.15)",
          "& .MuiAvatar-root": {
            background: "#39773C",
          },
        },
        "&.blue": {
          background: "rgba(31,104,162,0.15)",
          border: "1px solid rgba(31,104,162,0.15)",
          "& .MuiAvatar-root": {
            background: "#1F68A2",
          },
        },
        "&.purple": {
          background: "rgba(74,46,124,0.15)",
          border: "1px solid rgba(74,46,124,0.15)",
          "& .MuiAvatar-root": {
            background: "#4A2E7C",
          },
        },
        borderRadius: 2,
      }}
    >
      <ListItemAvatar
        sx={{
          minWidth: {
            xs: "36px",
            md: "44px",
            lg: "56px",
          },
        }}
      >
        {icon && <StyledAvatar>{icon}</StyledAvatar>}
      </ListItemAvatar>
      <ListItemText
        primary={primaryText || ""}
        secondary={secondaryText || ""}
      />
    </ListItem>
  )
}
