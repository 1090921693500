import { useDialog } from "../../context/DialogContext"
import { useAppDispatch, useAppSelector } from "../../store"
import { connectHandler } from "../../utils/connectHandler"
import { BuyDialog } from "../dialog/BuyDialog"
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress"
import { styled } from "@mui/material/styles"
import React from "react"

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}))

export const IDOSecondStep = () => {
  const dispatch = useAppDispatch()
  const { address } = useAppSelector((state) => state.user.user)

  const getWallet = () => {
    connectHandler(dispatch)
  }

  const [openDialog, closeDialog] = useDialog()

  const onOpenBuyDialog = () => {
    openDialog({
      children: <BuyDialog closeDialog={closeDialog} width={480} />,
    })
  }

  return (
    <Stack spacing={2}>
      <Box>
        <Typography className="primary" sx={{ mb: 1, fontSize: 20 }}>
          Total Sold
        </Typography>

        <Typography gutterBottom>
          0.00 <span>ZTD</span>
        </Typography>

        <Typography gutterBottom>
          Progress: <span>0%</span>
        </Typography>

        <BorderLinearProgress variant="determinate" value={0} />
      </Box>

      <Box>
        <Typography className="primary" sx={{ mb: 1, fontSize: 20 }}>
          Buy ZTD
        </Typography>

        <Grid container alignItems="center" sx={{ mb: 2 }} spacing={2}>
          <Grid item md={9}>
            <TextField
              label="BUSD (Min 100 / Max 500)"
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">BUSD</InputAdornment>
                ),
                inputProps: {
                  max: 200,
                  min: 100,
                },
              }}
            />
          </Grid>
          <Grid item md={3}>
            <Typography variant="h4">= 0 ZTD</Typography>
          </Grid>
        </Grid>

        <Stack direction="row" spacing={2}>
          <Typography gutterBottom>
            Allocation: <span className="primary">0/500 BUSD</span>
          </Typography>
        </Stack>

        {address ? (
          <Button variant="contained" sx={{ mt: 1 }} onClick={onOpenBuyDialog}>
            Buy
          </Button>
        ) : (
          <>
            <Typography sx={{ mb: 1 }}>
              Please connect your wallet to check its whitelisted
            </Typography>
            <Button variant="contained" onClick={getWallet}>
              Connect Wallet
            </Button>
          </>
        )}
      </Box>
    </Stack>
  )
}
