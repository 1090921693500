import dividerPrimaryGradientUrl from "../assets/img/border/divider-primary-gradient.svg"
import newItem from "../assets/img/staking_items-new.png"
import { StakingNftCard } from "../components/card/StakingNftCard"
import { CardActionArea, Chip, Container, Grid, Tooltip } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import React, { useRef, useState } from "react"
import PreviewNFTCard from "../components/card/PreviewNFTCard"
import { useAppSelector } from "../store"

export const StakingNftPage = () => {
  const [activeStacking, setActiveStacking] = useState("nft-stacking")

  const nftStacking = activeStacking === "nft-stacking" ? <NFTStaking /> : null
  return <>{nftStacking}</>
}

const NFTStaking = () => {
  const commanders = useAppSelector((state) => state.market.commanders)
  const turrets = useAppSelector((state) => state.market.turrets)

  const inventoryRef = useRef(null)
  const scrollToInventory = () => {
    inventoryRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const obj = [
    {
      type: <Chip label="Common" className="chip chip-common" sx={{ m: 0 }} />,
      value: "+5%",
    },
    {
      type: (
        <Chip label="Uncommon" className="chip chip-uncommon" sx={{ m: 0 }} />
      ),
      value: "+10%",
    },
    {
      type: <Chip label="Rare" className="chip chip-rare" sx={{ m: 0 }} />,
      value: "+30%",
    },
    {
      type: <Chip label="Epic" className="chip chip-epic" sx={{ m: 0 }} />,
      value: "+70%",
    },
    {
      type: (
        <Chip label="Legendary" className="chip chip-legendary" sx={{ m: 0 }} />
      ),
      value: "+200%",
    },
  ]

  return (
    <Box className="content">
      <Box className="content-head">
        <Container fixed>
          <Typography
            variant="h1"
            textAlign="center"
            sx={{
              mb: 2,
            }}
          >
            STAKING NFT
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              my: 2,
              pl: 2,
              borderLeft: "1px solid transparent",
              borderImage: `url(${dividerPrimaryGradientUrl}) 1 stretch`,
            }}
          >
            By Staking NFT, you add a percentage to the ZTD token's Staking and
            increase the level of staked NFT. The higher the rarity of the NFT,
            the greater the additional rate to the Staking of the token.
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              my: 2,
              pl: 2,
              borderLeft: "1px solid transparent",
              borderImage: `url(${dividerPrimaryGradientUrl}) 1 stretch`,
            }}
          >
            You can stake up to 20 NFTs at a time and remove them from the stake
            at any time. If you remove NFT from Staking, the current level's
            progress is canceled, and additional interest for Staking is also
            balanced.
          </Typography>
        </Container>
      </Box>

      <Box className="content-body">
        <Container fixed>
          <Box sx={{ margin: "auto", mt: 4 }}>
            <Grid container spacing={[4]} justifyContent="space-between">
              <Grid item md={6}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={3}>
                      <Grid item xs={5} sm={4}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            background:
                              "-webkit-linear-gradient(#e8c582, #a16929)",
                            backgroundClip: "text",
                            textFillColor: "transparent",
                            fontWeight: 500,
                            pl: 2,
                          }}
                        >
                          Rarity
                        </Typography>
                      </Grid>
                      <Grid item xs={7} sm={8}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            background:
                              "-webkit-linear-gradient(#e8c582, #a16929)",
                            backgroundClip: "text",
                            textFillColor: "transparent",
                            fontWeight: 500,
                            textAlign: {
                              xs: "center",
                              md: "left",
                            },
                          }}
                        >
                          Additional percentage to Staking token
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {obj.map((item) => (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          p: 2,
                          background: "rgba(255, 255, 255, .1)",
                          borderRadius: "16px",
                        }}
                      >
                        <Grid container alignItems="center" spacing={3}>
                          <Grid item xs={6} sm={4}>
                            <Typography variant="subtitle1">
                              {item.type}
                            </Typography>
                          </Grid>
                          <Grid item xs={5} sm={7}>
                            <Typography variant="subtitle1" textAlign="center">
                              {item.value}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={12} md={4}>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={6} sm={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        background: "-webkit-linear-gradient(#e8c582, #a16929)",
                        backgroundClip: "text",
                        textFillColor: "transparent",
                        fontWeight: 500,
                        pl: 2,
                      }}
                    >
                      Total:
                    </Typography>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    mt: 2,
                    p: 2,
                    background: "rgba(255, 255, 255, .1)",
                    borderRadius: "16px",
                  }}
                >
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={5}>
                      <Typography variant="subtitle1">Staking NTF:</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          textAlign: {
                            xs: "center",
                            md: "left",
                          },
                        }}
                      >
                        0/1
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Box
                  sx={{
                    my: 2,
                    p: 2,
                    background: "rgba(255, 255, 255, .1)",
                    borderRadius: "16px",
                  }}
                >
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={5}>
                      <Typography variant="subtitle1">Summary:</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography
                        sx={{
                          textAlign: {
                            xs: "center",
                            md: "left",
                          },
                        }}
                      >
                        0%
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              mt: {
                xs: 4,
                lg: 10,
              },
            }}
          >
            <Typography
              variant="h2"
              textAlign="center"
              sx={{
                mb: {
                  xs: 2,
                  lg: 5,
                },
              }}
            >
              Staking
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {turrets.map((turret) => {
                  return (
                    <>
                      {turret.id === 1 && (
                        <Box>
                          <Typography
                            variant="h4"
                            textAlign="center"
                            sx={{
                              mb: 2,
                              fontSize: {
                                xs: 12,
                                md: 14,
                              },
                            }}
                          >
                            {turret.name}
                          </Typography>
                          <PreviewNFTCard
                            id={turret.id}
                            imageUrl={turret.image}
                            videoUrl={turret.video}
                            lvl={turret.level}
                            bonus={turret.bonus}
                            rarity={turret.rarity}
                            progressValue="85"
                            progressLabel="1d. 2h. 15m."
                          />
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Tooltip title="Coming soon...">
                              <div>
                                <Button
                                  variant="contained"
                                  size="small"
                                  fullWidth
                                  disabled
                                >
                                  Unstake
                                </Button>
                              </div>
                            </Tooltip>
                          </Box>
                        </Box>
                      )}
                    </>
                  )
                })}
              </Grid>
              {/*<Grid item xs={12} sm={6} md={4} lg={3}>*/}
              {/*  <StakingNftCard*/}
              {/*    type="staking"*/}
              {/*    lvl="1"*/}
              {/*    rarity="Uncommon"*/}
              {/*    bonus="30%"*/}
              {/*    progressValue={"50"}*/}
              {/*    progressLabel={"1d. 15h. 30m."}*/}
              {/*  />*/}
              {/*</Grid>*/}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box>
                  <CardActionArea
                    sx={{
                      display: "block",
                      mt: 6.75,
                    }}
                    onClick={scrollToInventory}
                  >
                    <img
                      src={newItem}
                      alt="Add new"
                      style={{ width: "100%" }}
                    />
                  </CardActionArea>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              mt: {
                xs: 5,
                lg: 10,
              },
            }}
          >
            <Typography
              variant="h2"
              textAlign="center"
              sx={{
                mb: {
                  xs: 2,
                  lg: 5,
                },
              }}
            >
              My Inventory
            </Typography>

            <Grid container spacing={3} ref={inventoryRef}>
              {commanders.map((commander) => {
                return (
                  <>
                    {commander.id === 1 && (
                      <Grid item sm={10} md={6} lg={3}>
                        <Box>
                          <Typography
                            variant="h4"
                            textAlign="center"
                            sx={{
                              mb: 2,
                              fontSize: {
                                xs: 12,
                                md: 14,
                              },
                            }}
                          >
                            {commander.name}
                          </Typography>
                          <PreviewNFTCard
                            id={commander.id}
                            imageUrl={commander.image}
                            videoUrl={commander.video}
                            lvl={commander.level}
                            bonus={commander.bonus}
                            rarity={commander.rarity}
                          />
                          <Box
                            sx={{
                              mt: 1,
                            }}
                          >
                            <Tooltip title="Coming soon...">
                              <div>
                                <Button
                                  variant="contained"
                                  size="small"
                                  fullWidth
                                  disabled
                                >
                                  Add to staking
                                </Button>
                              </div>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </>
                )
              })}
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
