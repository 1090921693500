import AppRouter from "./AppRouter"
import "./assets/sass/style.scss"
import ScrollToTop from "./components/ScrollToTop"
import { AgreementsDialog } from "./components/dialog/AgreementsDialog"
import { Footer } from "./components/layout/Footer"
import { Header } from "./components/layout/Header"
import { SubHeader } from "./components/layout/SubHeader"
import DialogProvider from "./context/DialogContext"
import theme from "./theme/theme"
import { ThemeProvider } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { SnackbarProvider } from "notistack"

const App = () => {
  const location = useLocation()

  const [showCookieDialog, setShowCookieDialog] = useState(false)

  const handleClose = (e, reason) => {
    if (reason && reason === "backdropClick") return

    setShowCookieDialog(false)
    localStorage.setItem("cookieAgreements", true)
  }

  useEffect(() => {
    if (localStorage.getItem("cookieAgreements") === null) {
      setShowCookieDialog(true)
    }
    if (location.pathname === "/privacy-policy") {
      setShowCookieDialog(false)
    }
  }, [localStorage.getItem("cookieAgreements"), location.pathname])

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <DialogProvider>
          <ScrollToTop />
          <div className="wrapper">
            <SubHeader />
            <Header />
            <div id={"main"} className="main">
              <AppRouter />
            </div>
            <Footer />

            {showCookieDialog && (
              <AgreementsDialog
                openDialog={showCookieDialog}
                onCloseDialog={handleClose}
              />
            )}
          </div>
        </DialogProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
