import baseCommanderImg from "../assets/img/commanders/baseCommander/baseCommanderWhite.png"
import baseCommanderVideo from "../assets/video/commanders/baseCommander/baseCommander.mp4"

export const commanders = [
  {
    id: 1,
    name: "Base Commander",
    price: 700,
    image: baseCommanderImg,
    videoUrl: baseCommanderVideo,
    poster: baseCommanderImg,
    bonus: 5,
    type: "artillery",
    rarity: "common",
    level: 1,
    status: "mining",
    baseDamage: "50",
    actualDamage: "267",
    attackSpeed: "slow",
    attackType: "single",
    ability:
      "deals damage to the closest single target with a high attack speed",
  },
]
