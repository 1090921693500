import { AccountSidebar } from "../account/AccountSidebar"
import Box from "@mui/material/Box"
import React from "react"
import { Outlet } from "react-router-dom"

export const AccountLayout = () => {
  const drawerWidth = 260

  return (
    <Box pt={12} pb={4} sx={{ minHeight: "100vh", display: "flex" }}>
      <AccountSidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          maxWidth: "xl",
          mx: "auto",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}
