import { StyledTableCell } from "../pages/NftBoxesTurretsPage"
import { Box } from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import React from "react"

export const TopReferrals = () => {
  return (
    <Box
      sx={{
        mt: {
          xs: 4,
          sm: 6,
          md: 8,
        },
      }}
    >
      <Typography variant="h2" textAlign="center" gutterBottom>
        Top 50 Referrals
      </Typography>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Place</StyledTableCell>
                <StyledTableCell>Successful referrals</StyledTableCell>
                <StyledTableCell>Address</StyledTableCell>
                <StyledTableCell>Prize</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  <Typography>Not found.</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}
