// Turrets
// All images
// Partners
import gotbitLogo from "../assets/img/partners/gotbit.svg"
import unrealEngineLogo from "../assets/img/partners/unreal.png"
import artilleryTurretImg_1 from "../assets/img/turrets/artillery/artilleryWhite.webp"
import radarTurretImg_1 from "../assets/img/turrets/electromagnetic/electromagneticWhite.webp"
import electromagneticTurretImg_1 from "../assets/img/turrets/laser/laserWhite.webp"
import machineGunTurretImg_1 from "../assets/img/turrets/machineGun/machineGunWhite.webp"
import rocketImg_1 from "../assets/img/turrets/rocket/rocketWhite.webp"
// Zombies
import lizardZombie from "../assets/img/zombies/_monster_1.png"
import mutantZombie from "../assets/img/zombies/_monster_2.png"
import grasshopperZombie from "../assets/img/zombies/_monster_3.png"
import insectDogZombie from "../assets/img/zombies/_monster_4.png"
import abominationZombie from "../assets/img/zombies/_monster_5.png"
import ogreZombie from "../assets/img/zombies/_monster_6.png"
import ElectromagnetCommanderVideo from "../assets/video/commanders/electroMagnet/electroMagnetWhite.mp4"
import LaserCommanderVideo from "../assets/video/commanders/laserWoman/laserWomanWhite.mp4"
import ArtilleryCommanderVideo from "../assets/video/commanders/artilleryMan/artilleryManWhite.mp4"
import RocketeerCommanderVideo from "../assets/video/commanders/rocketeer/rocketeerWhite.mp4"
// Commanders
import DefaultCommanderVideo from "../assets/video/commanders/baseCommander/baseCommander.mp4"
import MachineGunnerCommanderVideo from "../assets/video/commanders/machineGunner/machineGunnerWhite.mp4"
import ArtilleryCommanderImg from "../assets/img/commanders/artilleryMan/artilleryManWhite.png"
import RocketeerCommanderImg from "../assets/img/commanders/rocketeer/rocketeerWhite.png"
import DefaultCommanderImg from "../assets/img/commanders/baseCommander/baseCommanderWhite.png"
import MachineGunnerCommanderImg from "../assets/img/commanders/machineGunner/machineGunnerWhite.png"
import ElectromagnetCommanderImg from "../assets/img/commanders/electroMagnet/electroMagnetWhite.jpg"
import LaserCommanderImg from "../assets/img/commanders/laserWoman/laserWomanWhite.png"
import artilleryTurretVideo_1 from "../assets/video/turrets/artillery/artilleryWhite.mp4"
// All videos
import radarTurretVideo_1 from "../assets/video/turrets/electromagnetic/electromagneticWhite.mp4"
import electromagneticTurretVideo_1 from "../assets/video/turrets/laser/laserWhite.mp4"
import machineGunTurretVideo_1 from "../assets/video/turrets/machineGun/machineGunWhite.mp4"
import rocketVideo_1 from "../assets/video/turrets/rocket/rocketWhite.mp4"
// Icons
import {
  GiAutogun,
  GiCyborgFace,
  GiDuration,
  GiGamepadCross,
  GiMissileSwarm,
  GiRockGolem,
  GiSupersonicBullet,
  GiUpgrade,
  GiWorld,
} from "react-icons/gi"

export const entries = {
  turrets2: [
    {
      id: 1,
      name: "",
      title: "Machine Gun",
      description:
        "Machine Gun Turret - deals damage to the nearest single target with a high attack speed. Effective against weak zombies.",
      image: machineGunTurretImg_1,
      video: machineGunTurretVideo_1,
    },
    {
      id: 2,
      name: "",
      title: "rocket",
      description:
        "rocket Turret - deals massive damage with an average attack speed over the area. Effective against groups of zombies.",
      image: rocketImg_1,
      video: rocketVideo_1,
    },
    {
      id: 3,
      name: "",
      title: "Laser",
      description:
        "Laser Turret - deals damage to the nearest single target and is the most effective cannon against Bosses. In the process of a continuous attack, an increase in injury occurs.",
      image: electromagneticTurretImg_1,
      video: electromagneticTurretVideo_1,
    },
    {
      id: 4,
      name: "",
      title: "Electromagnetic",
      description:
        "Electromagnetic Turret - deals minor massive damage while slowing down the speed of zombies.",
      image: radarTurretImg_1,
      video: radarTurretVideo_1,
    },
    {
      id: 5,
      name: "",
      title: "Artillery",
      description:
        "Artillery Turret - deals severe damage to the nearest target while stunning it for a specific time.",
      image: artilleryTurretImg_1,
      video: artilleryTurretVideo_1,
    },
  ],

  commanders: [
    {
      id: 1,
      name: "",
      title: "Base Commander",
      description: "Is the default Commander, which does not have the ability.",
      image: DefaultCommanderImg,
      video: DefaultCommanderVideo,
    },
    {
      id: 2,
      name: "",
      title: "Machine gunner",
      description:
        "Increases damage, radius, and attack speed, and also reduces the time spent on improving the Machine Gun.",
      image: MachineGunnerCommanderImg,
      video: MachineGunnerCommanderVideo,
    },
    {
      id: 3,
      name: "",
      title: "Rocketeer",
      description:
        "Increases the damage, radius of destruction, and area of mass attack of the rocket Turret.",
      image: RocketeerCommanderImg,
      video: RocketeerCommanderVideo,
    },
    {
      id: 4,
      name: "",
      title: "Laserwoman",
      description:
        "Increases damage, the radius of destruction, and acceleration of the speed of damage increase.",
      image: LaserCommanderImg,
      video: LaserCommanderVideo,
    },
    {
      id: 5,
      name: "",
      title: "Electromagnet",
      description:
        "Increases damage, radius, and the Electromagnetic Turret's slowdown effect.",
      image: ElectromagnetCommanderImg,
      video: ElectromagnetCommanderVideo,
    },
    {
      id: 6,
      name: "",
      title: "Artilleryman",
      description:
        "increases the damage, radius of destruction, and the duration of the stun for an Artillery Turret.",
      image: ArtilleryCommanderImg,
      video: ArtilleryCommanderVideo,
    },
  ],
}

export const features = [
  {
    image: <GiWorld style={{ fill: "url(#primary-gradient)" }} />,
    title: "",
    subtitle: "101 Campaign levels with 5 difficulty levels",
    description: "",
    className: "",
  },
  {
    image: <GiAutogun style={{ fill: "url(#primary-gradient)" }} />,
    title: "",
    subtitle: "5 types of Turrets with 5 rarities",
    description: "",
    className: "",
  },
  {
    image: <GiGamepadCross style={{ fill: "url(#primary-gradient)" }} />,
    title: "",
    subtitle: (
      <span>
        3 game modes: Single Campaign, Competitive Endless and PvP modes.{" "}
        <span className="primary">Fight for ZTD token!</span>
      </span>
    ),
    description: "",
    className: "",
  },
  {
    image: <GiCyborgFace style={{ fill: "url(#primary-gradient)" }} />,
    title: "",
    subtitle: "6 types of Commanders with 5 rarities",
    description: "",
    className: "",
  },
  {
    image: <GiRockGolem style={{ fill: "url(#primary-gradient)" }} />,
    title: "",
    subtitle: "23 zombies, including 8 Bosses",
    description: "",
    className: "",
  },
]

export const advantages = [
  {
    image: "",
    title: "Rejection of investors",
    subtitle: "",
    description:
      "The main advantage is the absence of a pre-sale of tokens to early investors, which gives the project flexibility and independence. Investors do not own most of the tokens and thus do not have the opportunity to put pressure on the team and the token's price in their commercial interests. Therefore, Users who participated in ido will be the first owners of the token and are the only ones who can influence its development.",
  },
  {
    image: "",
    title: "Interesting game",
    subtitle: "",
    description:
      "We are developing a full-fledged mobile game in the Tower Defense genre with good graphics, exciting and addictive gameplay, and not a trivial browser-based one-button game (clicker) as most projects with the Play to Earn concept do now.",
  },
  {
    image: "",
    title: "Project readiness",
    subtitle: "",
    description:
      "Our game is already 90% ready, unlike most projects that go to ido with the concept of Play-to-Earn. Other projects first try to raise money, then start or don't start developing the game.",
  },
  {
    image: "",
    title: "It is placing the game on Google Play and the App Store",
    subtitle: "",
    description:
      "The game will be available on Google Play and App Store, unlike most projects that provide only Android Package files for the game. The application will be synchronized with the website, and authorization will take place through the Metamask wallet.",
  },
  {
    image: "",
    title: "Extensive experience in projects with the concept of Play-to-Earn",
    subtitle: "",
    description:
      "Our team has collected and processed all kinds of information by participating in projects, studying them, and communicating with the players. Our team identified the advantages and disadvantages of various projects. In Zombie TD, we have integrated only the strengths of the projects under study.",
  },
  {
    image: "",
    title: "No commissions",
    subtitle: "",
    description:
      "There are no in-game commissions in Zombie TD. The commission is charged only in the blockchain network for depositing and withdrawing the ZTD token.",
  },
]

export const economy = [
  {
    image: "",
    title: "Rejection of investors",
    subtitle: "",
    description:
      "Investors and investment funds have repeatedly approached us with proposals to invest in our project. However, we are against investors because they will have a dominant part of the tokens. Thus they can speculate in the market and will also dictate their terms for the project's development. The rejection of investors gives the project stability and independence, and the players are guaranteed the absence of speculation in the market.",
  },
  {
    image: "",
    title: "The mechanism of burning ZTD tokens with limited emission",
    subtitle: "",
    description:
      "Burning tokens will create scarcity by reducing the issuance of the token, increasing its value both in the market and in the Zombie TD game. The burning algorithm is as follows: all tokens received from the commission on the market, as well as spent in the game when buying inventory or to speed up the leveling of towers, will be burned every week on Monday, followed by the publication of a burning report on social networks.",
  },
  {
    image: "",
    title: "Staking & Staking NFT",
    subtitle: "",
    description:
      "The developed functionality of Token Staking provides the User with additional income in ZTD tokens, and NFT Staking adds interest to the token Staking and upgrades staked NFT.",
  },
  {
    image: "",
    title: "Own NFT Marketplace on the ZTD token",
    subtitle: "",
    description:
      "The ZTD token is the main currency of Zombie TD, and an even more significant increase in demand for it guarantees the need to use it when buying and selling NFT on the Marketplace on the Site.",
  },
  {
    image: "",
    title: "Aggressive marketing",
    subtitle: "",
    description:
      "We will use aggressive marketing to attract new Users and contain the maximum number of existing Users. And this, in turn, will lead to demand for ZTD tokens and NFT Zombie TD Collections.",
  },
]

export const zombies = [
  {
    image: lizardZombie,
    alt: "",
    title: "Lizard",
    subtitle: "",
    description: "",
  },
  {
    image: mutantZombie,
    alt: "",
    title: "Mutant",
    subtitle: "",
    description: "",
  },
  {
    image: grasshopperZombie,
    alt: "",
    title: "Grasshopper",
    subtitle: "",
    description: "",
  },
  {
    image: insectDogZombie,
    alt: "",
    title: "Insect Dog",
    subtitle: "",
    description: "",
  },
  {
    image: abominationZombie,
    alt: "",
    title: "Abomination",
    subtitle: "",
    description: "",
  },
  {
    image: ogreZombie,
    alt: "",
    title: "Ogre",
    subtitle: "",
    description: "",
  },
]

export const gameplay = [
  {
    image: <GiMissileSwarm style={{ fill: "url(#primary-gradient)" }} />,
    title: "Farming",
    subtitle: "",
    description: "Stake and get tokens. Farm and get NFT",
    className: "",
  },
  {
    image: <GiDuration style={{ fill: "url(#primary-gradient)" }} />,
    title: "Endless mode",
    subtitle: "",
    description:
      "Participate in tournaments, defeat other players and collect rewards",
    className: "",
  },
  {
    image: <GiWorld style={{ fill: "url(#primary-gradient)" }} />,
    title: "Campaign",
    subtitle: "",
    description: "Liberate territories from zombies and get ZTD tokens for it",
    className: "",
  },
  {
    image: <GiUpgrade style={{ fill: "url(#primary-gradient)" }} />,
    title: "NFT Upgrade",
    subtitle: "",
    description: "Upgrade your NFT while playing, increasing their value",
    className: "",
  },
  {
    image: <GiSupersonicBullet style={{ fill: "url(#primary-gradient)" }} />,
    title: "PvP",
    subtitle: "",
    description: "Fight other players for the ZTD token.",
    className: "",
  },
]

export const partners = [
  // {
  //   image: binanceLogo,
  //   alt: "Binance",
  //   className: "",
  // },
  // {
  //   image: coinGeckoLogo,
  //   alt: "CoinGecko",
  //   className: "",
  // },
  // {
  //   image: coinMarketCapLogo,
  //   alt: "CoinMarketCap",
  //   className: "",
  // },
  // {
  //   image: pancakeSwapLogo,
  //   alt: "PancakeSwap",
  //   className: "",
  // },
  {
    image: unrealEngineLogo,
    alt: "UnrealEngine",
    className: "",
  },
  {
    image: gotbitLogo,
    alt: "gotbit",
    className: "",
  },
]
