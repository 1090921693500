import appSlice from "./appReducer"
import boxSlice from "./boxReducer"
import marketSlice from "./marketReducer"
import userSlice from "./userReducer"
import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { useDispatch, useSelector } from "react-redux"

const rootReducer = combineReducers({
  app: appSlice,
  user: userSlice,
  market: marketSlice,
  boxes: boxSlice,
})

export const store = configureStore({
  reducer: rootReducer,
})

export const useAppDispatch = () => useDispatch()
export const useAppSelector = useSelector
