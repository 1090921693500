import { DialogActions, Stack, TextField } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import { enqueueSnackbar } from "notistack"
import { useState } from "react"

const initFormValues = {
  amountTokens: "",
  bepAddress: "",
}
export const WithdrawalDialog = ({ closeDialog, width }) => {
  const [form, setForm] = useState(initFormValues)

  const onWithdraw = () => {
    if (form.amountTokens && form.bepAddress) {
      setForm(initFormValues)
      closeDialog()
      enqueueSnackbar("Operation completed successfully", {
        variant: "success",
      })
    } else {
      enqueueSnackbar("All fields must be filled", {
        variant: "error",
      })
    }
  }

  return (
    <Box
      sx={{
        width,
        background: "#121212",
        textAlign: "center",
      }}
    >
      <DialogTitle sx={{ fontSize: "1rem" }}>Withdrawal</DialogTitle>
      <DialogContent sx={{ pt: 2, pb: 0 }}>
        <Typography mb={2}>
          Enter the amount in ZTD tokens you want to withdraw and your BEP20
          address
        </Typography>
        <Stack spacing={2}>
          <TextField
            id="amount-tokens"
            value={form.amountTokens}
            onChange={(e) =>
              setForm((prevState) => ({
                ...prevState,
                amountTokens: e.target.value,
              }))
            }
            label="Enter the amount in ZTD tokens"
            variant="outlined"
            size={"small"}
            fullWidth
            type={"number"}
            shrink
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              min: 1,
            }}
          />
          <TextField
            id="bep-address"
            value={form.bepAddress}
            onChange={(e) =>
              setForm((prevState) => ({
                ...prevState,
                bepAddress: e.target.value,
              }))
            }
            label="Enter BEP20 address"
            variant="outlined"
            size={"small"}
            fullWidth
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          color="primary"
          variant={"contained"}
          onClick={onWithdraw}
          fullWidth
          sx={{ fontFamily: "RUSBoycott, sans-serif" }}
        >
          Withdraw
        </Button>
      </DialogActions>
    </Box>
  )
}
