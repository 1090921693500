import { AccountLayout } from "./components/layout/AccountLayout"
import { appRoutes } from "./constants/routes"
import { useAppDispatch, useAppSelector } from "./store"
import { getAllItems } from "./store/thunks/marketThunks"
import { getInventarCards } from "./store/thunks/userThunks"
import { setWalletBalance } from "./store/userReducer"
import { getWalletBalance } from "./utils/metamask/getWalletBalance"
import React, { useEffect } from "react"
import { Route, Routes } from "react-router-dom"

const AppRouter = () => {
  const dispatch = useAppDispatch()
  const isAuth = useAppSelector((state) => state.user.isAuth)

  useEffect(() => {
    if (isAuth) {
      dispatch(getAllItems(1, { rarity: 0, lvl: 0, price: 0 }))
      dispatch(getInventarCards())
      getWalletBalance().then((balance) => {
        dispatch(setWalletBalance(balance))
      })
    }
  }, [isAuth])

  return (
    <Routes>
      {appRoutes.map(({ path, Element, isActive, type }, index) => (
        <React.Fragment key={index}>
          {type === "public" && (
            <Route
              key={path}
              path={path}
              element={
                <div
                  className={`page ${path.replace("/", "")}-page ${
                    !isActive ? "unavailable-page" : ""
                  }`}
                >
                  <Element />
                </div>
              }
            />
          )}
        </React.Fragment>
      ))}
      {isAuth && (
        <Route element={<AccountLayout />}>
          {appRoutes.map(({ path, Element, isActive, type }, index) => (
            <React.Fragment key={index}>
              {type === "private" && (
                <Route
                  key={path}
                  path={path}
                  element={
                    <div
                      className={`page ${path.replace("/", "")}-page ${
                        !isActive ? "unavailable-page" : ""
                      }`}
                    >
                      <Element />
                    </div>
                  }
                />
              )}
            </React.Fragment>
          ))}
        </Route>
      )}
    </Routes>
  )
}

export default AppRouter
