import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
// icons
import FilterListIcon from "@mui/icons-material/FilterList"
import { Chip, Stack, TextField, useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControlLabel from "@mui/material/FormControlLabel"
import IconButton from "@mui/material/IconButton"
import Paper from "@mui/material/Paper"
import Switch from "@mui/material/Switch"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Toolbar from "@mui/material/Toolbar"
import Tooltip from "@mui/material/Tooltip"
// @mui
import { visuallyHidden } from "@mui/utils"
import * as React from "react"
import { useState } from "react"

function createData(id, amount, type, createdAt) {
  return { id, amount, type, createdAt }
}

const rows = [
  createData("1", "10 ZTD", "deposit", "Sep 25th 22"),
  createData("2", "20 ZTD", "withdrawal", "Sep 24th 22"),
  createData("3", "10 ZTD", "deposit", "Sep 23th 22"),
  createData("4", "540 ZTD", "deposit", "Sep 22th 22"),
  createData("5", "20 ZTD", "deposit", "Sep 21th 22"),
  createData("6", "410 ZTD", "deposit", "Sep 20th 22"),
  createData("7", "20 ZTD", "withdrawal", "Sep 19th 22"),
  createData("8", "10 ZTD", "deposit", "Sep 18th 22"),
  createData("9", "50 ZTD", "deposit", "Sep 17th 22"),
  createData("10", "15 ZTD", "withdrawal", "Sep 16th 22"),
  createData("11", "14 ZTD", "deposit", "Sep 15th 22"),
  createData("12", "12 ZTD", "deposit", "Sep 14th 22"),
  createData("13", "100 ZTD", "withdrawal", "Sep 13th 22"),
  createData("14", "18 ZTD", "deposit", "Sep 12th 22"),
  createData("15", "100 ZTD", "withdrawal", "Sep 11th 22"),
]

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "ask"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "amount",
    label: "Amount",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "createdAt",
    label: "Created At",
  },
  {
    id: "actions",
    label: "Actions",
  },
]

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Stack direction={"row"} spacing={2}>
        <TextField
          label="Enter text to search..."
          variant="outlined"
          size={"small"}
          sx={{ minWidth: 300 }}
        />
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </Toolbar>
  )
}

export const TransactionsPage = () => {
  const theme = useTheme()

  const [rows, setRows] = useState([])

  const [order, setOrder] = useState("ask")
  const [orderBy, setOrderBy] = useState("id")
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [dense, setDense] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeDense = (event) => {
    setDense(event.target.checked)
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Box sx={{ width: "100%", mt: 4 }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell component="th" id={labelId} scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell>{row.amount}</TableCell>
                      <TableCell>
                        <Chip
                          label={row.type}
                          sx={{
                            background:
                              row.type === "deposit"
                                ? theme.palette.primary.main
                                : theme.palette.success.light,
                            textTransform: "capitalize",
                          }}
                        />
                      </TableCell>
                      <TableCell>{row.createdAt}</TableCell>
                      <TableCell>
                        <Button
                          variant={"outlined"}
                          endIcon={<ArrowForwardIcon />}
                          disabled
                        >
                          Details
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  )
}
