import { PATHS } from "../../constants/paths"
import { DialogContentText, Link } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import React from "react"
import { NavLink } from "react-router-dom"

export const AgreementsDialog = ({ openDialog, onCloseDialog }) => {
  return (
    <Dialog
      open={openDialog}
      onClose={onCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
      disableEscapeKeyDown
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ textAlign: "center", fontSize: "1rem" }}
      >
        This website uses cookies
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <DialogContentText id="alert-dialog-description">
          <Typography>
            This website uses cookies for functionality <br />
            and analytics as described in our Privacy Policy.
          </Typography>
          <Typography>
            If you agree to our{" "}
            <Link component={NavLink} to={PATHS.TERMS_OF_USE.PATH}>
              Terms of Use
            </Link>{" "}
            and our{" "}
            <Link component={NavLink} to={PATHS.PRIVACY_POLICY.PATH}>
              Privacy Policy
            </Link>
            , please continue to use our site.
          </Typography>
        </DialogContentText>
        <Button
          onClick={onCloseDialog}
          variant={"contained"}
          fullWidth
          sx={{ fontFamily: "RUSBoycott, sans-serif", mt: 2 }}
        >
          Accept and Continue
        </Button>
      </DialogContent>
    </Dialog>
  )
}
