import $api from "../../api"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const getInventarCards = createAsyncThunk(
  "users/fetchAll",
  async (id, { rejectWithValue }) => {
    try {
      const response = await $api
        .post("/Towers/user", {
          sorting: [
            {
              propertyName: "level",
              direction: 2,
              sortOrder: 2,
            },
          ],
          skip: 0,
          take: 9,
        })
        .then((res) => res.data.data)

      return response
    } catch (err) {
      return rejectWithValue("Request NFT error")
    }
  },
)
