import { PATHS } from "../constants/paths"
import { useAppDispatch, useAppSelector } from "../store"
import { setUserLoading } from "../store/userReducer"
import { connectHandler } from "../utils/connectHandler"
import DrawerMenu from "./DrawerMenu"
import CtaButton from "./button/CtaButton"
import { MenuList } from "./layout/MenuList"
import LoadingButton from "@mui/lab/LoadingButton"
import { Tooltip } from "@mui/material"
import Button from "@mui/material/Button"
import useMediaQuery from "@mui/material/useMediaQuery"
import React from "react"
import { NavLink, useLocation, useNavigate } from "react-router-dom"

export const MainNav = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { user, loading } = useAppSelector((state) => state.user)

  const getWallet = () => {
    dispatch(setUserLoading(true))
    connectHandler(dispatch)
  }

  const send = () => {
    // AccountTransactions
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
    ) {
      window.open("https://metamask.app.link/dapp/game-bsc-three.vercel.app/")
    }
    navigate(`${location.pathname}?popup=deposit`)
  }

  const isDownLg = useMediaQuery("(max-width: 1280px)")
  const isDownMd = useMediaQuery("(max-width: 900px)")
  const isDownSm = useMediaQuery("(max-width: 600px)")

  return (
    <nav className="nav">
      <ul className="nav-list">
        {!isDownLg && (
          <>
            {Object.entries(PATHS).map(([key, value], index) => (
              <React.Fragment key={index}>
                {value.TYPE === "public" && value.SUB_TYPE === "main" && (
                  <>
                    {key !== "NFT_BOXES" && key !== "WHITE_PAPER" ? (
                      <>
                        {!value.DISABLED ? (
                          <li className="nav-item">
                            <Button
                              className="nav-link"
                              component={NavLink}
                              to={value.PATH}
                            >
                              {value.LABEL}
                            </Button>
                          </li>
                        ) : (
                          <Tooltip title={"Coming soon..."}>
                            <span>
                              <li className="nav-item">
                                <Button
                                  className="nav-link"
                                  component={NavLink}
                                  to={value.PATH}
                                  disabled={value.DISABLED}
                                >
                                  {value.LABEL}
                                </Button>
                              </li>
                            </span>
                          </Tooltip>
                        )}
                      </>
                    ) : key === "NFT_BOXES" ? (
                      <>
                        <li className="nav-item">
                          <MenuList
                            name="NFT Boxes"
                            items={[
                              {
                                label: value.NFT_BOXES_WITH_TURRETS.LABEL,
                                link: value.NFT_BOXES_WITH_TURRETS.PATH,
                              },
                              {
                                label: value.NFT_BOXES_WITH_COMMANDERS.LABEL,
                                link: value.NFT_BOXES_WITH_COMMANDERS.PATH,
                              },
                            ]}
                            type="link"
                          />
                        </li>
                      </>
                    ) : (
                      <>
                        <MenuList
                          isExternal={true}
                          name="Whitepaper"
                          items={[
                            {
                              label: value.WHITE_PAPER.LABEL,
                              link: value.WHITE_PAPER.PATH,
                            },
                            {
                              label: value.LITE_PAPER.LABEL,
                              link: value.LITE_PAPER.PATH,
                            },
                          ]}
                          type="link"
                        />
                      </>
                    )}
                  </>
                )}
              </React.Fragment>
            ))}
          </>
        )}

        {!isDownSm && (
          <li className="nav-item">
            <Tooltip title={"Coming soon..."}>
              <span>
                <CtaButton variant="outlined" onClick={send} disabled>
                  Buy ZTD
                </CtaButton>
              </span>
            </Tooltip>
          </li>
        )}

        <li className="nav-item">
          {user.address ? (
            <MenuList
              name={`${user.address.substring(0, 3)}...${user.address.slice(
                -4,
              )}`}
              items={[
                {
                  label: PATHS.ACCOUNT.LABEL,
                  link: PATHS.ACCOUNT.PATH,
                },
                {
                  label: PATHS.TRANSACTIONS.LABEL,
                  link: PATHS.TRANSACTIONS.PATH,
                },
              ]}
              type="button"
            />
          ) : (
            <>
              {loading ? (
                <LoadingButton loading variant="contained">
                  Submit
                </LoadingButton>
              ) : (
                <CtaButton
                  variant="contained"
                  onClick={getWallet}
                  disabled={loading}
                  size="small"
                >
                  Connect Wallet
                </CtaButton>
              )}
            </>
          )}
        </li>

        {isDownLg && (
          <li className="nav-item">
            <DrawerMenu />
          </li>
        )}
      </ul>
    </nav>
  )
}
