import { getInventarCards } from "./thunks/userThunks"
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: "",
  isAuth: false,
  user: {
    userName: "User",
    // address: "0x88b59a9Ba97214Ab6B1D7d755a526FFC7738Ddf4",
    address: "",
    cardBalance: "0",
    balance: 0,
    userId: 14632341,
  },
  walletBalance: 0,
  cards: [
    // {
    //   name: "Turret 1",
    //   image: turret1,
    //   videoUrl: turretVideo1,
    //   poster: turret1,
    //   towerId: 2,
    //   type: "rare",
    //   level: 5,
    //   rarity: "gff",
    // },
    // {
    //   name: "Turret 2",
    //   image: turret2,
    //   videoUrl: turretVideo2,
    //   poster: turret2,
    //   towerId: 3,
    //   type: "rare",
    //   level: 5,
    //   rarity: "gff",
    // },
    // {
    //   name: 'Turret 3',
    //   image: turret3,
    //   towerId: 3,
    //   type: 'rare',
    //   level: 5,
    //   rarity: 'gff'
    // },
    // {
    //   name: 'Turret 4',
    //   image: turret4,
    //   towerId: 3,
    //   type: 'rare',
    //   level: 5,
    //   rarity: 'gff'
    // },
    // {
    //   name: 'Turret 5',
    //   image: turret5,
    //   towerId: 3,
    //   type: 'rare',
    //   level: 5,
    //   rarity: 'gff'
    // },
  ],
}

const userSlice = createSlice({
  name: "user",

  initialState,

  reducers: {
    setUserName(state, action) {
      state.user.userName = action.payload
    },
    incrementBalance(state, action) {
      state.user.balance += action.payload
    },
    decrementBalance(state, action) {
      state.user.balance -= action.payload
    },
    setWalletBalance(state, action) {
      state.walletBalance = action.payload
    },
    setIsAuth(state, action) {
      state.isAuth = action.payload
    },
    setUserLoading(state, action) {
      state.loading = action.payload
    },
    setUser(state, action) {
      state.user = action.payload
      state.user.userName = "User"
    },
  },
  extraReducers: {
    [getInventarCards.fulfilled.type]: (state, action) => {
      state.loading = false
      state.cards = action.payload.data
      state.error = ""
    },
    [getInventarCards.pending.type]: (state, action) => {
      state.loading = true
    },
    [getInventarCards.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export default userSlice.reducer
export const {
  setUserName,
  setUser,
  incrementBalance,
  setUserLoading,
  setWalletBalance,
  decrementBalance,
  setIsAuth,
} = userSlice.actions
