import electromagneticTurretBoxImg from "../../assets/img/nft-boxes/electromagnetic-box.png"
import { DialogActions, Stack, TextField } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import { enqueueSnackbar } from "notistack"
import React, { useState } from "react"

const initFormValues = {
  amount: "",
}
export const SellDialog = ({ closeDialog, width }) => {
  const [form, setForm] = useState(initFormValues)

  const onSellNFT = () => {
    if (form.amount) {
      setForm(initFormValues)
      closeDialog()
      enqueueSnackbar("Operation completed successfully", {
        variant: "success",
      })
    } else {
      enqueueSnackbar("All fields must be filled", {
        variant: "error",
      })
    }
  }

  return (
    <Box
      sx={{
        width,
        background: "#121212",
        textAlign: "center",
      }}
    >
      <DialogTitle sx={{ fontSize: "1rem" }}>Listing on the market</DialogTitle>
      <DialogContent sx={{ pt: 2, pb: 0 }}>
        <Stack>
          <Box
            textAlign="center"
            sx={{
              width: 350,
              margin: "auto",
              padding: "70px 0",
              background:
                "url(https://faraland.io/static/media/circle-highlight.a886adc7.png)",
              backgroundSize: "75%",
              backgroundPosition: "50% 50%",
              backgroundRepeat: "no-repeat",
              "& > img": {
                width: "180px",
              },
            }}
          >
            <img src={electromagneticTurretBoxImg} alt={""} />
          </Box>
          <Typography mb={2}>
            You are about to sell NFT #1432342 on the marketplace.
          </Typography>

          <TextField
            id="amount"
            value={form.amount}
            onChange={(e) =>
              setForm((prevState) => ({
                ...prevState,
                amount: e.target.value,
              }))
            }
            label="Enter the amount in ZTD tokens"
            variant="outlined"
            size={"small"}
            fullWidth
            autoComplete={"none"}
            type={"number"}
            shrink
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              min: 1,
            }}
          />

          <Typography varaint="body2" mt={1}>
            Listing is free. At the time of the sale, the fees 5% will be
            deducted.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          color="primary"
          variant={"contained"}
          onClick={onSellNFT}
          fullWidth
          sx={{ fontFamily: "RUSBoycott, sans-serif" }}
        >
          List on Market
        </Button>
      </DialogActions>
    </Box>
  )
}
