import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  notification: "",
  plans: {
    basic: {
      apy: 110,
      plan: 0,
      game: 20,
      total: 140,
    },
    standard: {
      apy: 120,
      plan: 10,
      game: 20,
      total: 160,
    },
    premium: {
      apy: 120,
      plan: 20,
      game: 30,
      total: 180,
    },
    pro: {
      apy: 120,
      plan: 50,
      game: 40,
      total: 200,
    },
  },
}

const appSlice = createSlice({
  name: "user",

  initialState,

  reducers: {
    setNotification(state, action) {
      state.notification = action.payload
    },
  },
})

export default appSlice.reducer
export const { setNotification } = appSlice.actions
