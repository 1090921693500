import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"
import React from "react"

const BootstrapButton = styled(Button)({
  fontFamily: ["RUSBoycott", "sans-serif"].join(","),
})

const CtaButton = ({ children, ...props }) => {
  return <BootstrapButton {...props}>{children}</BootstrapButton>
}

export default CtaButton
