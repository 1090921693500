import { getAllItems } from "./thunks/marketThunks"
import { createSlice } from "@reduxjs/toolkit"
import { turrets } from "../data/turrets"
import { commanders } from "../data/commanders"

const initialState = {
  loading: false,
  error: null,
  turrets,
  commanders,
}

const marketSlice = createSlice({
  name: "market",

  initialState,
  reducers: {
    setMarketLoading(state, action) {
      state.loading = action.payload
    },
  },
  extraReducers: {
    [getAllItems.fulfilled.type]: (state, action) => {
      state.loading = false
      state.error = ""
      state.items = action.payload.data
    },
    [getAllItems.pending.type]: (state, action) => {
      state.loading = true
    },
    [getAllItems.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export default marketSlice.reducer
export const { setMarketLoading } = marketSlice.actions
