import { DialogActions, TextField } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import { enqueueSnackbar } from "notistack"
import { useState } from "react"

const initFormValues = {
  amount: "",
}
export const StakeDialog = ({ closeDialog, width }) => {
  const [form, setForm] = useState(initFormValues)

  const onStake = () => {
    if (form.amount) {
      enqueueSnackbar("Operation completed successfully", {
        variant: "success",
      })
      setForm(initFormValues)
      closeDialog()
    } else {
      enqueueSnackbar("All fields must be filled", {
        variant: "error",
      })
    }
  }

  return (
    <Box
      sx={{
        width,
        background: "#121212",
        textAlign: "center",
      }}
    >
      <DialogTitle sx={{ fontSize: "1rem" }}>
        Stake tokens for NFT mining
      </DialogTitle>
      <DialogContent sx={{ pt: 2, pb: 0 }}>
        <Typography mb={2}>
          Enter the amount you want to stake for NFT mining
        </Typography>
        <TextField
          id="amount"
          value={form.amount}
          onChange={(e) =>
            setForm((prevState) => ({
              ...prevState,
              amount: e.target.value,
            }))
          }
          label="Enter the amount in ZTD tokens"
          variant="outlined"
          size="small"
          fullWidth
          autoComplete="none"
          type="number"
          shrink
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            min: 1,
          }}
        />
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          color="primary"
          variant={"contained"}
          onClick={onStake}
          fullWidth
          sx={{ fontFamily: "RUSBoycott, sans-serif" }}
        >
          Stake
        </Button>
      </DialogActions>
    </Box>
  )
}
