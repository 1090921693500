import dividerPrimaryGradientUrl from "../../assets/img/border/divider-primary-gradient.svg"
import { PATHS } from "../../constants/paths"
import TelegramIcon from "@mui/icons-material/Telegram"
import TwitterIcon from "@mui/icons-material/Twitter"
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material"
import Button from "@mui/material/Button"
import useMediaQuery from "@mui/material/useMediaQuery"
import React from "react"
import { AiOutlineMedium } from "react-icons/ai"
import { NavLink } from "react-router-dom"

export const Footer = () => {
  const isDownSm = useMediaQuery("(max-width: 600px)")

  return (
    <Box
      component={"footer"}
      py={2}
      sx={{
        borderTop: "1px solid transparent",
        borderImage: `url(${dividerPrimaryGradientUrl}) 1 stretch`,
      }}
    >
      <Container fixed>
        <Grid
          container
          justifyContent={!isDownSm ? "space-between" : "center"}
          alignItems="center"
        >
          <Grid item>
            <Typography color="secondary">
              {new Date().getFullYear()} |{" "}
              <span style={{ fontSize: "1rem" }}>Ⓒ</span> Zombie TD
            </Typography>
          </Grid>
          {!isDownSm && (
            <Grid item>
              <Stack
                direction="row"
                spacing={2}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Button
                  component={NavLink}
                  to={PATHS.TERMS_OF_USE.PATH}
                  color="secondary"
                  className="nav-link"
                >
                  {PATHS.TERMS_OF_USE.LABEL}
                </Button>

                <Button
                  component={NavLink}
                  to={PATHS.PRIVACY_POLICY.PATH}
                  color="secondary"
                  className="nav-link"
                >
                  {PATHS.PRIVACY_POLICY.LABEL}
                </Button>
                <IconButton
                  component={Link}
                  href="https://zombietd.medium.com/"
                  target="_blank"
                >
                  <AiOutlineMedium />
                </IconButton>
                <IconButton
                  component={Link}
                  href="https://twitter.com/zombie_td/"
                  target="_blank"
                >
                  <TwitterIcon color="secondary" />
                </IconButton>
                <IconButton
                  component={Link}
                  href="https://t.me/zombie_td_news/"
                  target="_blank"
                >
                  <TelegramIcon color="secondary" />
                </IconButton>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  )
}
