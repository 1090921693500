import arrow from "../assets/img/arrow-staking.png"
import { ListItemStyled } from "../components/ListItemStyled"
import { useAppSelector } from "../store"
import AdjustRoundedIcon from "@mui/icons-material/AdjustRounded"
import TokenRoundedIcon from "@mui/icons-material/TokenRounded"
import {
  ButtonGroup,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
} from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import React, { useEffect, useState } from "react"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 18,
    "@media (max-width: 600px)": {
      fontSize: 16,
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    "@media (max-width: 600px)": {
      fontSize: 16,
    },
  },
}))

export const Stacking = () => {
  const { quantityPerPerson, total } = useAppSelector((state) => state.boxes)

  const { available } = useAppSelector((state) => state.boxes)
  const [selected, setSelected] = useState("basic")
  const [tokens, setTokens] = useState(+available)
  const [ZTD, setZTD] = useState(0)
  const plans = useAppSelector((state) => state.app.plans)

  const changePlan = (plan) => setSelected(plan)
  const isActive = (plan) => (selected === plan ? "contained" : "outlined")
  const selectTokens = (value) => {
    if (value <= 0) setTokens(+available)
    if (value > 0) setTokens(value)
  }

  useEffect(() => {
    // let calculatedZTD = Math.floor((tokens * +plans[selected].total) / 100);
    let calculatedZTD = Math.floor(0)
    setZTD(calculatedZTD)
  }, [tokens, plans, selected])

  return (
    <Box className="content">
      <Box className="content-head"></Box>

      <Box className="content-body">
        <Container fixed>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <Typography
                variant="h3"
                textAlign="center"
                sx={{
                  mt: {
                    xss: 4,
                    sm: 0,
                  },
                  mb: {
                    xss: 2,
                    sm: 2,
                  },
                }}
              >
                Information
              </Typography>

              <Typography gutterBottom>
                You can stake tokens in one of 4 plans. The plans differ in the
                duration of token lock-up and additional bonus percentage to
                Staking:
              </Typography>
              <List>
                <ListItemStyled
                  icon={<TokenRoundedIcon />}
                  primaryText="Basic"
                  secondaryText="Blocking tokens for 1 week without additional interest"
                  className="gray"
                />
                <ListItemStyled
                  icon={<TokenRoundedIcon />}
                  primaryText="Standard"
                  secondaryText="Blocking tokens for 2 weeks with a 10% bonus"
                  className="green"
                />
                <ListItemStyled
                  icon={<TokenRoundedIcon />}
                  primaryText="Premium"
                  secondaryText="Blocking tokens for a 1 month with a 20% bonus"
                  className="blue"
                />
                <ListItemStyled
                  icon={<TokenRoundedIcon />}
                  primaryText="Pro"
                  secondaryText="Blocking tokens for 3 months with a 50% bonus"
                  className="purple"
                />
              </List>

              <Typography gutterBottom>
                200,000,000 ZTD tokens have been allocated for Staking. The
                distribution of tokens takes place in equal shares every day
                during the year. Every day, 547,945 (200,000,000 / 365) ZTD
                tokens are distributed among all Users in proportion to the
                contribution and additional bonuses.
              </Typography>

              <Typography mt={2}>You can increase the bonus:</Typography>

              <List>
                <ListItem
                  sx={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                  }}
                >
                  <ListItemAvatar sx={{ minWidth: 40, display: "flex" }}>
                    <AdjustRoundedIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText primary="Choosing a plan with a bonus;" />
                </ListItem>
                <ListItem
                  sx={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                  }}
                >
                  <ListItemAvatar sx={{ minWidth: 40, display: "flex" }}>
                    <AdjustRoundedIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText primary="In the game, upgrading the Commander and freeing new territories in the Campaign;" />
                </ListItem>
                <ListItem
                  sx={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                  }}
                >
                  <ListItemAvatar sx={{ minWidth: 40, display: "flex" }}>
                    <AdjustRoundedIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText primary="On the Site for NFT Staking." />
                </ListItem>
              </List>

              <Typography>
                At the end of Staking, the tokens will be automatically credited
                to the balance, and the earned tokens must be branded in the
                table below.
              </Typography>
            </Grid>
            <Grid item md={6}>
              <div>
                <Box mb={4} textAlign="center">
                  <Typography variant="h3" mb={3}>
                    Staking
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={4} display="flex">
                      <ListItem
                        sx={{
                          paddingTop: "4px",
                          paddingBottom: "4px",
                          flexDirection: "column",
                          border: "1px solid #bc8c4b",
                          borderRadius: "8px",
                        }}
                        component={Box}
                      >
                        <ListItemText
                          primary="Total Staked:"
                          secondary="0"
                          sx={{
                            textAlign: "center",
                          }}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={4} display="flex">
                      <ListItem
                        sx={{
                          paddingTop: "4px",
                          paddingBottom: "4px",
                          flexDirection: "column",
                          border: "1px solid #bc8c4b",
                          borderRadius: "8px",
                        }}
                        component={Box}
                      >
                        <ListItemText
                          primary="Average APY:"
                          secondary="-"
                          sx={{
                            textAlign: "center",
                          }}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={4} display="flex">
                      <ListItem
                        sx={{
                          paddingTop: "4px",
                          paddingBottom: "4px",
                          flexDirection: "column",
                          border: "1px solid #bc8c4b",
                          borderRadius: "8px",
                        }}
                        component={Box}
                      >
                        <ListItemText
                          primary="Available ZTD:"
                          secondary={"0"}
                          sx={{
                            textAlign: "center",
                          }}
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Box>

                <Box textAlign="center" mb={2}>
                  <Typography variant="h3" mb={3}>
                    Choose Staking Plan
                  </Typography>
                  <ButtonGroup size="large" aria-label="large button group">
                    <Button
                      variant={isActive("basic")}
                      key="basic"
                      onClick={() => changePlan("basic")}
                    >
                      Basic
                    </Button>
                    <Button
                      variant={isActive("standard")}
                      key="standard"
                      onClick={() => changePlan("standard")}
                    >
                      Standard
                    </Button>
                    <Button
                      variant={isActive("premium")}
                      key="premium"
                      onClick={() => changePlan("premium")}
                    >
                      Premium
                    </Button>
                    <Button
                      variant={isActive("pro")}
                      key="pro"
                      onClick={() => changePlan("pro")}
                    >
                      Pro
                    </Button>
                  </ButtonGroup>
                </Box>

                <List>
                  <ListItem
                    sx={{
                      paddingTop: "4px",
                      paddingBottom: "4px",
                    }}
                  >
                    <ListItemAvatar
                      sx={{
                        minWidth: 40,
                        display: "flex",
                      }}
                    >
                      <AdjustRoundedIcon color="primary" />
                    </ListItemAvatar>
                    <ListItemText primary={`APY: -`} />
                  </ListItem>
                  <ListItem
                    sx={{
                      paddingTop: "4px",
                      paddingBottom: "4px",
                    }}
                  >
                    <ListItemAvatar
                      sx={{
                        minWidth: 40,
                        display: "flex",
                      }}
                    >
                      <AdjustRoundedIcon color="primary" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`Bonus Plan: ${plans[selected].plan}%`}
                    />
                  </ListItem>
                  <ListItem
                    sx={{
                      paddingTop: "4px",
                      paddingBottom: "4px",
                    }}
                  >
                    <ListItemAvatar
                      sx={{
                        minWidth: 40,
                        display: "flex",
                      }}
                    >
                      <AdjustRoundedIcon color="primary" />
                    </ListItemAvatar>
                    <ListItemText primary={`Staking NFT Bonus: -`} />
                  </ListItem>
                  <ListItem
                    sx={{
                      paddingTop: "4px",
                      paddingBottom: "4px",
                    }}
                  >
                    <ListItemAvatar
                      sx={{
                        minWidth: 40,
                        display: "flex",
                      }}
                    >
                      <AdjustRoundedIcon color="primary" />
                    </ListItemAvatar>
                    <ListItemText primary={`Finally APY: -`} />
                  </ListItem>
                </List>

                <Box textAlign="center">
                  <Typography variant="h3" my={3}>
                    How many tokens do you want to Staking?
                  </Typography>
                  <TextField
                    id="outlined-required"
                    label={`ZTD`}
                    defaultValue={available}
                    onChange={(e) => selectTokens(+e.target.value)}
                    type="number"
                    size="small"
                  />

                  <Typography mt={2}>
                    At the current percent lage you will receive approximately
                  </Typography>

                  <img src={arrow} alt="" className="z_staking__arrow" />

                  <Typography variant="h3" my={2}>
                    {ZTD} ZTD
                  </Typography>

                  {/*<Box sx={{mb: 2}}>*/}
                  {/*  <Typography>Locked for 2 weeks. Will be available in:</Typography>*/}
                  {/*  <Box sx={{display: 'flex', justifyContent: 'center', my: 2, mb: 3}}>*/}
                  {/*    <TimeLeft y={2022} m={9} d={16}/>*/}
                  {/*  </Box>*/}
                  {/*</Box>*/}

                  <Tooltip title="Coming soon...">
                    <span>
                      <Button variant="contained" size="large" disabled>
                        Stake
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              </div>
            </Grid>
          </Grid>

          <Typography
            variant="h2"
            textAlign="center"
            sx={{
              mt: {
                xs: 6,
                md: 8,
                lg: 10,
              },
              mb: {
                xss: 0,
                md: 4,
              },
            }}
          >
            Transaction
          </Typography>
          <Box sx={{ margin: "auto" }}>
            <TableContainer>
              <Table sx={{ minWidth: "768px" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Plan</StyledTableCell>
                    <StyledTableCell>Start</StyledTableCell>
                    <StyledTableCell>End In</StyledTableCell>
                    <StyledTableCell>Total Staked</StyledTableCell>
                    <StyledTableCell>Earned</StyledTableCell>
                    <StyledTableCell>APY</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <StyledTableCell colSpan={6}>
                      <Box textAlign={"center"}>No Data</Box>
                    </StyledTableCell>
                  </TableRow>
                  {/*<TableRow>*/}
                  {/*  <StyledTableCell>*/}
                  {/*    Pro*/}
                  {/*  </StyledTableCell>*/}
                  {/*  <StyledTableCell>*/}
                  {/*    <p>02.11.21</p>*/}
                  {/*    <p>20:30</p>*/}
                  {/*  </StyledTableCell>*/}
                  {/*  <StyledTableCell>*/}
                  {/*    1000ZDT*/}
                  {/*  </StyledTableCell>*/}
                  {/*  <StyledTableCell>*/}
                  {/*    1000ZDT*/}
                  {/*  </StyledTableCell>*/}
                  {/*  <StyledTableCell>*/}
                  {/*    <p>APY: 110%+ </p>*/}
                  {/*    <p>Bonus Plan: 10%+</p>*/}
                  {/*    <p>Staking NFT Bonus: 40%</p>*/}
                  {/*  </StyledTableCell>*/}
                  {/*  <StyledTableCell>*/}
                  {/*    <Tooltip title='Coming Soon...'>*/}
                  {/*                                      <span>*/}
                  {/*                                        <Button variant='contained' disabled>Claim</Button>*/}
                  {/*                                      </span>*/}
                  {/*    </Tooltip>*/}
                  {/*  </StyledTableCell>*/}
                  {/*</TableRow>*/}
                  {/*<TableRow>*/}
                  {/*  <StyledTableCell>*/}
                  {/*    Pro*/}
                  {/*  </StyledTableCell>*/}
                  {/*  <StyledTableCell>*/}
                  {/*    <p>02.11.21</p>*/}
                  {/*    <p>20:30</p>*/}
                  {/*  </StyledTableCell>*/}
                  {/*  <StyledTableCell>*/}
                  {/*    1000ZDT*/}
                  {/*  </StyledTableCell>*/}
                  {/*  <StyledTableCell>*/}
                  {/*    1000ZDT*/}
                  {/*  </StyledTableCell>*/}
                  {/*  <StyledTableCell>*/}
                  {/*    <p>APY: 110%+ </p>*/}
                  {/*    <p>Bonus Plan: 10%+</p>*/}
                  {/*    <p>Staking NFT Bonus: 40%</p>*/}
                  {/*  </StyledTableCell>*/}
                  {/*  <StyledTableCell>*/}
                  {/*    <Tooltip title='Coming Soon...'>*/}
                  {/*                                      <span>*/}
                  {/*                                        <Button variant='contained' disabled>Claim</Button>*/}
                  {/*                                      </span>*/}
                  {/*    </Tooltip>*/}
                  {/*  </StyledTableCell>*/}
                  {/*</TableRow>*/}
                  {/*<TableRow>*/}
                  {/*  <StyledTableCell>*/}
                  {/*    Pro*/}
                  {/*  </StyledTableCell>*/}
                  {/*  <StyledTableCell>*/}
                  {/*    <p>02.11.21</p>*/}
                  {/*    <p>20:30</p>*/}
                  {/*  </StyledTableCell>*/}
                  {/*  <StyledTableCell>*/}
                  {/*    1000ZDT*/}
                  {/*  </StyledTableCell>*/}
                  {/*  <StyledTableCell>*/}
                  {/*    1000ZDT*/}
                  {/*  </StyledTableCell>*/}
                  {/*  <StyledTableCell>*/}
                  {/*    <p>APY: 110%+ </p>*/}
                  {/*    <p>Bonus Plan: 10%+</p>*/}
                  {/*    <p>Staking NFT Bonus: 40%</p>*/}
                  {/*  </StyledTableCell>*/}
                  {/*  <StyledTableCell>*/}
                  {/*    <Tooltip title='Coming Soon...'>*/}
                  {/*                                      <span>*/}
                  {/*                                        <Button variant='contained' disabled>Claim</Button>*/}
                  {/*                                      </span>*/}
                  {/*    </Tooltip>*/}
                  {/*  </StyledTableCell>*/}
                  {/*</TableRow>*/}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
