import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { FormControlLabel, Grid, Slider } from "@mui/material"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import Typography from "@mui/material/Typography"
import React, { useState } from "react"

const AccordionStyled = ({ title, children }) => {
  return (
    <Accordion
      defaultExpanded
      sx={{
        background: "none",
        boxShadow: "none",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          mt: 0,
          padding: 0,
          minHeight: "auto !important",

          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "0 0 10px",
          },
        }}
      >
        <Typography variant="h5">{title}</Typography>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          padding: 0,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export const MarketplaceFilter = () => {
  const [rarityState, setRarityState] = React.useState({
    common: false,
    uncommon: false,
    rare: false,
    epic: false,
    legendary: false,
  })

  const { common, uncommon, rare, epic, legendary } = rarityState

  const handleChangeState = (event) => {
    setRarityState({
      ...rarityState,
      [event.target.name]: event.target.checked,
    })
  }

  const [typeGunState, setTypeGunState] = React.useState({
    machineGun: false,
    rocket: false,
    laser: false,
    slowingDown: false,
    artillery: false,
  })

  const [typeCommanderState, setTypeCommanderState] = React.useState({
    baseCommander: false,
    machineGunner: false,
    rocketeer: false,
    laserWoman: false,
    artilleryMan: false,
  })

  const { machineGun, rocket, laser, slowingDown, artillery } = typeGunState

  const handleChangeTurretType = (event) => {
    setTypeGunState({
      ...typeGunState,
      [event.target.name]: event.target.checked,
    })
  }

  const handleChangeCommanderType = (event) => {
    setTypeCommanderState({
      ...typeCommanderState,
      [event.target.name]: event.target.checked,
    })
  }

  const {
    baseCommander,
    machineGunner,
    rocketeer,
    laserWoman,
    electroMagnet,
    artilleryMan,
  } = typeCommanderState

  const [rangeLvl, setRangeLvl] = useState([0, 100])
  const [rangePrice, setRangePrice] = useState([0, 1000])

  const handleChangeRangeLvl = (event, newValue) => {
    setRangeLvl(newValue)
  }

  const handleChangeRangePrice = (event, newValue) => {
    setRangePrice(newValue)
  }

  return (
    <>
      <AccordionStyled title="Rarity">
        <FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={common}
                  onChange={handleChangeState}
                  name="common"
                />
              }
              label="Common"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={uncommon}
                  onChange={handleChangeState}
                  name="uncommon"
                />
              }
              label="Uncommon"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rare}
                  onChange={handleChangeState}
                  name="rare"
                />
              }
              label="Rare"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={epic}
                  onChange={handleChangeState}
                  name="epic"
                />
              }
              label="Epic"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={legendary}
                  onChange={handleChangeState}
                  name="legendary"
                />
              }
              label="Legendary"
            />
          </FormGroup>
        </FormControl>
      </AccordionStyled>

      <AccordionStyled title="Turrets">
        <FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={machineGun}
                  onChange={handleChangeTurretType}
                  name="machineGun"
                />
              }
              label="Machine Gun"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rocket}
                  onChange={handleChangeTurretType}
                  name="rocket"
                />
              }
              label="Rocket"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={laser}
                  onChange={handleChangeTurretType}
                  name="laser"
                />
              }
              label="Laser"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={slowingDown}
                  onChange={handleChangeTurretType}
                  name="slowingDown"
                />
              }
              label="Electromagnetic"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={artillery}
                  onChange={handleChangeTurretType}
                  name="artillery"
                />
              }
              label="Artillery"
            />
          </FormGroup>
        </FormControl>
      </AccordionStyled>
      <AccordionStyled
        title="Commanders"
        sx={{
          "& .MuiAccordionSummary-root": {
            minHeight: "auto",
          },
        }}
      >
        <FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={baseCommander}
                  onChange={handleChangeCommanderType}
                  name="machineGun"
                />
              }
              label="Base Commander"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={machineGunner}
                  onChange={handleChangeCommanderType}
                  name="machineGunner"
                />
              }
              label="Machine Gunner"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rocketeer}
                  onChange={handleChangeCommanderType}
                  name="rocketeer"
                />
              }
              label="Rocketeer"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={laserWoman}
                  onChange={handleChangeCommanderType}
                  name="laserWoman"
                />
              }
              label="Laserwoman"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={electroMagnet}
                  onChange={handleChangeCommanderType}
                  name="electroMagnet"
                />
              }
              label="Electromagnet"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={artilleryMan}
                  onChange={handleChangeCommanderType}
                  name="artilleryMan"
                />
              }
              label="Artilleryman"
            />
          </FormGroup>
        </FormControl>
      </AccordionStyled>

      <AccordionStyled title="Level">
        <Grid container justifyContent="center">
          <Grid item xs={11}>
            <Slider
              aria-label="Always visible"
              value={rangeLvl}
              onChange={handleChangeRangeLvl}
              valueLabelDisplay="auto"
              marks
            />
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography>{rangeLvl[0]} Lvl</Typography>
            </Grid>
            <Grid item>
              <Typography>{rangeLvl[1]} Lvl</Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionStyled>

      <AccordionStyled title="Price">
        <Grid container justifyContent="center">
          <Grid item xs={11}>
            <Slider
              aria-label="Always visible"
              value={rangePrice}
              onChange={handleChangeRangePrice}
              valueLabelDisplay="auto"
              min={0}
              max={999999}
            />
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography>{rangePrice[0]}.00 ZTD</Typography>
            </Grid>
            <Grid item>
              <Typography>{rangePrice[1]}.00 ZTD</Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionStyled>
    </>
  )
}
