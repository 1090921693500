import litePaper from "../assets/Zombie_TD_Litepaper.pdf"

export const PATHS = {
  HOME: {
    PATH: "/",
    LABEL: "",
    ID: 1,
    TYPE: "public",
    SUB_TYPE: "",
  },
  IDO: {
    PATH: "/ido",
    LABEL: "IDO",
    ID: 2,
    TYPE: "public",
    SUB_TYPE: "main",
  },
  MARKET: {
    PATH: "/marketplace",
    LABEL: "Marketplace",
    ID: 3,
    TYPE: "public",
    SUB_TYPE: "main",
  },
  STAKING: {
    PATH: "/staking",
    LABEL: "Staking",
    ID: 4,
    TYPE: "public",
    SUB_TYPE: "main",
  },
  STAKING_NFT: {
    PATH: "/stakingnft",
    LABEL: "Staking NFT",
    ID: 5,
    TYPE: "public",
    SUB_TYPE: "main",
  },
  NFT_BOXES: {
    NFT_BOXES_WITH_TURRETS: {
      PATH: "/nft-boxes-with-turrets",
      LABEL: "NFT Boxes with Turrets",
      ID: 6,
    },
    NFT_BOXES_WITH_COMMANDERS: {
      PATH: "/nft-boxes-with-commanders",
      LABEL: "NFT Boxes with Commanders",
      ID: 7,
    },
    TYPE: "public",
    SUB_TYPE: "main",
  },
  MINING: {
    PATH: "/mining",
    LABEL: "Mining",
    ID: 8,
    TYPE: "public",
    SUB_TYPE: "main",
  },
  WHITE_PAPER: {
    WHITE_PAPER: {
      LABEL: "Whitepaper",
      PATH: "https://docs.zombietd.app/",
    },
    LITE_PAPER: {
      LABEL: "Litepaper",
      PATH: litePaper,
    },
    TYPE: "public",
    SUB_TYPE: "main",
  },
  FAQS: {
    PATH: "/faqs",
    LABEL: "Faqs",
    ID: 10,
    TYPE: "public",
    SUB_TYPE: "main",
  },
  ACCOUNT: {
    PATH: "/account/dashboard",
    LABEL: "Dashboard",
    ID: 11,
    TYPE: "private",
    SUB_TYPE: "main",
  },
  TRANSACTIONS: {
    PATH: "/account/transactions",
    LABEL: "Transactions",
    ID: 12,
    TYPE: "private",
    SUB_TYPE: "main",
  },
  TERMS_OF_USE: {
    PATH: "/terms-of-use",
    LABEL: "Terms of use",
    ID: 13,
    TYPE: "public",
    SUB_TYPE: "additional",
  },
  PRIVACY_POLICY: {
    PATH: "/privacy-policy",
    LABEL: "Privacy policy",
    ID: 14,
    TYPE: "public",
    SUB_TYPE: "additional",
  },
}
