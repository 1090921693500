import { MarketplaceFilter } from "../components/MarketplaceFilter"
import NftCard from "../components/card/NftCard"
import { useAppSelector } from "../store"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import FilterListOffRoundedIcon from "@mui/icons-material/FilterListOffRounded"
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded"
import { Container, Divider, Drawer, Grid, TextField } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import useMediaQuery from "@mui/material/useMediaQuery"
import React, { useState } from "react"

export const Marketplace = () => {
  const isDownMd = useMediaQuery("(max-width: 900px)")
  const isDownSm = useMediaQuery("(max-width: 600px)")

  const commanders = useAppSelector((state) => state.market.commanders)

  const [marketItems, setMarketItems] = useState(commanders)

  const search = (e) =>
    setMarketItems(
      marketItems.filter((elem) => elem.id.toLowerCase().includes(e)),
    )

  const [age, setAge] = React.useState("")

  const handleChangeAge = (event) => {
    setAge(event.target.value)
  }

  const [name, setName] = React.useState("")
  const handleChangeName = (event) => {
    setName(event.target.value)
  }

  const [openDrawer, setOpenDrawer] = useState(false)

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setOpenDrawer(!openDrawer)
  }

  return (
    <Box className="content">
      <Box className="content-head">
        <Container fixed>
          <Typography
            variant={"h1"}
            sx={{
              mb: {
                xss: 2,
                sm: 3,
                lg: 5,
              },
            }}
          >
            Marketplace
          </Typography>
        </Container>
      </Box>

      <Box className="content-body">
        <Container fixed>
          <Grid container spacing={3}>
            {!isDownSm && (
              <Grid item sm={3}>
                <MarketplaceFilter />
              </Grid>
            )}

            <Grid item xs={12} sm={9}>
              {isDownSm && (
                <Box my={2}>
                  <Button
                    onClick={toggleDrawer()}
                    variant="contained"
                    startIcon={
                      !openDrawer ? (
                        <FilterListRoundedIcon />
                      ) : (
                        <FilterListOffRoundedIcon />
                      )
                    }
                  >
                    Filter by params
                  </Button>
                </Box>
              )}

              <Box mb={4}>
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item display="flex" alignItems="center">
                        <Typography>Sort By:</Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="secondary"
                          endIcon={<ExpandMoreIcon />}
                        >
                          Rarity
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="secondary"
                          endIcon={<ExpandMoreIcon />}
                        >
                          Level
                        </Button>
                      </Grid>
                      <Grid item display="flex" justifyContent="flex-end">
                        <Button
                          variant="outlined"
                          color="secondary"
                          endIcon={<ExpandMoreIcon />}
                        >
                          Price
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={3}>
                    <Grid item textAlign="right">
                      <TextField
                        id="outlined-name"
                        label="ID NFT"
                        onChange={(e) => search(e.target.value)}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Grid container rowSpacing={5} columnSpacing={3}>
                {marketItems.length ? (
                  marketItems
                    .filter((item) => item.id === 1)
                    .map((item) => {
                      return (
                        <Grid item xs={12} sm={6} md={4}>
                          <NftCard
                            key={item.id}
                            type="marketplace"
                            item={item}
                          />
                        </Grid>
                      )
                    })
                ) : (
                  <Grid item xs={12}>
                    <Typography>Not found...</Typography>
                  </Grid>
                )}
              </Grid>

              <Box>
                <Divider sx={{ my: 4 }} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Drawer anchor="left" open={openDrawer} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 260, px: 2 }}>
          <MarketplaceFilter />
        </Box>
      </Drawer>
    </Box>
  )
}
