import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import React, { useState } from "react"

const ShowMoreButton = ({ content, ...props }) => {
  const [readMore, setReadMore] = useState(false)

  const length = 200
  let trimmedContent =
    content.length > length
      ? content.substring(0, content.length > length && content.length / 3) +
        "..."
      : content

  return (
    <>
      <Typography gutterBottom>
        {readMore ? content : trimmedContent}
      </Typography>
      {content.length > length && (
        <Button
          endIcon={!readMore ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          onClick={() => {
            setReadMore(!readMore)
          }}
          {...props}
        >
          {!readMore ? "Show more" : "Show less"}
        </Button>
      )}
    </>
  )
}

export default ShowMoreButton
